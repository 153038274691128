/*
 *
 * LanguageProvider reducer
 *
 */

import { fromJS } from 'immutable';
import { appLocales } from '../../i18n';
import {
  CHANGE_LOCALE,
  DEFAULT_LOCALE,
  LOCALE_VISIBLE,
  PICKER_OFF,
} from './constants';

const initialState = fromJS({
  locale: DEFAULT_LOCALE,
  locale_visible: LOCALE_VISIBLE,
  picker_off: PICKER_OFF,
});

function languageProviderReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      // double check for valid language
      if (appLocales.indexOf(action.locale) < 0) {
        return state.set('picker_off', true);
      }
      return state.set('locale', action.locale);

    case LOCALE_VISIBLE:
      return state.set('locale_visible', action.locale_visible);
    default:
      return state;
  }
}

export default languageProviderReducer;
