import styled from 'styled-components';

import {
  getFontSizes,
  size,
  media,
  offscreen,
  nativeFocus,
} from '../../styles/mixins';
import styles from '../../styles/constants';

// First child wraps the actual input component
// Second child wraps its optional children

export default styled.li`
  & > div:first-child {
    margin-bottom: 0;

    label {
      ${getFontSizes('form_check')} display: block;
      padding: ${size(2)} ${size(3)};
      color: ${styles.color.blue};
      font-weight: 500;
      margin-left: 0;
      margin-right: 0;
      margin-top: ${size(1)};
      border: 1px solid ${styles.color.gray4};
      border-radius: 6px;
      &.child {
        ${getFontSizes('form_sub_question')};
      }
      ${media.large`
        font-weight: 400;
      `};
    }

    > input {
      ${offscreen()};
      &:focus {
        + label {
          ${nativeFocus()};
        }
      }

      &:checked + label {
        background-color: ${styles.color.blue};
        color: white;
        border-top-color: ${styles.color.blue};
        border-radius: 6px;
        font-weight: 500;

        + .text-group {
          display: block;
        }
      }
    }
  }
  & > div:nth-child(2) {
  }

  /* Select all radio labels after an existing radio-label */
  // TODO: Remove if not needed any longer
  // & ~ li {
  //   position: relative;
  //   &:before {
  //     content: ' ';
  //     position: absolute;
  //     left: ${size(-3)};
  //     right: ${size(-3)};
  //     background-color: ${styles.color.gray4};
  //     height: 1px;
  //   }
  }
`;
