import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import * as actionCreators from "../../../store/LppProjectActions";
import { callPaths } from "../../../configuration";
import { setVal } from "../../../helpers/setValueHelper";

import styled from "styled-components";
import LoanAppHeader from "../../../components/LoanHeader";
import MainWrapper from "../../../components/MainWrapper";
import Button from "../../../components/Button";
import Center from "../../../components/MainWrapper/Center";
import Group from "../../../components/Group";
import QuestionGroups from "../../../components/QuestionGroups";
import Label from "../../../components/QuestionWrapper/Label";
import ColorBox from "../../../components/ColorBox";
import ColorBoxGroup from "../../../components/ColorBoxGroup";
import TooltipSVG from "../../../components/QuestionWrapper/tooltip.svg";
import InputWrapper from "../../../components/InputText/InputWrapper";
import ErrorMessage from "../../../components/QuestionWrapper/ErrorMessage";

const LeftAlign = styled.div`
  text-align: left;
`;

const ToolTip = styled.img`
  float: right;
`;

const QuestionWrap = styled.div`
  margin-top: 8px;
`;

const NextPrevButtons = styled.div`
  display: flex;
`;

export const AdditionalInformationPage = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  let [formValues, setFormValues] = useState({
    ...props.appState.additionalInformationState,
  });

  let handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    formValues[name] = value;
    setFormValues(formValues);
  };

  const backButtonHandler = () => {
    props.storeAdditionalInformationState(formValues);
    if (props.appState.employmentStatusState.s1IsRecordOfEmployment) {
      history.push("/employmentStatus");
    } else {
      history.push("/employmentHistory2");
    }
  };

  const onSubmit = () => {
    window.scrollTo(0, 0);
    props.toggleSpinner();
    formValues.id = props.appState.appIdAndToken.token;
    props.storeAdditionalInformationState(formValues);
    axios
      .post(callPaths.screen6, formValues)
      .then((response) => {
        props.toggleSpinner();
        props.isAppCompleted();
        history.push("/thankYouScreen");
        window.scrollTo(0, 0);
      })
      .catch(function(error) {
        props.toggleSpinner();
        console.log(error);
      });
  };
  const onKeyPress = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };
  useEffect(() => {
    if (!props.appState.appIdAndToken) {
      history.push("/");
    }
    if (props.appState.isApplicationCompleted) {
      history.push("/thankYouScreen");
    }
  }, []);
  return (
    <>
      <LoanAppHeader progress={90} />
      <MainWrapper>
        <Center>
          <ColorBoxGroup>
            <ColorBox green>
              <LeftAlign>
                <FormattedMessage id="lpp_page6_header" />
              </LeftAlign>
            </ColorBox>
            <ColorBox big blue>
              <FormattedMessage id="lpp_page6_subheader" />
            </ColorBox>
          </ColorBoxGroup>
          <form onKeyPress={onKeyPress}>
            <QuestionGroups>
              <Group>
                <Label htmlFor="s3Comments">
                  <FormattedMessage id="lpp_page6_question1_label" />
                  <ToolTip
                    role="link"
                    tabIndex="0"
                    className="tooltip"
                    src={TooltipSVG}
                    alt="tooltip"
                    onClick={() =>
                      props.showTooltip("tooltip_title", [
                        "tooltip_text10",
                        "tooltip_text10_1",
                        "tooltip_text10_2_inline",
                      ])
                    }
                  />
                </Label>
                <QuestionWrap>
                  <InputWrapper isValid={errors.s3Comments === undefined}>
                    <input
                      type="textarea"
                      name="s3Comments"
                      id="s3Comments"
                      placeholder=""
                      onChange={handleChange}
                      ref={register({ maxLength: 500 })}
                      rows="5"
                      defaultValue={setVal(
                        props.appState.additionalInformationState,
                        "s3Comments"
                      )}
                    />
                    {errors.s3Comments && (
                      <ErrorMessage>
                        <FormattedMessage id="lpp_validation_max500" />
                      </ErrorMessage>
                    )}
                  </InputWrapper>
                </QuestionWrap>
              </Group>
            </QuestionGroups>
            <NextPrevButtons>
              <Button outlined onClick={backButtonHandler}>
                <FormattedMessage id="lpp_cta_back" />
              </Button>
              <Button onClick={handleSubmit(onSubmit)} type="button">
                <FormattedMessage id="lpp_cta_submit" />
              </Button>
            </NextPrevButtons>
          </form>
        </Center>
      </MainWrapper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    appState: state.crossSellApp,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    storeAdditionalInformationState: (data) =>
      dispatch(actionCreators.storeAdditionalInformationState(data)),
    toggleSpinner: () => dispatch(actionCreators.toggleSpinner()),
    showTooltip: (title, texts) =>
      dispatch(
        actionCreators.showTooltip({
          title: title,
          text: texts,
        })
      ),
    isAppCompleted: () => dispatch(actionCreators.isApplicationCompleted(true)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AdditionalInformationPage));
