import React, { Component } from "react";
import styles from "./Main.module.css";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoanNumberPage from "./LoanNumberPage/LoanNumberPage";
import CustomerInfoPage from "./CustomerInfoPage/CustomerInfoPage";
import EmploymentStatusPage from "./EmploymentStatusPage/EmploymentStatusPage";
import EmployerInfoPage from "./EmployerInfoPage/EmployerInfoPage";
import EmploymentHistoryPage from "./EmploymentHistoryPage/EmploymentHistoryPage";
import EmploymentHistoryPage2 from "./EmploymentHistoryPage2/EmploymentHistoryPage2";
import AdditionalInformationPage from "./AdditionalInformationPage/AdditionalInformationPage";
import VoidChequePage from "./VoidChequePage/VoidChequePage";
import EsignPage from "./EsignPage/EsignPage";
import CompleteProcessPage from "./CompleteProcessPage/CompleteProcessPage";
import ThankYouPage from "./ThankYouPage/ThankYouPage";
import AlreadyExistsPage from "./AlreadyExistsPage/AlreadyExistsPage";
import EsignEmailPage from "./EsignEmailPage/EsignEmailPage";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";

class Main extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.spinerActive) {
      document.body.classList.add("spiner_active");
    } else {
      document.body.classList.remove("spiner_active");
    }
  }

  render() {
    return (
      <div className={`${styles.main_Content}}`}>
        <LoadingIndicator isActive={this.props.appState.loadingStarted} />
        <ErrorMessage isActive={this.props.appState.isServerError} />
        <Router>
          <Switch>
            <Route exact path="/">
              <LoanNumberPage />
            </Route>
            <Route exact path="/customerInfo">
              <CustomerInfoPage />
            </Route>
            <Route exact path="/employmentStatus">
              <EmploymentStatusPage />
            </Route>
            <Route exact path="/employerInfo">
              <EmployerInfoPage />
            </Route>
            <Route exact path="/employmentHistory">
              <EmploymentHistoryPage />
            </Route>
            <Route exact path="/employmentHistory2">
              <EmploymentHistoryPage2 />
            </Route>
            <Route exact path="/additionalInformation">
              <AdditionalInformationPage />
            </Route>
            <Route exact path="/uploadDocuments">
              <VoidChequePage />
            </Route>
            <Route path="/lpp/esign">
              <EsignEmailPage />
            </Route>
            <Route path="/esign">
              <EsignPage />
            </Route>
            <Route exact path="/completeTheProcess">
              <CompleteProcessPage />
            </Route>
            <Route path="/thankYouScreen">
              <ThankYouPage />
            </Route>
            <Route path="/alreadyExists">
              <AlreadyExistsPage />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appState: state.crossSellApp,
    spinerActive: state.crossSellApp.loadingStarted,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
