exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Preview_vc_modal_size__20y11 {\n    max-width: 80% !important;\n    max-height: 80vh !important;\n}\n.Preview_image_size__2mTT5 {\n    max-height: 80vh;\n    max-width: 80%;\n}\n.Preview_document_section__3DEs5 {\n    width: 100%;\n    background-color: #ffffff;\n    margin-bottom: 7px;\n    padding: 5px;\n    box-shadow: 0 0 10px rgba(0,0,0,0.5)\n}\n.Preview_document_section_content__1uW4H {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n.Preview_document_section_img_wrapper__yhwAe {\n    max-height: 140px;\n    max-width: 100px;\n}\n.Preview_document_section_img_wrapper__yhwAe img,\n.Preview_document_section_img_wrapper__yhwAe iframe{\n    width: 100%;\n    height: 100%;\n}\n.Preview_document_section_remove_btn__xFDSD {\n    cursor: pointer;\n}", ""]);

// Exports
exports.locals = {
	"vc_modal_size": "Preview_vc_modal_size__20y11",
	"image_size": "Preview_image_size__2mTT5",
	"document_section": "Preview_document_section__3DEs5",
	"document_section_content": "Preview_document_section_content__1uW4H",
	"document_section_img_wrapper": "Preview_document_section_img_wrapper__yhwAe",
	"document_section_remove_btn": "Preview_document_section_remove_btn__xFDSD"
};