/**
 *
 * ProgressBar
 * Horizontal loader
 *
 */

import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../styles/constants';

import React from 'react';
import ProgressIndicator from './ProgressIndicator';

const ProgressWrapper = styled.div`
  background: ${styles.color.white};
  z-index: 1;
`;

class ProgressBar extends React.PureComponent {
  render() {
    const { progress } = this.props;
    return (
      <ProgressWrapper aria-label="Small header">
        <ProgressIndicator
          progress={progress}
          role="progressbar"
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
          aria-label="Progress Bar"
        />
      </ProgressWrapper>
    );
  }
}

ProgressBar.propTypes = {
  /** Number 0-100 indicating progress as a percent */
  progress: PropTypes.number.isRequired,
};

export default ProgressBar;
