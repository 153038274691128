/**
 *
 * date birth dropdown
 *
 */

import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { times } from 'lodash';
import styled from 'styled-components';
import styles from '../../styles/constants';
import { size, getFontSizes, media } from '../../styles/mixins';
import QUESTION_DEFAULTS from '../../constants/questions/defaults';
import QUESTION_TYPES from '../../constants/questions/types';
import moment from 'moment';

const { blue, green, red, gray } = styles.color;
const UPTOYEAR = 1900;
const ACTIONMONTH = 'month';
const ACTIONYEAR = 'year';

function getBorderBottomColor(props) {
  if (props.isClean) {
    return blue;
  }

  if (props.isValid) {
    return gray;
  }

  return red;
}

function getTextColor(props) {
  if (props.isDefault) {
    return styles.color.placeholder;
  }

  return styles.color.text;
}

const SelectWrapper = styled.div`
  border-bottom: 2px solid ${(props) => getBorderBottomColor(props)};
  border-radius: 0;
  padding: ${size()} 0 0;
  width: '150px';

  :focus-within {
    border-bottom-color: ${(props) =>
      props.isValid || props.isClean ? green : red};
    outline-offset: 2px;
  }
  label {
    display:none;
  }
`;

const Select = styled.select`
  position: relative;
  ${getFontSizes('text_input')} border: none;
  color: ${(props) => getTextColor(props)};
  width: 30%;
  padding: ${size()} 0 ${size(2)};
  margin-bottom: ${size(2)};
  margin-left: 8px;
  text-align: center;
  text-align-last: center;

  ${media.medium`
    margin-left: 15px;
    text-align: center;
    text-align-last: center;
  `};

  &[disabled] {
    background-color: white;
    border-bottom: none;
  }
`;

class InputDateField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.value = props.value;
    this.handleChangeDate = this.handleChangeDate.bind(this);
  

  this.state = {
    year: '',
    month: '',
    date: '',
    dateValue: 0,
    totalYears: props.increaseYear ? parseInt(new Date().getFullYear()) + 1 : parseInt(new Date().getFullYear())
  };
}

  handleChnageYear(e) {
    const yearVal = e.target.value;
    this.setState({ year: yearVal });
    this.updateNumberOfDays(yearVal, '', '', ACTIONYEAR);
  }

  handleChnageMonth(e) {
    const monthVal = e.target.value;
    this.setState({ month: monthVal });
    this.updateNumberOfDays('', monthVal, '', ACTIONMONTH);
  }

  updateNumberOfDays(yearVal, monthVal, value, action) {
    const year =
      yearVal === '' && action === ACTIONYEAR
        ? ''
        : yearVal === '' && action !== ACTIONYEAR ? this.state.year : yearVal;
    const month =
      monthVal === '' && action === ACTIONMONTH
        ? ''
        : monthVal === '' && action !== ACTIONMONTH
          ? this.state.month
          : monthVal;
    if (year !== '' && month !== '') {
      const days = new Date(year, month, 0).getDate();
      this.setState({ dateValue: days });
      this.resetdDate(year, month);
    } else {
      this.setState({ dateValue: '', date: '' });
      this.resetSelectedDate('');
    }
  }

  resetdDate(year, month) {
    const selectedDob = this.props.value;
    if (selectedDob !== '') {
      const getDate = moment(selectedDob).format('DD');
      const dateval = year + '-' + month + '-' + getDate;
      const newDate = moment(dateval).format('YYYY-MM-DD');
      if (moment(newDate, 'YYYY-MM-DD', true).isValid() === true) {
        this.resetSelectedDate(newDate);
      } else {
        this.resetSelectedDate('');
      }
    }
  }
  formatNumber(number) {
    return ('0' + (number + 1)).slice(-2);
  }

  handleChangeDate(e) {
    const { onChange, name, type } = this.props;
    const currentValue = e.target.value;
    const getDate =
      currentValue !== '' ? moment(currentValue).format('DD') : '';
    this.resetSelectedDate(currentValue);
    this.setState({ date: getDate });
  }

  resetSelectedDate(value) {
    const { onChange, name, type } = this.props;
    const currentValue = value !== '' ? value : '';
    const modifiedEvent = {
      target: {
        name,
        type,
        value: currentValue,
      },
    };
    onChange(modifiedEvent);
  }

  loadYearDropDownValues() {
    const yearVals = [];
    for (let i = this.state.totalYears; i >= UPTOYEAR; i--) {
      yearVals.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return yearVals;
  }

  validateDate() {
    const val = this.props.value;
    if (moment(val, 'YYYY-MM-DD', true).isValid() === true) {
      const yearVal = moment(val).format('YYYY');
      const monthval = moment(val).format('MM');
      this.setState({ year: yearVal, month: monthval });
      this.updateNumberOfDays(yearVal, monthval, val);
    }
  }

  componentDidMount() {
    this.validateDate();
  }

  render() {
    const {
      intl,
      onBlur,
      onFocus,
      placeholder,
      name,
      value,
      isDisabled,
      isValid,
      isClean,
    } = this.props;

    const isDefault = QUESTION_DEFAULTS[QUESTION_TYPES.DROPDOWN] === value;
    const daysResult = this.state.dateValue;
    const monthNames = [
      intl.formatMessage({ id: 'q_month_jan' }),
      intl.formatMessage({ id: 'q_month_feb' }),
      intl.formatMessage({ id: 'q_month_mar' }),
      intl.formatMessage({ id: 'q_month_apr' }),
      intl.formatMessage({ id: 'q_month_may' }),
      intl.formatMessage({ id: 'q_month_jun' }),
      intl.formatMessage({ id: 'q_month_jul' }),
      intl.formatMessage({ id: 'q_month_aug' }),
      intl.formatMessage({ id: 'q_month_sep' }),
      intl.formatMessage({ id: 'q_month_oct' }),
      intl.formatMessage({ id: 'q_month_nov' }),
      intl.formatMessage({ id: 'q_month_dec' }),
    ];

    return (
      <SelectWrapper
        isDisabled={isDisabled}
        isValid={isValid}
        isClean={isClean}
      >
        <label htmlFor="year">year</label>
        <Select
          isDefault={isDefault}
          value={this.state.year}
          onChange={this.handleChnageYear.bind(this)}
          id="year"
          aria-label="Year"
        >
          <option value="" disabled aria-hidden >
            {intl.formatMessage({ id: 'q_birth_year' })}
          </option>
          {this.loadYearDropDownValues()}
        </Select>
        <label htmlFor="month">month</label>
        <Select
          isDefault={isDefault}
          value={this.state.month}
          onChange={this.handleChnageMonth.bind(this)}
          id="month"
          aria-label="Month"
        >
          <option value="" disabled aria-hidden >
            {intl.formatMessage({ id: 'q_birth_month' })}
          </option>
          {times(12, (mIndex) => (
            <option
              key={this.formatNumber(mIndex)}
              value={this.formatNumber(mIndex)}
            >
              {monthNames[mIndex]}
            </option>
          ))}
        </Select>
        <label htmlFor="date">date</label>
        <Select
          onChange={this.handleChangeDate}
          onBlur={onBlur}
          onFocus={onFocus}
          name={name}
          isDisabled={isDisabled}
          isValid={isValid}
          isClean={isClean}
          value={value}
          isDefault={isDefault}
          id="date"
          aria-label="Day"
        >
          <option value="" disabled  aria-hidden >
            {intl.formatMessage({ id: placeholder })}
          </option>
          {times(daysResult, (dIndex) => (
            <option
              key={this.formatNumber(dIndex)}
              value={
                this.state.year +
                '-' +
                this.state.month +
                '-' +
                this.formatNumber(dIndex)
              }
            >
              {this.formatNumber(dIndex)}
            </option>
          ))}
        </Select>
      </SelectWrapper>
    );
  }
}

InputDateField.propTypes = {
  intl: intlShape.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  isValid: PropTypes.bool,
  isClean: PropTypes.bool,
};

export default injectIntl(InputDateField);
