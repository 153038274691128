import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { callPaths } from '../../configuration';
import * as actionCreators from '../../store/LppProjectActions';
import styled from 'styled-components';
import Button from '../Button';
import DropzoneMessage from '../DropzoneMessage/DropzoneMessage';
import Preview from '../Preview/Preview';

import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { concat } from 'lodash';

const NextPrevButtons = styled.div`
    display: flex;
`;
const ToolTip = styled.img`
    float: right;
    display: none;
`;
const oneMb = 1000000;
let maxFileSize = null;

const VoidCheque = (props) => {
    const uploadedFiles = [];
    const failedFiles = [];
    const history = useHistory();
    const [isSubmitBtn, setSubmitBtn] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);

    const handleChangeStatus = ({ meta }, status, files) => {
        if (status === 'rejected_file_type') {
            props.showTooltip('tooltip_empty', ['tooltip_incorrect_format']);
        }
        const result = files.filter((el) => {
            return el.meta.status === 'done';
        });
        if (result.length > 0) {
            setSubmitBtn(true);
        } else {
            setSubmitBtn(false);
        }
    };

    const onSubmit = (currentFile) => {
        const fd = new FormData();
        fd.append('id', props.appState.appIdAndToken.token);
        fd.append('filePathes', currentFile);

        return axios.post(callPaths.uploadDoc, fd);
    };

    const handleSubmit = (files, allFiles) => {
        props.toggleSpinner();
        const arrOfPromises = [];
        allFiles.forEach((f) => {
            const result = onSubmit(f.file)
                .then((response) => {
                    if (response.data.status === 'FAIL') {
                        failedFiles.push(response.data.fileName);
                    } else {
                        f.remove();
                        uploadedFiles.push(
                            response.data.savedDocuments[0].filename
                        );
                    }
                })
                .catch(function(error) {
                    return Promise.reject();
                });
            arrOfPromises.push(result);
        });

        Promise.all(arrOfPromises)
            .then((values) => {
                props.isAppCompleted();
                let result;
                if (uploadedFiles.length > 0 && failedFiles.length > 0) {
                    result = concat(
                        ['tooltip_uploaded_bold'],
                        uploadedFiles,
                        ['tooltip_failed_bold'],
                        failedFiles
                    );
                    setSubmitBtn(false);
                } else if (
                    failedFiles.length === 0 &&
                    uploadedFiles.length > 0
                ) {
                    result = concat(['tooltip_uploaded_bold'], uploadedFiles);
                    props.showTooltip('tooltip_empty', result);
                    setSubmitSuccess(true);
                } else if (failedFiles.length > 0) {
                    result = concat(['tooltip_failed_bold'], failedFiles);
                }
                props.showTooltip('tooltip_empty', result);
                props.toggleSpinner();
            })
            .catch((error) => {
                props.toggleSpinner();
            });
    };

    const triggerSubmitHandler = () => {
        const btn = document.getElementsByClassName('dzu-submitButton')[0];
        btn.click();
    };
    const backButtonHandler = () => {
        history.push('/completeTheProcess');
    };
    const setMessage = () => {
        return (
            <DropzoneMessage
                fileFormat={props.appState.fileFormats}
                fileSize={props.appState.fileSize}
            />
        );
    };
    useEffect(() => {
        maxFileSize = +props.appState.fileSize;
        if (!props.appState.appIdAndToken) {
            history.push('/');
        }
    }, []);
    useEffect(() => {
        if (submitSuccess && !props.appState.tooltip.isVisible) {
            history.push('/completeTheProcess');
        }
    }, [props.appState.tooltip]);
    return (
        <div>
            <ToolTip
                role="link"
                tabIndex="0"
                className="tooltip"
                alt="tooltip"
            />
            <Dropzone
                inputContent={setMessage}
                accept={props.appState.fileFormats}
                onChangeStatus={handleChangeStatus}
                PreviewComponent={Preview}
                onSubmit={handleSubmit}
                maxSizeBytes={props.appState.fileSize * oneMb}
                styles={{
                    dropzone: {
                        minHeight: '60vh',
                        minHeight: '50vh',
                        overflow: 'auto',
                        padding: '20px',
                    },
                    dropzoneActive: { borderColor: 'green' },
                    submitButtonContainer: { display: 'none', width: '100%' },
                    submitButton: {
                        background: '#158709',
                        color: '#fff',
                        width: '100%',
                        padding: '16px 24px',
                    },
                    inputLabelWithFiles: {
                        background: '#158709',
                        color: '#fff',
                        margin: '20px 0 0 0',
                    },
                    previewImage: { maxHeight: '100px', maxWidth: '100px' },
                }}
            />
            <NextPrevButtons>
                <Button outlined onClick={backButtonHandler}>
                    <FormattedMessage id="lpp_cta_back" />
                </Button>
                <Button
                    dis
                    onClick={triggerSubmitHandler}
                    disabled={!isSubmitBtn}
                    type="button"
                >
                    <FormattedMessage id="lpp_cta_submit" />
                </Button>
            </NextPrevButtons>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        appState: state.crossSellApp,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSpinner: () => dispatch(actionCreators.toggleSpinner()),
        isAppCompleted: () =>
            dispatch(actionCreators.isApplicationCompleted(true)),
        showTooltip: (title, text) =>
            dispatch(
                actionCreators.showTooltip({
                    title: title,
                    text: text,
                    isUpload: true,
                })
            ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VoidCheque);
