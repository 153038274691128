import { css } from 'styled-components';
import styles from '../../styles/constants';
import { size, getFontSizes, media } from '../../styles/mixins';

// Set what colour ColorBox will use based on props

function getBGColor() {
  return (props) => {
    const { blue, green, black } = props;
    if (blue) {
      return styles.color.blue;
    }
    if (green) {
      return styles.color.green;
    }
    if (black) {
      return styles.color.black;
    }
    // if none of the above, return until default colour is determined.
    return 'hotpink';
  };
}

function getSmallPadding() {
  return (props) => {
    const { big } = props;

    if (big) {
      return `${size(2.5)} ${size(3)}`;
    }

    return `${size(0.5)} ${size(2)}`;
  };
}

function getMediumPadding() {
  return (props) => {
    const { big } = props;

    if (big) {
      return `${size(2.5)} ${size(3)}`;
    }

    return `${size(1.5)} ${size(2)}`;
  };
}

function getWidth() {
  return (props) => {
    const { left, big } = props;

    if (left) {
      return 'auto';
    } else if (big) {
      return  '100%';
    } else {
      return 'auto';
    }
  };
}

const colorBoxStyles = css`
  padding: ${getSmallPadding};
  background-color: ${getBGColor};
  display: inline-block;
  text-transform: uppercase;
  width: ${getWidth};
  color: ${styles.color.white};

  ${media.medium`
    padding: ${getMediumPadding}
  `} & > span {
    ${getFontSizes('color_box')};
  }

  & > * {
    color: ${styles.color.white};
  }
`;

export default colorBoxStyles;
