let serverName = process.env.REACT_APP_API_SERVER_URL
  ? process.env.REACT_APP_API_SERVER_URL
  : "https://startclaim-api-dit.herokuapp.com";
export const callPaths = {
  //API demo calls:
  screen2: `${serverName}/api/lpp/resubmit/save/screen2`,
  screen3: `${serverName}/api/lpp/resubmit/save/screen3`,
  screen4: `${serverName}/api/lpp/resubmit/save/screen4`,
  screen5: `${serverName}/api/lpp/resubmit/save/screen5`,
  screen6: `${serverName}/api/lpp/resubmit/save/screen6`,
  completeOnline: `${serverName}/api/lpp/completedOnlineOrByPhone`,
  getToken: `${serverName}/api/lpp/getSignUrl?id=`,
  uploadDoc: `${serverName}/api/lpp/uploadDoc`,
  apiUrl: `${serverName}`
};
