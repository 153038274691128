import styled from 'styled-components';
import { media } from '../../styles/mixins';

import Side from './Side';

const Center = styled(Side)`
  margin: auto;
  ${media.medium`
        width: 690px;
    `}};
`;

export default Center;
