import styles from '../styles/constants';

export const getCurrentBreakpoint = () => {
  const { innerWidth } = window;

  // All breakpoints will be stored as var 'breakpoints'
  const { breakpoint: breakpoints } = styles;

  // loop through all breakpoints to find a breakpoint larger than the innerWidth
  const currentBreakpoint = Object.entries(breakpoints).find(
    (thisBreakpoint) => innerWidth < thisBreakpoint[1]
  );

  if (currentBreakpoint) {
    return currentBreakpoint[0];
  }

  return Object.keys(breakpoints).pop();
};
