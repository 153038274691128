/**
 *
 * InputNumber
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import InputText from './index';

class InputPhone extends React.PureComponent {
  constructor(props) {
    super(props);

    this.value = props.value;
    this.preOnChange = this.preOnChange.bind(this);
  }

  getConvertedValue(value) {
    // Convert into format (xxx) xxx-xxxx
    if (value.length === 0) {
      return '';
    }
    let convertedValue = `(${value.substr(0, 3)}`;

    if (value.length < 3) {
      return convertedValue;
    }

    convertedValue = `${convertedValue}) ${value.substr(3, 3)}`;

    if (value.length < 6) {
      return convertedValue;
    }

    return `${convertedValue}-${value.substr(6)}`;
  }

  /**
   * Modify the event being passed up so that the value is stored as a number
   */
  preOnChange(e) {
    const { onChange } = this.props;
    const { value, name, type } = e.target;

    let modifiedValue = value.replace(/\D/g, '');
    const isDeleting = modifiedValue === this.lastValue;

    // User has tried to strip off an extra digit but couldn't because of the placeholder,
    // so strip it off for them
    if (isDeleting && (value.length === 5 || value.length === 9)) {
      modifiedValue = modifiedValue.substr(0, modifiedValue.length - 1);
    }

    const modifiedEvent = {
      target: {
        name,
        type,
        value: modifiedValue,
      },
    };

    onChange(modifiedEvent);
    this.lastValue = modifiedValue;
  }

  render() {
    const value = this.getConvertedValue(this.props.value);

    return (
      <InputText
        {...this.props}
        value={value}
        minLength={10}
        maxLength={14}
        type="tel"
        onChange={this.preOnChange}
      />
    );
  }
}

InputPhone.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default InputPhone;
