export function loadCSS(href) {
  const head = document.getElementsByTagName('head')[0];
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = href;
  link.media = 'all';
  head.appendChild(link);
}

export function loadJS(src, callback) {
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');

  script.type = 'text/javascript';

  if (script.readyState) {
    // IE
    script.onreadystatechange = () => {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        if (typeof callback === 'function') {
          callback();
        }
      }
    };
  } else {
    // Others
    script.onload = () => {
      if (typeof callback === 'function') {
        callback();
      }
    };
  }

  script.src = src;
  head.appendChild(script);
}

  export function SpaceRemoverHelper(str) {
    const pattern = /[\f\n\r\t\v ]{2,}/g;
    const replacement = " ";

    const result = str.replace(pattern, replacement);
    return result;
  }
