import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import * as actionCreators from "../../../store/LppProjectActions";
import { callPaths } from "../../../configuration";
import { setVal } from "../../../helpers/setValueHelper";
import * as moment from "moment";
import { decimalNumberValidation } from "../../../helpers/validationHelper";

import styled from "styled-components";
import LoanAppHeader from "../../../components/LoanHeader";
import MainWrapper from "../../../components/MainWrapper";
import Button from "../../../components/Button";
import Center from "../../../components/MainWrapper/Center";
import Group from "../../../components/Group";
import QuestionGroups from "../../../components/QuestionGroups";
import Label from "../../../components/QuestionWrapper/Label";
import FormChecklist from "../../../components/FormCheckList";
import FormCheck from "../../../components/FormCheck";
import InputRadio from "../../../components/InputRadioGroup/InputRadio";
import ColorBox from "../../../components/ColorBox";
import ColorBoxGroup from "../../../components/ColorBoxGroup";
import TooltipSVG from "../../../components/QuestionWrapper/tooltip.svg";
import InputWrapper from "../../../components/InputText/InputWrapper";
import ErrorMessage from "../../../components/QuestionWrapper/ErrorMessage";
import InputDateField from "../../../components/InputText/InputDateField";

const LeftAlign = styled.div`
  text-align: left;
`;

const ToolTip = styled.img`
  float: right;
`;

const QuestionWrap = styled.div`
  margin-top: 8px;
`;

const NextPrevButtons = styled.div`
  display: flex;
`;

const AdditionalQuestions = styled.div`
  margin-top: 1em;
  margin-left: 2em;
`;

const EmploymentHistoryPage = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();

  const [collapse1, setCollapse1] = useState(false);

  let [formValues, setFormValues] = useState({
    ...props.appState.employmentHistoryState,
  });

  const emplymentStatusList = [
    { permanent: null },
    { seasonal: null },
    { temporary: null },
    { contract: null },
    { self_employed: null },
  ];
  let severanceYes = null;
  let severanceNo = null;
  let returnedToWorkYes = null;
  let returnedToWorkNo = null;
  let fdYes = null;
  let fdNo = null;
  let currentState = props.appState.employmentHistoryState;

  if (currentState) {
    if (
      currentState.s3IsEmployeeReceiveSeverance &&
      currentState.s3IsEmployeeReceiveSeverance !== ""
    ) {
      severanceYes = true;
    } else if (
      !currentState.s3IsEmployeeReceiveSeverance &&
      currentState.s3IsEmployeeReceiveSeverance !== ""
    ) {
      severanceNo = true;
    }

    if (
      currentState.s1IsReturnedToWork &&
      currentState.s1IsReturnedToWork !== ""
    ) {
      returnedToWorkYes = true;
    } else if (
      !currentState.s1IsReturnedToWork &&
      currentState.s1IsReturnedToWork !== ""
    ) {
      returnedToWorkNo = true;
    }
    if (
      currentState.s3IsEmployeeResumedFullDuties &&
      currentState.s3IsEmployeeResumedFullDuties !== ""
    ) {
      fdYes = true;
    } else if (
      !currentState.s3IsEmployeeResumedFullDuties &&
      currentState.s3IsEmployeeResumedFullDuties !== ""
    ) {
      fdNo = true;
    }

    for (let i = 0; i < emplymentStatusList.length; i++) {
      if (
        emplymentStatusList[i].hasOwnProperty(
          currentState.s3EmploymentType.toLowerCase()
        )
      ) {
        emplymentStatusList[i][
          currentState.s3EmploymentType.toLowerCase()
        ] = true;
      }
    }
  }

  let handleChange = (e) => {
    let name = e.target.name;
    let id = e.target.id;
    collapseHandler(id, name);
    let value;
    if (name === "s3EmploymentType") {
      value = id;
    } else if (
      name === "s3IsEmployeeReceiveSeverance" ||
      name === "s1IsReturnedToWork" ||
      name === "s3IsEmployeeResumedFullDuties"
    ) {
      value =
        id === "severanceYes" || id === "returnedToWorkYes" || id === "fdYes";
    } else {
      value = e.target.value;
    }
    formValues[name] = value;
    setFormValues(formValues);
  };
  const backButtonHandler = () => {
    props.storeEmploymentHistoryState(formValues);
    history.push("/employerInfo");
  };

  const [isValidStartWorking, setIsValidStartWorking] = useState(true);
  const [dob, setDate] = useState("");

  const isFeautureDate = (date) => {
    var today = new Date();
    var dateForChecking = new Date(date);
    let result = date !== "" ? today <= dateForChecking  : true;
    return result;
  };

  const changeDate = (date, field) => {
    if (date ) {
      // Date format YYYY-MM-DD
      formValues[field] = date;
      setFormValues(formValues);
      setDate(date);
      if(field  === 's3HiredDate') {
        if(isFeautureDate(date)) {
          setIsValidStartWorking(false);
        } else {
          setIsValidStartWorking(true);
        }
      }
    }
  };

  const onSubmit = () => {
    window.scrollTo(0, 0);
    if (!isValidStartWorking) {
      return;
    }
    props.toggleSpinner();
    formValues.id = props.appState.appIdAndToken.token;
    formValues.screen = "EMPLOYMENT_HISTORY";
    props.storeEmploymentHistoryState(formValues);
    axios
      .post(callPaths.screen5, formValues)
      .then((response) => {
        props.toggleSpinner();
        history.push("/employmentHistory2");
        window.scrollTo(0, 0);
      })
      .catch(function(error) {
        props.toggleSpinner();
        console.log(error);
      });
  };

  const collapseHandler = (id, name) => {
    if (id === "SEASONAL" && name === "s3EmploymentType") {
      setCollapse1(true);
    } else if (id !== "SEASONAL" && name === "s3EmploymentType") {
      setCollapse1(false);
      formValues["s3SeasonalEmploymentFrom"] = "";
      formValues["s3SeasonalEmploymentTo"] = "";
    }
  };

  useEffect(() => {
    if (emplymentStatusList[1].seasonal) {
      setCollapse1(true);
    }
    if (!props.appState.appIdAndToken) {
      history.push("/");
    }
  }, []);

  const onKeyPress = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  return (
    <>
      <LoanAppHeader progress={60} />
      <MainWrapper>
        <Center>
          <ColorBoxGroup>
            <ColorBox green>
              <LeftAlign>
                <FormattedMessage id="lpp_page4_header" />
              </LeftAlign>
            </ColorBox>
            <ColorBox big blue>
              <FormattedMessage id="lpp_page4_subheader" />
            </ColorBox>
          </ColorBoxGroup>
          <form onKeyPress={onKeyPress} noValidate>
            <QuestionGroups>
              <Group>
                <Label htmlFor="s3HiredDate">
                  <FormattedMessage id="lpp_page4_question1_label" />
                  &nbsp;
                  {props.appState.employerInfoState.s3CiName}?
                </Label>
                <QuestionWrap>
                  <InputWrapper isValid={errors.s3HiredDate === undefined}>
                    <InputDateField
                      name="s3HiredDate"
                      id="s3HiredDate"
                      placeholder="q_birth_day"
                      onChange={(evt) =>
                        changeDate(evt.target.value, evt.target.name)
                      }
                      onBlur={handleChange}
                      onFocus={() => null}
                      increaseYear = {false}
                      value={formValues.s3HiredDate}
                      isValid={isValidStartWorking}
                    />
                    {!isValidStartWorking && (
                        <ErrorMessage>
                          <FormattedMessage id="lpp_validation_future_date" />
                        </ErrorMessage>
                      )}
                  </InputWrapper>
                </QuestionWrap>
                <Label htmlFor="s3WorkingHoursPerWeek">
                  <FormattedMessage id="lpp_page4_question2_label" />
                  <ToolTip
                    role="link"
                    tabIndex="0"
                    className="tooltip"
                    src={TooltipSVG}
                    alt="tooltip"
                    onClick={() =>
                      props.showTooltip("tooltip_title", ["tooltip_text5"])
                    }
                  />
                </Label>
                <QuestionWrap>
                  <InputWrapper
                    isValid={errors.s3WorkingHoursPerWeek === undefined}
                  >
                    <input
                      type="text"
                      name="s3WorkingHoursPerWeek"
                      id="s3WorkingHoursPerWeek"
                      placeholder=""
                      step=".01"
                      onChange={(e) => {
                        e.target.value = e.target.value.trim();
                        handleChange(e);
                      }}
                      ref={register({
                        validate: (val) => decimalNumberValidation(val),
                      })}
                      defaultValue={setVal(
                        props.appState.employmentHistoryState,
                        "s3WorkingHoursPerWeek"
                      )}
                    />
                    {errors.s3WorkingHoursPerWeek &&
                      errors.s3WorkingHoursPerWeek.type === "validate" && (
                        <ErrorMessage>
                          <FormattedMessage id="lpp_validation_decimal_number" />
                        </ErrorMessage>
                      )}
                  </InputWrapper>
                </QuestionWrap>
              </Group>
              <Group>
                <Label htmlFor="typeOfemployment">
                  <FormattedMessage id="lpp_page4_question3_label" />
                  <ToolTip
                    role="link"
                    tabIndex="0"
                    className="tooltip"
                    src={TooltipSVG}
                    alt="tooltip"
                    onClick={() =>
                      props.showTooltip("tooltip_title", [
                        "tooltip_text6_1_bold",
                        "tooltip_text6_2_inline",
                        "tooltip_text6_3_bold",
                        "tooltip_text6_4_inline",
                        "tooltip_text6_5_bold",
                        "tooltip_text6_6_inline",
                        "tooltip_text6_7_bold",
                        "tooltip_text6_8_inline",
                        "tooltip_text6_9_bold",
                        "tooltip_text6_10_inline",
                      ])
                    }
                  />
                </Label>
                <FormChecklist>
                  <FormCheck>
                    <InputRadio
                      id="PERMANENT"
                      name="s3EmploymentType"
                      label="lpp_page4_question3_radio1"
                      onChange={handleChange}
                      defaultChecked={Object.values(emplymentStatusList[0])[0]}
                    />
                  </FormCheck>
                  <FormCheck>
                    <InputRadio
                      id="SEASONAL"
                      name="s3EmploymentType"
                      label="lpp_page4_question3_radio2"
                      onChange={handleChange}
                      defaultChecked={Object.values(emplymentStatusList[1])[0]}
                    />
                  </FormCheck>
                  <FormCheck>
                    <InputRadio
                      id="TEMPORARY"
                      name="s3EmploymentType"
                      label="lpp_page4_question3_radio3"
                      onChange={handleChange}
                      defaultChecked={Object.values(emplymentStatusList[2])[0]}
                    />
                  </FormCheck>
                  <FormCheck>
                    <InputRadio
                      id="CONTRACT"
                      name="s3EmploymentType"
                      label="lpp_page4_question3_radio4"
                      onChange={handleChange}
                      defaultChecked={Object.values(emplymentStatusList[3])[0]}
                    />
                  </FormCheck>
                  <FormCheck>
                    <InputRadio
                      id="SELF_EMPLOYED"
                      name="s3EmploymentType"
                      label="lpp_page4_question3_radio5"
                      onChange={handleChange}
                      defaultChecked={Object.values(emplymentStatusList[4])[0]}
                    />
                  </FormCheck>
                </FormChecklist>
                {collapse1 === true ? (
                  <>
                    <br />
                    <Label>
                      <FormattedMessage id="lpp_page4_collapse1_caption" />
                    </Label>
                    <br />
                    <Label htmlFor="s3SeasonalEmploymentFrom">
                      <FormattedMessage id="lpp_page4_collapse1_label1" />
                    </Label>
                    <QuestionWrap>
                      <InputWrapper isValid={true}>
                        <InputDateField
                          name="s3SeasonalEmploymentFrom"
                          id="s3SeasonalEmploymentFrom"
                          placeholder="q_birth_day"
                          onChange={(evt) =>
                            changeDate(evt.target.value, evt.target.name)
                          }
                          onBlur={handleChange}
                          onFocus={() => null}
                          value={formValues.s3SeasonalEmploymentFrom}
                          increaseYear = {true}
                          isValid={true}
                        />
                      </InputWrapper>
                    </QuestionWrap>
                    <Label htmlFor="s3SeasonalEmploymentTo">
                      <FormattedMessage id="lpp_page4_collapse1_label2" />
                    </Label>
                    <QuestionWrap>
                      <InputWrapper isValid={true}>
                        <InputDateField
                          name="s3SeasonalEmploymentTo"
                          id="s3SeasonalEmploymentTo"
                          placeholder="q_birth_day"
                          onChange={(evt) =>
                            changeDate(evt.target.value, evt.target.name)
                          }
                          onBlur={handleChange}
                          onFocus={() => null}
                          value={formValues.s3SeasonalEmploymentTo}
                          increaseYear = {true}
                          isValid={true}
                        />
                      </InputWrapper>
                    </QuestionWrap>
                  </>
                ) : null}
              </Group>
            </QuestionGroups>
            <NextPrevButtons>
              <Button outlined onClick={backButtonHandler}>
                <FormattedMessage id="lpp_cta_back" />
              </Button>
              <Button onClick={handleSubmit(onSubmit)} type="button">
                <FormattedMessage id="lpp_cta_next" />
              </Button>
            </NextPrevButtons>
          </form>
        </Center>
      </MainWrapper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    appState: state.crossSellApp,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    storeEmploymentHistoryState: (data) =>
      dispatch(actionCreators.storeEmploymentHistoryState(data)),
    toggleSpinner: () => dispatch(actionCreators.toggleSpinner()),
    showTooltip: (title, texts) =>
      dispatch(
        actionCreators.showTooltip({
          title: title,
          text: texts,
        })
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EmploymentHistoryPage));
