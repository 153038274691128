import axios from 'axios';
import * as actions from './actions';
import { getToken } from '../helpers/callHelperFunctions';
import { callPaths } from '../configuration';

export const storeAppId = (id) => {
    return {
        type: actions.GET_APP_ID,
        value: id,
    };
};

export const storeToken = (token) => {
    return {
        type: actions.STORE_TOKEN,
        value: token,
    };
};

export const toggleSpinner = () => {
    return {
        type: actions.TOGGLE_SPINNER,
    };
};

export const finishDocumentSigning = (appState) => {
    return (dispatch) => {
        dispatch(toggleSpinner());
        dispatch(toggleSpinner());
        esignSuccessInform(appState, dispatch, showThankYouScreen);
    };
};

export const esignSuccessInform = (appState, dispatch, showThankYouScreen) => {
    let eData = getToken(appState.esignData.url);

    return (
        axios
            // .get(
            //   `${callPaths.apiUrl}/api/lpp/esign/callback?tooken=${localStorage.getItem('token')}&signer=signer1&status=SIGNER_COMPLETE&lppAppId=${localStorage.getItem('id')}`
            // )
            .get(
                `${callPaths.apiUrl}/api/lpp/esign/callback?tooken=${eData}&signer=signer1&status=SIGNER_COMPLETE&lppAppId=${appState.token}`
            )
            .then((response) => {
                if (response.status === 200) {
                    dispatch(showThankYouScreen());
                    // dispatch(toggleSpinner());
                } else if (response.status === 204) {
                    console.log('NO_CONTENT');
                }
            })
            .catch((err) => {
                // dispatch(isServerError(true));
                console.log(err);
            })
    );
};
export const storeEsignData = (esignData) => {
    return {
        type: actions.STORE_ESIGN_DATA,
        value: esignData,
    };
};
export const showThankYouScreen = () => {
    return {
        type: actions.SHOW_THANKYOU_SCREEN,
    };
};
export const isServerError = (isError) => {
    return {
        type: actions.IS_SERVER_ERROR,
        value: isError,
    };
};

export const storeCustomerInfoState = (data) => {
    return {
        type: actions.STORE_CUSTOMER_INFO_STATE,
        value: data,
    };
};

export const storeEmploymentStatusState = (data) => {
    return {
        type: actions.STORE_EMPLOYMENT_STATUS_STATE,
        value: data,
    };
};

export const storeEmployerInfoState = (data) => {
    return {
        type: actions.STORE_EMPLOYER_INFO_STATE,
        value: data,
    };
};

export const storeEmploymentHistoryState = (data) => {
    return {
        type: actions.STORE_EMPLOYMENT_HISTORY_STATE,
        value: data,
    };
};

export const storeAdditionalInformationState = (data) => {
    return {
        type: actions.STORE_ADDITIONAL_INFO_STATE,
        value: data,
    };
};

export const showTooltip = (data) => {
    return {
        type: actions.TOOLTIP_SHOW,
        value: data,
    };
};

export const hideTooltip = () => {
    return {
        type: actions.TOOLTIP_HIDE,
    };
};
export const isApplicationCompleted = (data) => {
    return {
        type: actions.IS_APPLICATION_COMPLETED,
        value: data,
    };
};
export const setCompleteType = (data) => {
    return {
        type: actions.SET_COMPLETE_TYPE,
        value: data,
    };
};

export const setFileFormats = (data) => {
    return {
        type: actions.SET_FILE_FORMATS,
        value: data,
    };
};

export const setFileSize = (data) => {
    return {
        type: actions.SET_FILE_SIZE,
        value: data,
    };
};

export const setSpecialCharacters = (data) => {
    return {
        type: actions.SET_SPECIAL_CHARACTERS,
        value: data,
    };
};
