const BREAKPOINTS = {
  XSMALL: 1,
  SMALL: 2,
  MEDIUM: 3,
  LARGE: 4,
  XLARGE: 5,
};

export const BREAKPOINT_NAMES = {
  [BREAKPOINTS.XSMALL]: 'xsmall',
  [BREAKPOINTS.SMALL]: 'small',
  [BREAKPOINTS.MEDIUM]: 'medium',
  [BREAKPOINTS.LARGE]: 'large',
  [BREAKPOINTS.XLARGE]: 'xlarge',
};

export default BREAKPOINTS;
