import React, { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import * as actionCreators from "../../../store/LppProjectActions";

// import styled from 'styled-components';
import styles from "./EsignPage.module.css";
import LoanAppHeader from "../../../components/LoanHeader";
import MainWrapper from "../../../components/MainWrapper";
import Center from "../../../components/MainWrapper/Center";
const EsignPage = (props) => {
  const history = useHistory();

  const receiveMessage = (e) => {
    const origin = e.origin || e.originalEvent.origin;
    const data = e.data;

    if (~origin.indexOf("esignlive") || ~origin.indexOf("e-signlive")) {
      switch (data) {
        case "ESL:MESSAGE:REGISTER":
          e.source.postMessage("ESL:MESSAGE:ACTIVATE_EVENTS", origin);
          break;
        case "ESL:MESSAGE:STARTED:SIGNER_COMPLETE":
          e.source.postMessage(data, origin);
          setTimeout(props.finishDocumentSigning(props.appState), 3000);

          break;
        case "ESL:MESSAGE:SUCCESS:PACKAGE_DECLINE":
          break;
        default:
          e.source.postMessage(data, origin);
          break;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
  }, []);
  useEffect(() => {
    if (props.appState.showThankYouScreen) {
      history.push("/thankYouScreen");
    }
  }, [props.appState.showThankYouScreen]);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    if (!props.appState.esignData) {
      document.body.style.overflow = "auto";
      history.push("/");
    }
  }, [props.appState.esignData]);
  return (
    <>
	<div className={styles.headerWrapper}>
	  <LoanAppHeader progress={90} />
	</div>     
      <MainWrapper>
        <Center className={styles.iframe_wrapper}>
          <iframe
            src={props.appState.esignData ? props.appState.esignData.url : ""}
            title="Loan Document"
            frameBorder="0"
            height="100%"
            width="100%"
            // onLoad={props.toggleSpinner}
          />
        </Center>
      </MainWrapper>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    appState: state.crossSellApp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    finishDocumentSigning: (appState) =>
      dispatch(actionCreators.finishDocumentSigning(appState)),
    toggleSpinenr: () => dispatch(actionCreators.toggleSpinner()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EsignPage);
