import SIZE from '../constants/breakpoints';

const styles = {
  // These are autocompiled into a mixin in the mixins.js file
  // Note - if you add or remove breakpoints, you should add or remove them in the typography section below
  breakpoint: {
    [SIZE.SMALL]: 320,
    [SIZE.MEDIUM]: 695,
    [SIZE.LARGE]: 960,
    [SIZE.XLARGE]: 1606,
  },

  // Values taken from Bootstraps defaults: https://getbootstrap.com/docs/4.0/layout/overview/#z-index
  z: {
    behind: -1,
    dropdown_backdrop: 990,
    dropdown: 1000,
    fixed: 1030,
    sticky: 1030,
    modal_backdrop: 1040,
    modal: 1050,
    popover: 1060,
    helptext: 1070,

    // You should not need to use this
    doing_it_wrong: 10000,
  },

  typography: {
    font: {
      body: "'din-next-lt-pro', 'Helvetica Neue', Helvetica, Aria, sans-serif",
      header:
        "'din-next-lt-pro', 'Helvetica Neue', Helvetica, Aria, sans-serif",
    },

    // Default size is the starter size, and it works up from there with the above breakpoints.  Sizes are in em.
    // Global tags are injected globally at the start, others are referenced via mixin.
    size: {
      defaults: {
        a: 1.5,
        button: 1,
        button_oversized: 1.125,
        button_secondary: 1,
        callout_li: 1.125,
        callout_p: 1.5,
        check_with_text_label: 1,
        color_box: 0.75,
        form_check: 1,
        form_input_validation_message: 0.75,
        form_question: 1.375,
        form_sub_question: 1.125,
        form_validation_message: 0.875,
        h1: 3,
        h2: 1.25,
        h3: 1,
        p: 1,
        progress_heading: 1,
        question_error: 0.75,
        question_label: 1.125,
        question_label_small: 0.875,
        question_helptext: 0.875,
        text_input: 1,
        title_box_title: 1.125,
      },

      breakpoint: {
        [SIZE.SMALL]: {},
        [SIZE.MEDIUM]: {
          button: 1.25,
          button_oversized: 1.5,
          button_secondary: 1.125,
          callout_li: 1.5,
          callout_p: 2.25,
          check_with_text_label: 1.125,
          color_box: 1.125,
          form_check: 1.25,
          form_input_validation_message: 1,
          form_question: 1.25,
          form_sub_question: 1,
          form_validation_message: 1.125,
          h1: 4,
          h2: 2,
          h3: 1.25,
          p: 1.25,
          progress_heading: 1.25,
          question_error: 1,
          question_label: 1.25,
          question_label_small: 1.125,
          question_helptext: 1.125,
          text_input: 1.25,
          title_box_title: 1.5,
        },
        [SIZE.LARGE]: {},
        [SIZE.XLARGE]: {},
      },
    },

    weight: {
      h1: 'bold',
      h2: 'bold',
      h3: 'bold',
    },

    line_height: {
      defaults: {
        header: '1.08',
        body: '1.25',
        h2: '1.19',
        h3: '1.25',
      },
      small: {},
      medium: {},
      large: {
        header: '1.06',
      },
    },
  },

  shadow: {
    default: '-8px 10px 22px 0 rgba(76, 81, 92, 0.18)',
    small: '0 1px 3px 0 rgba(48, 51, 64, 0.3)',
  },

  size: {
    // Base unit to allow scaling on retina
    unit: 8,

    // Shorthand as styled-components does not allow multiplying by px values
    unitpx: '8px',

    border_radius: {
      small: '3px',
      large: '6px',
      main: '5px',

      // Use when you have a rectangle and you want rounded borders on the short sides
      pill: '300px',
    },

    // Magic number from the designers
    form_max_width: '432px',
  },

  color: {
    secondary_text: '#8a8a8d',
    background_clear: '#fff',
    background_dark: '#1b232a',
    background_xdark: '#182026',
    divider: '#9b9b9b',

    bright_pink: '#ff69b4',
    error_text: '#ff0000',
    success_text: '#00ff00',

    button_color: '#ef532c',
    button_color_disabled: '#555559',
    button_color_clicked: '#0f6a06',
    button_text: '#fff',

    green: '#158709',
    blue: '#005ea5',
    black: '#3b3f47',
    gray: '#555559',
    gray2: '#f2f0ef',
    gray3: '#f1f1f1',
    gray4: '#dddddd',
    red: '#e60000',
    light_blue: '#77a5c8',
    light_green: '#71bf43',
    light_gray: '#f2f0ef',
    facebook: '#4267b2',
    placeholder: '#aaaaaa',

    white: '#fff',
  },

  animation: {
    duration: 100,
    function: 'ease-in',
  },
};

// Reference any set constants here instead of typing them out twice
styles.color.text = styles.color.gray;

export default styles;
