import { css } from 'styled-components';
import { BREAKPOINT_NAMES } from '../constants/breakpoints';

import styles from './constants';

// Works from small up.  This should be the default use case for breakpoints
export const media = Object.keys(styles.breakpoint).reduce((res, label) => {
  res[BREAKPOINT_NAMES[label]] = (...args) => css`
    @media (min-width: ${styles.breakpoint[label] / 16}em) {
      ${css(...args)};
    }
  `;

  return res;
}, {});

// Inverse of normal media queries.  Will style up to the size you specify.
// Example - mediaUpTo.medium - This will not style the medium breakpoint, only below it.
export const mediaUpTo = Object.keys(styles.breakpoint).reduce((res, label) => {
  res[BREAKPOINT_NAMES[label]] = (...args) => css`
    @media (max-width: ${styles.breakpoint[label] / 16}em) {
      ${css(...args)};
    }
  `;

  return res;
}, {});

// Creates a full size pseudoelement, usefull for backgrops or overlays.
export const fullPseudo = ['before', 'after'].reduce((res, type) => {
  res[type] = (...args) => css`
    :${type} {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      content: '';
      ${css(...args)}
    }
  `;

  return res;
}, {});

// Moves the element out of site but preserves readability by screen readers
export const offscreen = () => css`
  position: absolute;
  left: -300vw;
`;

// Simplifies the size param in styles
export const size = (multiple = 1) => `${multiple * styles.size.unit}px`;

// Brings in a font size and associated breakpoints from config
export const getFontSizes = (tag) => {
  const { defaults, breakpoint } = styles.typography.size;

  let res = '';

  // If there's a default param for this tag, dump it right away
  if (defaults[tag]) {
    res += `font-size: ${defaults[tag]}em;`;
  }

  // Run through the breakpoints, pump out a media query for each breakpoint wiht that tag
  res += Object.keys(breakpoint).reduce((breakpointRes, thisSize) => {
    if (breakpoint[thisSize][tag]) {
      return (
        breakpointRes +
        media[BREAKPOINT_NAMES[thisSize]]`font-size: ${
          breakpoint[thisSize][tag]
        }em;`.join('')
      );
    }

    return breakpointRes;
  }, '');

  return res;
};

export const nativeFocus = () => `
  outline-width: 1px;
  outline-style: solid;
  outline-color: Highlight;
  outline-offset: ${size()};
  @media (-webkit-min-device-pixel-ratio:0) {
    outline-width: 5px;
    outline-style: auto;
    outline-color: -webkit-focus-ring-color;
  }
  `;
