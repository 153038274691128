/**
 *
 * Group
 *
 */

import PropTypes from 'prop-types';

import React from 'react';
import styled from 'styled-components';
import styles from '../../styles/constants';
import { size, media } from '../../styles/mixins';

const BoxedGroup = styled.li`
  padding-bottom: 0;

  &:empty {
    display: none;
  }

  & > div:not(:first-child) {
    margin-top: ${size(3)};
  }
`;

const QuestionBox = styled.div`
  display: flex;
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 rgba(48, 51, 64, 0.08);
  background-color: ${styles.color.white};
  border: solid 1px #f2f0ef;
  padding: 40px 25px;
  flex-direction: column;
  margin-bottom: 20px;

  &:empty {
    display: none;
  }
  
  ${media.medium`
    padding: 40px 70px;
    margin-left: 0px;
    margin-right: 0px;
  `} 
  
  > div {
    margin-bottom: 20px;
  }

  > p {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;

// This is a single bordered input box grouping, which can contain text, radio or check boxes,
// and which can also have some form meta info above, and (error) feedback below the text,
// and where the check option can have an optional text box that appears when option is checked
class Group extends React.Component {
  render() {
    // const {
    //   questions,
    //   answers,
    //   onChange,
    //   onClickLink,
    //   onClickToolTip,
    //   onBlur,
    //   onFocus,
    // } = this.props;

    // Add all questions from this group into an array
    // const renderedQuestions = questions.map((question) => {
    //   // TODO
    //   // Once the config json is finalized, we should split out
    //   // the question props explicitly instead of all at once
    //   debugger;
    //   const props = question.toJS();
    //   const name = question.get('name');

    //   const condition = question.get('condition');
    //   if (condition) {
    //     const referenceValue = answers.getIn([
    //       condition.get('reference'),
    //       'value',
    //     ]);
    //     if (
    //       !condition
    //         .get('values')
    //         .map((val) => String(val))
    //         .includes(referenceValue)
    //     ) {
    //       return false;
    //     }
    //   }

    //   const thisVal = answers.getIn([name, 'value']);
    //   const isClean = answers.getIn([name, 'isClean']);
    //   const isValid = answers.getIn([name, 'isValid']);
    //   const error = answers.getIn([name, 'errorMessage']);

    //   return (
    //     <Question
    //       {...props}
    //       key={name}
    //       onChange={onChange}
    //       onClickLink={onClickLink}
    //       onClickToolTip={onClickToolTip}
    //       onBlur={onBlur}
    //       onFocus={onFocus}
    //       answers={answers}
    //       value={thisVal}
    //       isClean={isClean}
    //       isValid={isValid}
    //       error={error}
    //     />
    //   );
    // });

    return (
      <BoxedGroup>
        <QuestionBox>{this.props.children}</QuestionBox>
      </BoxedGroup>
    );
  }
}

Group.propTypes = {
  answers: PropTypes.object,
  onChange: PropTypes.func,
  onClickLink: PropTypes.func,
  onClickToolTip: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

export default Group;
