import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/LppProjectActions';
import { injectIntl, FormattedMessage } from 'react-intl';
import { BrowserRouter as Router, useHistory, Link } from 'react-router-dom';
import axios from 'axios';
import { callPaths } from '../../../configuration';

import styled from 'styled-components';
import LoanAppHeader from '../../../components/LoanHeader';
import MainWrapper from '../../../components/MainWrapper';
import Center from '../../../components/MainWrapper/Center';
import Group from '../../../components/Group';
import QuestionGroups from '../../../components/QuestionGroups';
import Label from '../../../components/QuestionWrapper/Label';
import ColorBox from '../../../components/ColorBox';
import ColorBoxGroup from '../../../components/ColorBoxGroup';
import Paragraph from '../../../components/P';
import StyledButton from '../../../components/Button/StyledButton';
import H3 from '../../../components/H3';
import TooltipSVG from '../../../components/QuestionWrapper/tooltip.svg';
import { getToken } from '../../../helpers/callHelperFunctions';

const LeftAlign = styled.div`
    text-align: left;
`;

const ToolTip = styled.img`
    float: right;
`;

const QuestionWrap = styled.div`
    margin-top: 8px;
`;

const NextPrevButtons = styled.div`
    display: flex;
`;

const PhoneNumber = styled.a`
    white-space: nowrap;
`;

const CompleteProcessPage = (props) => {
    const history = useHistory();
    const email = props.appState.esignData
        ? props.appState.esignData.email
        : '';
    const [completeOnline, setCompleteOnline] = useState(false);
    const [completePhone, setCompletePhone] = useState(false);
    const [progressVal, setProgressVal] = useState(80);
    const [showButtons, setShowButtons] = useState(true);

    const onlineButtonHandler = () => {
        setCompleteOnline(true);
        setCompletePhone(false);
        isCompletedOnlineHandler('online');
        setProgressVal(90);
        setShowButtons(false);
        props.setCompleteType('online');
    };
    const phoneButtonHandler = () => {
        props.setCompleteType('phone');
        isCompletedOnlineHandler('phone');
        history.push('/thankYouScreen');
    };
    const uploadDocHandler = (e) => {
        history.push('/uploadDocuments');
    };
    const isCompletedOnlineHandler = (type) => {
        axios
            .post(callPaths.completeOnline, {
                id: props.appState.appIdAndToken.id,
                isCompletedOnline: type === 'online',
                isCompletedByPhone: type === 'phone',
            })
            .then((response) => {
                props.setFileFormats(response.data.allowedTypes.toString());
                props.setFileSize(response.data.maxFileSize.toString());
                props.setSpecialCharacters(
                    response.data.specialCharactersRegex.toString()
                );
            })
            .catch(function(error) {
                console.log(error);
                //   props.toggleSpinner();
            });
    };
    useEffect(() => {
        if (!props.appState.esignData && !props.appState.appIdAndToken) {
            history.push('/');
        }
        if (props.appState.completeType === 'online') {
            setCompleteOnline(true);
            setCompletePhone(false);
            setProgressVal(90);
            setShowButtons(false);
            props.setCompleteType('online');
        } else if (props.appState.completeType === 'phone') {
            // phoneButtonHandler();
            history.push('/thankYouScreen');
        }
    }, []);
    return (
        <>
            <LoanAppHeader progress={progressVal} />
            <MainWrapper>
                <Center>
                    <ColorBoxGroup>
                        <ColorBox green>
                            <LeftAlign>
                                <FormattedMessage
                                    id={
                                        completePhone
                                            ? 'lpp_thank_you_header'
                                            : 'lpp_confirmation_header'
                                    }
                                />
                            </LeftAlign>
                        </ColorBox>
                        <ColorBox big blue>
                            {completeOnline ? (
                                <FormattedMessage id="lpp_confirmation_subheader" />
                            ) : (
                                <FormattedMessage
                                    id={
                                        completePhone
                                            ? 'lpp_thank_you_subheader'
                                            : 'lpp_confirmation_subheader'
                                    }
                                />
                            )}
                        </ColorBox>
                    </ColorBoxGroup>
                    <QuestionGroups>
                        <Group>
                            {showButtons ? (
                                <H3>
                                    <FormattedMessage id="lpp_confirmation_complete_process" />
                                </H3>
                            ) : null}
                            <br />
                            {showButtons ? (
                                <>
                                    <StyledButton onClick={onlineButtonHandler}>
                                        <FormattedMessage id="lpp_confirmation_complete_online" />
                                    </StyledButton>
                                    <br />
                                </>
                            ) : null}
                            {completeOnline ? (
                                <>
                                    <Paragraph>
                                        <FormattedMessage id="one_with_dot" />
                                        &nbsp;
                                        <a
                                            onClick={(e) => {
                                                e.preventDefault();
                                                history.push(
                                                    '/uploadDocuments'
                                                );
                                            }}
                                            href=""
                                        >
                                            <FormattedMessage id="lpp_confirmation_upload_link" />
                                        </a>
                                        &nbsp;
                                        <FormattedMessage id="lpp_confirmation_caption1_2" />
                                        <br />
                                        &nbsp; &bull; &nbsp;
                                        <FormattedMessage id="lpp_thank_you_document1_1" />
                                        <FormattedMessage id="lpp_thank_you_document1_3_1" />
                                        <br />
                                        &nbsp; &bull; &nbsp;
                                        <FormattedMessage id="lpp_thank_you_document2_0" />
                                        &nbsp;
                                        <FormattedMessage id="lpp_thank_you_document2_1" />
                                    </Paragraph>
                                    <Paragraph>
                                        <FormattedMessage id="two_with_dot" />
                                        &nbsp;
                                        <Link
                                            target={'_blank'}
                                            to={
                                                '/lpp/esign?token=' +
                                                props.appState.appIdAndToken
                                                    .token
                                            }
                                        >
                                            <FormattedMessage id="lpp_confirmation_caption1_4" />
                                        </Link>
                                        &nbsp;
                                        <FormattedMessage id="lpp_confirmation_caption1_5" />
                                    </Paragraph>
                                </>
                            ) : null}
                            {showButtons ? (
                                <>
                                    <StyledButton
                                        onClick={phoneButtonHandler}
                                        outlined={true}
                                        secondary={true}
                                    >
                                        <FormattedMessage id="lpp_confirmation_complete_phone" />
                                    </StyledButton>
                                    <br />
                                </>
                            ) : null}
                        </Group>
                    </QuestionGroups>
                </Center>
            </MainWrapper>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        appState: state.crossSellApp,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setFileFormats: (data) => dispatch(actionCreators.setFileFormats(data)),
        setSpecialCharacters: (data) =>
            dispatch(actionCreators.setSpecialCharacters(data)),
        setFileSize: (data) => dispatch(actionCreators.setFileSize(data)),
        toggleSpinner: () => dispatch(actionCreators.toggleSpinner()),
        setCompleteType: (val) => dispatch(actionCreators.setCompleteType(val)),
        showTooltip: (title, text) =>
            dispatch(
                actionCreators.showTooltip({
                    title: title,
                    text: text,
                })
            ),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(CompleteProcessPage));
