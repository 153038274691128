import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { BrowserRouter as Router, useHistory } from "react-router-dom";

import styled from "styled-components";
import LoanAppHeader from "../../../components/LoanHeader";
import MainWrapper from "../../../components/MainWrapper";
import Center from "../../../components/MainWrapper/Center";
import Group from "../../../components/Group";
import QuestionGroups from "../../../components/QuestionGroups";
import ColorBox from "../../../components/ColorBox";
import ColorBoxGroup from "../../../components/ColorBoxGroup";
import Paragraph from "../../../components/P";

const LeftAlign = styled.div`
  text-align: left;
`;

const AlreadyExistsPage = (props) => {
    const history = useHistory();
    //   const appId = props.appState.appIdAndToken
    //     ? props.appState.appIdAndToken.id
    //     : "";
    //   const email = props.appState.esignData ? props.appState.esignData.email : "";
    useEffect(() => {
        if (!props.appState.appIdAndToken) {
            history.push("/");
        }
    }, []);
    return (
        <>
            <LoanAppHeader progress={100} />
            <MainWrapper>
                <Center>
                    <ColorBoxGroup>
                        <ColorBox green>
                            <LeftAlign>
                                <FormattedMessage id="lpp_thank_you_header" />
                            </LeftAlign>
                        </ColorBox>
                        <ColorBox big blue>
                            <FormattedMessage id="lpp_thank_you_subheader" />
                        </ColorBox>
                    </ColorBoxGroup>
                    <QuestionGroups>
                        <Group>
                            <Paragraph>
                                <FormattedMessage id="lpp_exists_caption1" />
                            </Paragraph>
                        </Group>
                    </QuestionGroups>
                </Center>
            </MainWrapper>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        appState: state.crossSellApp,
    };
};

export default connect(
    mapStateToProps,
    null
)(injectIntl(AlreadyExistsPage));
