export const emailValidation = email => {
    const re = /^[-a-z0-9àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ~!$%^&*_=+}{\'?]+(\.[-a-z0-9àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ~!$%^&*_=+}{\'?]+)*@([a-z0-9àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ_][-a-z0-9àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ_]*(\.[-a-z0-9àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i
   return email === '' ? true :  re.test(String(email).toLowerCase());
}

export const postalCodeValidation = code => {
    const re =  /^[A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]{1}\d{1}[A-Za-z]{1}[\s]{1,}\d{1}[A-Za-z]{1}\d{1}$/;
    return code === '' ? true : re.test(String(code).toLowerCase());
}

export const onlyLettersValidation = code => {
    const re = /^[a-zàâäèéêëîïôœùûüÿç][a-z-'` àâäèéêëîïôœùûüÿç]*$/;
    return code === '' ? true : re.test(String(code).toLowerCase());
}

export const phoneNumberValidation = code => {
    const re = /^[0-9]{10}$/;
    return code === '' ? true : re.test(String(code).toLowerCase());
}

export const decimalNumberValidation = code => {
    const re =  /^[0-9]\d{0,9}(\.\d{1,1})?%?$/;
    code = code.trim();
    let result = re.test(String(code).toLowerCase()) ? '': 'err';
    return code === '' ? 'empty' : result;
}

export const checkPhoneNumber = (phone, isRequired) => {
    phone = phone.replace(/\D/g, '')
    // if(!isRequired && phone === '') {
    //     return true;
    // }
    // return phone.length === 10;
    return phone === '' ? true : phone.length === 10;
}

