// Import bootstrap style
import { injectGlobal } from 'styled-components';
import styles from './constants';

/*
eslint-disable 
*/

injectGlobal`

  @font-face {
      font-family: 'din-next-lt-pro';
      src: url( ${require('../fonts/din-next-lt-pro/linotype-dinnextltpro-regular.woff2')} ), url( ${require('../fonts/din-next-lt-pro/linotype-dinnextltpro-regular.woff')} ), url( ${require('../fonts/din-next-lt-pro/linotype-dinnextltpro-regular.eot')} );
      font-weight: 400;
  }

  @font-face {
      font-family: 'din-next-lt-pro';
      src: url( ${require('../fonts/din-next-lt-pro/linotype-dinnextltpro-medium.woff2')} ), url( ${require('../fonts/din-next-lt-pro/linotype-dinnextltpro-medium.woff')} ), url( ${require('../fonts/din-next-lt-pro/linotype-dinnextltpro-medium.eot')} );
      font-weight: 500;
  }

  @font-face {
      font-family: 'din-next-lt-pro';
      src: url( ${require('../fonts/din-next-lt-pro/linotype-dinnextltpro-bold.woff2')} ), url( ${require('../fonts/din-next-lt-pro/linotype-dinnextltpro-bold.woff')} ), url( ${require('../fonts/din-next-lt-pro/linotype-dinnextltpro-bold.eot')} );
      font-weight: 700;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0;
  }

  body {
    font-family: ${styles.typography.font.body};
    font-weight: 400;
    line-height: ${styles.typography.line_height.body};
    color: ${styles.color.gray};
  }

  body.fontLoaded {
    font-family: ${styles.typography.font.body};
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${styles.typography.font.header};
    font-weight: 400;
    line-height: ${styles.typography.line_height.header};
  }

  #app {
    background-color: #fafafa;
    min-height: 100%;
    min-width: 100%;
  }

`;
