/**
 *
 * InputText
 *
 */

import PropTypes from 'prop-types';
import { SpaceRemoverHelper } from '../../vendor/helpers';

import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import InputWrapper from './InputWrapper';
import QUESTION_TYPES from '../../constants/questions/types';

class InputText extends React.PureComponent {

  getConvertedValue(rawValue) {
    let newValue = rawValue;
    const {
      name
    } = this.props;
    // First convert to number

    if (newValue.length === 0) {
      return '';
    }

    switch(name){
      case "firstName":
      case "lastName":
        try{
          newValue = SpaceRemoverHelper(rawValue);
        }catch (e) {}
        break;
      default:
       return newValue;
    }
  return newValue;
  }

  getFormattedNumber(currency = ""){
    const { locale } = this.props;

    let formatedNumber = '$' + currency.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    if (locale.toLowerCase() === 'fr') {
      formatedNumber = currency.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") +  ' $';
    }
    return formatedNumber;
  }

  handleOnBlur = (event) => {
    const { onChange, onBlur, name, type, value } = this.props;
    switch(name){
      case "firstName":
      case "lastName":
        const newValue = value.trim();
        const modifiedEvent = {
          target: {
            name,
            type,
            value: newValue
          }
        };
        onChange(modifiedEvent);
        break;
    }
    onBlur(event);
  }

  render() {
    const {
      intl,
      isDisabled,
      isValid,
      isClean,
      placeholder,
      label,
      name,
      value,
      minAmount,
      maxAmount,
      inputRef,
      ...rest
    } = this.props;

    let placeholderText;
    if (placeholder) {
      if (this.props.type === QUESTION_TYPES.CURRENCY){
        placeholderText = intl.formatMessage({ id: placeholder }, {minLoanAmount: this.getFormattedNumber(minAmount), maxLoanAmount: this.getFormattedNumber(maxAmount) });
      } else {
        placeholderText = intl.formatMessage({ id: placeholder });
      }
    }

    const id = `question-${name}`;

    return (
      <InputWrapper isDisabled={isDisabled} isValid={isValid} isClean={isClean}>
        <input
          aria-label={intl.formatMessage({ id: label })}
          disabled={isDisabled}
          placeholder={placeholderText}
          id={id}
          title={id}
          value={this.getConvertedValue(value)}
          name={name}
          ref={inputRef}
          {...rest}
          onBlur={this.handleOnBlur}
        />
      </InputWrapper>
    );
  }
}

InputText.defaultProps = {
  type: 'text',
};

InputText.propTypes = {
  /** Required for formatting, is supplied automatically */
  intl: intlShape.isRequired,

  isDisabled: PropTypes.bool,

  /** If false, line under field is red */
  isValid: PropTypes.bool,

  /** If true, ignore errors */
  isClean: PropTypes.bool,

  /** The title for the question */
  label: PropTypes.string.isRequired,

  /** Corresponds to the name in the config */
  name: PropTypes.string.isRequired,

  /** Passthrough function */
  onChange: PropTypes.func.isRequired,

  /** Passthrough function */
  onBlur: PropTypes.func.isRequired,

  /** Passthrough function */
  onFocus: PropTypes.func.isRequired,

  /** Text to show when no content is entered */
  placeholder: PropTypes.string,

  /** Type, defaults to text */
  type: PropTypes.string,

  /** Value of the field */
  value: PropTypes.any,

  minAmount: PropTypes.number,

  maxAmount: PropTypes.number,
};

export default injectIntl(InputText);
