import styled from 'styled-components';

import { getFontSizes } from '../../styles/mixins';
import styles from '../../styles/constants';

const H3 = styled.h3`
  ${getFontSizes('h3')};
  font-weight: ${styles.typography.weight.h3};
  color: ${styles.color.text};
  margin-bottom: 0;
  margin-top: 0;
  line-height: 1.5;
`;

export default H3;