import styled from 'styled-components';
import styles from '../../styles/constants';
import { size, getFontSizes } from '../../styles/mixins';

const { blue, green, red, gray } = styles.color;

function getBorderBottomColor(props) {
  if (props.isClean) {
    return blue;
  }

  if (props.isValid) {
    return gray;
  }

  return red;
}

export default styled.div`
  position: relative;

  input {
    ${getFontSizes('text_input')} border: none;
    border-bottom: 2px solid ${(props) => getBorderBottomColor(props)};
    border-radius: 0;
    color: ${styles.color.text};
    width: 100%;
    padding: ${size()} 0 ${size(2)};
    margin-bottom: ${size(2)};
    font-family: ${styles.typography.font.body};

    background-color: ${(props) =>
      props.isValid || props.isClean
        ? styles.color.white
        : 'rgba(230, 0, 0, 0.06)'};

    &[disabled] {
      background-color: white;
      border-bottom: none;
    }

    &:focus {
      border-bottom-color: ${(props) =>
        props.isValid || props.isClean ? green : red};
      outline-offset: 2px;
      background-color: ${(props) =>
        props.isValid || props.isClean
          ? 'rgba(81, 184, 72, 0.06)'
          : 'rgba(230, 0, 0, 0.06)'};
    }

    ::placeholder {
      color: ${styles.color.text};
      font-weight: 500;
    }
  }
`;
