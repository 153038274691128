import React, { Children } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { getFontSizes, size } from '../../styles/mixins';
import styles from '../../styles/constants';

const Paragraph = styled.p`
  ${getFontSizes('p')} color: ${styles.color.text};
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'none')};
  strong {
    font-weight: bold;
    color: ${styles.color.green};
  }
  :last-of-type {
    margin-bottom: ${size(2)};
  }
`;

class P extends React.PureComponent {
  render() {
    const { uppercase, children } = this.props;

    return (
      <Paragraph uppercase={uppercase}>{Children.toArray(children)}</Paragraph>
    );
  }
}

P.propTypes = {
  uppercase: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default P;
