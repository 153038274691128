import styled from 'styled-components';
import { size, media } from '../../styles/mixins';
import styles from '../../styles/constants';
import SIZE from '../../constants/breakpoints';

export default styled.div`
  height: auto;
  padding: 0 ${size(3)};
  width: 100%;

  ${media.medium`
    width: 50%;
    padding-top: ${size(5)};
  `} ${media.large`
    width: calc(${styles.breakpoint[SIZE.LARGE]}px / 2);
    height: 100%;
  `};
`;
