export const CONFIG = {
  /** What's the base url for all api calls */
  API_URL: 'API_URL',

  /** Should the app log errors to the console */
  LOG_ERRORS: 'LOG_ERRORS',

  /** Facebook App Key */
  FACEBOOK_APP_ID: 'FACEBOOK_APP_ID',

  /** Google Api client id */
  GOOGLE_CLIENT_ID: 'GOOGLE_CLIENT_ID',

  /** How many ms until the api automatically retries an access token refresh attempt on failure */
  REFRESH_TOKEN_RETRY_INTERVAL: 'REFRESH_TOKEN_RETRY_INTERVAL',

  /** How many ms before an access token expires should we get a new access token */
  AUTO_RENEW_REFRESH_TOKEN_TIMELEFT: 'AUTO_RENEW_REFRESH_TOKEN_TIMELEFT',

  /** How many ms before an access token expires should we mark the current access token as stale */
  ACCESS_TOKEN_STALE_THRESHOLD: 'ACCESS_TOKEN_STALE_THRESHOLD',

  /** How long since the last action to pop the inactivity timeout */
  INACTIVITY_TIMEOUT_LENGTH: 'INACTIVITY_TIMEOUT_LENGTH',

  /** How long since the inactivity modal popped before defaulting to logging the user out */
  TIMEOUT_DELAY: 'TIMEOUT_DELAY',

  /** Questions to send Google Analytics event for */
  GA_QUESTIONS: 'GA_QUESTIONS',

  /** Reasons for which these Google Analytics events can be send */
  GA_QUESTIONS_REASONS: 'GA_QUESTIONS_REASONS',

  /** How long between when a full load is requested and when the load is displayed */
  LOADER_DELAY: 'LOADER_DELAY',

  /** If we should allow developer shortcut actions */
  ALLOW_DEV_SHORTCUTS: 'ALLOW_DEV_SHORTCUTS',

  /** Stores the api key used to communicate with Canada Post */
  CANADA_POST_API_KEY: 'CANADA_POST_API_KEY',

  /** Stores Google Analytics ID */
  GA_ID: 'GA_ID',

  /** Determines if we should be using iovation or not */
  USE_IOVATION: 'USE_IOVATION',

  /** Determines GTM ID */
  GTM_ACCOUNT_ID: 'GTM_ACCOUNT_ID',

  /** Determines GTM AUTH */
  GTM_AUTH: 'GTM_AUTH',

  /** Determines GTM AUTH */
  GTM_PREVIEW: 'GTM_PREVIEW',

  /** Determines HOST_FR */
  HOST_FR: 'HOST_FR',

  /** Determines HOST_EN */
  HOST_EN: 'HOST_EN',

  /** Determines DEFAULT_FLOW_FR */
  DEFAULT_FLOW_FR: 'DEFAULT_FLOW_FR',

  /** Determines DEFAULT_FLOW_EN */
  DEFAULT_FLOW_EN: 'DEFAULT_FLOW_EN',

  /** Determines EFS_WEBSITE_EN */
  EFS_WEBSITE_EN: 'EFS_WEBSITE_EN',

  /** Determines EFS_WEBSITE_FR */
  EFS_WEBSITE_FR: 'EFS_WEBSITE_FR',

  /** Determines EH_WEBSITE_URL */
  EH_WEBSITE_URL: 'EH_WEBSITE_URL',

  /** Determines FACEBOOK_APP_ID_FR */
  FACEBOOK_APP_ID_FR: 'FACEBOOK_APP_ID_FR',

  /** Determines GOOGLE_CLIENT_ID_FR */
  GOOGLE_CLIENT_ID_FR: 'GOOGLE_CLIENT_ID_FR',

  /** Determines GTM ID */
  GTM_ACCOUNT_ID_MAIN: 'GTM_ACCOUNT_ID_MAIN',

  /** Determines GTM AUTH */
  GTM_AUTH_MAIN: 'GTM_AUTH_MAIN',
  
  /** Determines GTM AUTH */
  GTM_PREVIEW_MAIN: 'GTM_PREVIEW_MAIN',

  /** Determines GTM ENV_NAME */
  ENV_NAME: 'ENV_NAME',

  /** Banking iFrame URL */
  BANKING_IFRAME_URL: 'BANKING_IFRAME_URL',

  /**Banking API URL */
  BANKING_API_URL: 'BANKING_API_URL',

  /**flinks Redirect URL */
  FLINKS_REDIRECT_URL: 'FLINKS_REDIRECT_URL',

  /**Flinks terms URL EN */
  FLINKS_TERMS_URL_EN: 'FLINKS_TERMS_URL_EN',

  /**Flinks terms URL FR */
  FLINKS_TERMS_URL_FR: 'FLINKS_TERMS_URL_FR'

};

export function getConfig(key) {
  // const { config } = goeasy; // eslint-disable-line no-undef
  // if (config[key] !== undefined) {
  //   return config[key];
  // }

  // if (config[CONFIG.LOG_ERRORS]) {
  //   console.warn('UNDEFINED CONFIG KEY - ', key); // eslint-disable-line no-console
  // }

  return null;
}
