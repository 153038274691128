import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/LppProjectActions";
import { injectIntl, FormattedMessage } from "react-intl";
import { BrowserRouter as Router, useHistory } from "react-router-dom";

import styled from "styled-components";
import LoanAppHeader from "../../../components/LoanHeader";
import MainWrapper from "../../../components/MainWrapper";
import Center from "../../../components/MainWrapper/Center";
import Group from "../../../components/Group";
import QuestionGroups from "../../../components/QuestionGroups";
import Label from "../../../components/QuestionWrapper/Label";
import ColorBox from "../../../components/ColorBox";
import ColorBoxGroup from "../../../components/ColorBoxGroup";
import Paragraph from "../../../components/P";

const LeftAlign = styled.div`
  text-align: left;
`;

const ToolTip = styled.img`
  float: right;
`;

const QuestionWrap = styled.div`
  margin-top: 8px;
`;

const NextPrevButtons = styled.div`
  display: flex;
`;

const PhoneNumber = styled.a`
  white-space: nowrap;
`;

const ThankYouPage = (props) => {
  const history = useHistory();
  const appId = props.appState.appIdAndToken
    ? props.appState.appIdAndToken.id
    : "";
  const email = props.appState.esignData ? props.appState.esignData.email : "";
  useEffect(() => {
    if (!props.appState.esignData && !props.appState.appIdAndToken) {
      history.push("/");
    }
  }, []);
  return (
    <>
      <LoanAppHeader progress={100} />
      <MainWrapper>
        <Center>
          <ColorBoxGroup>
            <ColorBox green>
              <LeftAlign>
                <FormattedMessage id="lpp_confirmation_header" />
              </LeftAlign>
            </ColorBox>
            <ColorBox big blue>
              <FormattedMessage id="lpp_confirmation_subheader_signed" />
            </ColorBox>
          </ColorBoxGroup>
          <QuestionGroups>
            <Group>
              {!props.appState.esignData ? (
                <>
                  <Paragraph>
                    <FormattedMessage id="lpp_confirmation_caption1" />
                    &nbsp;
                    <span>{appId}.</span>
                  </Paragraph>
                  <Paragraph>
                    <FormattedMessage id="lpp_confirmation_phone_1" />
                  </Paragraph>
                  <div>
                    <Paragraph>
                      <FormattedMessage id="lpp_confirmation_phone_2" />
                    </Paragraph>
                    {/* &nbsp; &bull; &nbsp; */}
                    <ul>
                      <li>
                        <Paragraph>
                          <FormattedMessage id="lpp_thank_you_document1_1" />
                          <FormattedMessage id="lpp_thank_you_document1_3" />
                        </Paragraph>
                      </li>
                      <li>
                        <Paragraph>
                          {/* &nbsp; &bull; &nbsp; */}
                          <FormattedMessage id="lpp_thank_you_document2" />
                          <FormattedMessage id="lpp_thank_you_document2_0_1" />
                    &nbsp;
                    <FormattedMessage id="lpp_thank_you_document2_1" />
                        </Paragraph>
                      </li>
                    </ul>
                  </div>
                  <Paragraph>
                    <FormattedMessage id="lpp_confirmation_phone_2_1" />
                    <PhoneNumber href="tel:1-855-996-3279">
                      <FormattedMessage id="lpp_confirmation_link2" />
                    </PhoneNumber>
                    <FormattedMessage id="lpp_confirmation_phone_2_2" />
                  </Paragraph>
                </>
              ) : (
                  <Paragraph>
                    <FormattedMessage id="lpp_confirmation_caption5" />
                  &nbsp;
                    <a href={`mailto:${email}`}>{email}</a>
                    <FormattedMessage id="lpp_confirmation_caption6" />
                  </Paragraph>
                )}
            </Group>
          </QuestionGroups>
        </Center>
      </MainWrapper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    appState: state.crossSellApp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSpinner: () => dispatch(actionCreators.toggleSpinner()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ThankYouPage));
