/*
 *
 * LanguageProvider constants
 *
 */

/*
  The hard coded values here should be moved to config files when Config files are implemented
 */
import LocalStorageClass from '../../utils/util';
const HOSTNAME =  LocalStorageClass.getHostName();
const HOST_FR = "application-qc.goeasydev.com";

export const LANGUAGE_EN = 'en';
export const LANGUAGE_FR = 'fr';
export const CHANGE_LOCALE = 'app/LanguageToggle/CHANGE_LOCALE';
export const DEFAULT_LOCALE =
  HOSTNAME === HOST_FR  
    ? LANGUAGE_FR
    : LANGUAGE_EN;
export const LOCALE_VISIBLE = false;
export const PICKER_OFF = false;
export const LANGUAGE_FULL_NAMES = { en: 'english', fr: 'french' };
