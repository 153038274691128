/**
 *
 * InputRadio
 *
 */

import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

class InputRadio extends React.PureComponent {
  render() {
    const {
      id,
      isChecked,
      label,
      name,
      onChange,
      intl,
      value,
      onBlur,
      onFocus,
      skipTranslation,
      order,
      ref,
      defaultChecked
    } = this.props;

    const ariaLabel = skipTranslation
      ? label
      : intl.formatMessage({ id: label });

    return (
      <div>
        <input
          type="radio"
          aria-label={ariaLabel}
          checked={isChecked}
          id={id}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          data-order={order}
          ref={ref}
          defaultChecked={defaultChecked}
        />
        <label htmlFor={id}>
          {skipTranslation ? label : <FormattedMessage id={label} />}
        </label>
      </div>
    );
  }
}

InputRadio.propTypes = {
  isChecked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  intl: intlShape.isRequired,
  skipTranslation: PropTypes.bool,
  // defaultChecked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default injectIntl(InputRadio);
