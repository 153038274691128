export const getToken = (url) => {
   let searchObject = {},
    queries, split, i;
  queries = url.replace(/^\?/, '').split('&');
  for (i = 0; i < queries.length; i++) {
    split = queries[i].split('=');
    searchObject[split[0]] = split[1];
  }
  return Object.values(searchObject)[0];
}
