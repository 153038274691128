import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import { createStore, combineReducers,compose, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { reducer as formReducer } from "redux-form";
import LppReducer from './store/LppReducer';
import languageProviderReducer from './containers/LanguageProvider/reducer';
import thunk from 'redux-thunk';
import styled from 'styled-components';
import styles from './styles/constants';

import { translationMessages } from './i18n';
import LanguageProvider from './containers/LanguageProvider';
import loadAllVendors from './vendor';

const AppWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: ${styles.color.background_clear};
  max-width: 100%;
  overflow-x: hidden;
`;

const reducers = { 
  form: formReducer, 
  crossSellApp: LppReducer,
  language: languageProviderReducer
};
const combinedReducer = combineReducers(reducers);
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combinedReducer,
  composeEnhancer(applyMiddleware(thunk)),
);

const MOUNT_NODE = document.getElementById('root');

loadAllVendors();

const render = (messages) => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <AppWrapper>
          <App />
        </AppWrapper>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/de.js'),
      ])
    )
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Service worker disabled as it is causing problems with Canada Post
// registerServiceWorker();