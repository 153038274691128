import styled from 'styled-components';
import styles from '../../styles/constants';
import { size } from '../../styles/mixins';

const ProgressIndicator = styled.div`
  width: 100%;
  height: 13px;
  background: ${styles.color.light_gray};
  position: relative;

  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${(props) => props.progress}%;
    min-width: ${size(2)};
    z-index: 1;
    background: ${styles.color.light_green};
    transition: width 300ms ease-in;
  }
`;

export default ProgressIndicator;
