import styled from 'styled-components';

const PrevWrap = styled.div`
  width: 100%;
  text-align: center;
  margin: 2em 0;
  display: flex;
  padding-right: 20px;
`;

export { PrevWrap };
