export default {
  AMOUNT_SLIDER: 'amount_slider',
  DOB_DROPDOWN: 'dob_dropdown',
  CURRENCY: 'currency',
  DATE: 'date',
  EMAIL: 'email',
  MULTIPLE: 'multiple',
  NUMBER: 'number',
  SIN: 'sin',
  PHONE: 'phone',
  RADIO: 'radio',
  RADIOBOX: 'radiobox',
  CHECKBOX: 'checkbox',
  TEXT: 'text',
  DROPDOWN: 'dropdown',

  // Not supported over API
  PASSWORD: 'password',
};
