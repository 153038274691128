import styled from 'styled-components';
import styles from '../../styles/constants';

const MainWrapper = styled.main.attrs({
  id: 'main',
})`
  width: 100%;
  padding-bottom: 66px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${styles.color.gray3};
`;

export default MainWrapper;
