import { isString, isEmpty } from 'lodash';
import queryString from 'query-string';
import * as Sentry from '@sentry/browser';
import { CONFIG, getConfig } from '../config';

export function parseObjectString(string, obj) {
  function index(innerObj, i) {
    if (!innerObj) {
      return undefined;
    }
    return innerObj[i];
  }
  return string.split('.').reduce(index, obj);
}

export function isMobile() {
  let check = false;

  /* eslint-disable func-names */
  (function (a) {
    /* eslint-enable func-names */
    /* eslint-disable no-useless-escape */
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
      /* eslint-enable no-useless-escape */
    ) {
      check = true;
    }
  }(navigator.userAgent || navigator.vendor || window.opera)); // eslint-disable-line
  return check;
}

export function stringToBoolean(string) {
  if (!isString(string)) {
    return string;
  }

  switch (string.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return Boolean(string);
  }
}

const LOCAL_STORAGE_KEY = 'ges_la';
const DIFF_24HOURS = 86400000;
const LOCALHOST_FR = getConfig(CONFIG.HOST_FR);
const LOCALHOST_EN = getConfig(CONFIG.HOST_EN);
const DEFAULT_FLOW_FR = getConfig(CONFIG.DEFAULT_FLOW_FR);
const DEFAULT_FLOW_EN = getConfig(CONFIG.DEFAULT_FLOW_EN);
const EFS_WEBSITE_EN = getConfig(CONFIG.EFS_WEBSITE_EN);
const EFS_WEBSITE_FR = getConfig(CONFIG.EFS_WEBSITE_FR);
const EH_WEBSITE_URL = getConfig(CONFIG.EH_WEBSITE_URL);
const FACEBOOK_APP_ID = getConfig(CONFIG.FACEBOOK_APP_ID);
const FACEBOOK_APP_ID_FR = getConfig(CONFIG.FACEBOOK_APP_ID_FR);
const GOOGLE_CLIENT_ID = getConfig(CONFIG.GOOGLE_CLIENT_ID);
const GOOGLE_CLIENT_ID_FR = getConfig(CONFIG.GOOGLE_CLIENT_ID_FR);
const EH_SOURCE_VALUE = 'easyhome.ca';
const BUILD_URL_QUERY_EXT = {
  [LOCALHOST_FR]: `${queryString.stringify({ region: 'QC' })}&`,
  [LOCALHOST_EN]: '',
};
const BUILD_URL_QUERY = {
  [LOCALHOST_FR]: queryString.stringify({ flowname: DEFAULT_FLOW_FR }),
  [LOCALHOST_EN]: queryString.stringify({ flowname: DEFAULT_FLOW_EN }),
};
const NOW_TIMESTAMP = new Date().getTime();
let maxLoanAmount = 0;
export default class LocalStorageClass {
  static setQueryString(value) {
    this.queryString = value;
  }

  static getQueryString() {
    return this.queryString || '';
  }

  static setLandingPageUrl(value) {
    this.landingPageUrl = value;
  }
  static getLandingPageUrl() {
    return window.location.origin + this.getQueryString();
  }

  static getHostName() {
    return (
      window.location.hostname ||
      document.location.hostname
    );
  }
  static getLanguageForBanking() {
    const HOSTNAME = this.getHostName();
    if (HOSTNAME === LOCALHOST_FR) {
      return 'fr';
    }
    return 'en';
  }
  static getHostQueryString(original = true) {
    const qs = (
      window.location.search ||
      document.location.search || ''
    ).replace('?', '');

    if (isEmpty(qs)) {
      return '';
    }
    const URL_QUERY_OBJ = queryString.parse(qs);
    delete URL_QUERY_OBJ.region;
    if (original === false) {
      delete URL_QUERY_OBJ.flowname;
    }
    return queryString.stringify(URL_QUERY_OBJ);
  }

  static getEFSsite(lang = null) {
    const host = this.getHostName();
    if (this.ifSourceEasyHome() === true && host !== LOCALHOST_FR) {
      return EH_WEBSITE_URL;
    } else if (lang === 'fr' || LOCALHOST_FR === host) {
      return EFS_WEBSITE_FR;
    }
    return EFS_WEBSITE_EN;
  }

  static setMaxLoanAmount(amount) {
    maxLoanAmount = amount || 35000;
  }

  static getMaxLoanAmount() {
    return maxLoanAmount;
  }
  static getFormattedMaxLoanAmountForLanguage(language) {
    return this.getFormattedNumber(maxLoanAmount,language);
  }
  static getFormattedNumber(currency,language){
    let formatedNumber = '$' + currency.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    if (language === 'fr') {
      formatedNumber = currency.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") +  ' $';
    }
    return formatedNumber;
  }

  static getFacebookAppId() {
    const HOSTNAME = this.getHostName();
    if (HOSTNAME === LOCALHOST_FR) {
      return FACEBOOK_APP_ID_FR;
    }
    return FACEBOOK_APP_ID;
  }

  static getGoogleClientId() {
    const HOSTNAME = this.getHostName();
    if (HOSTNAME === LOCALHOST_FR) {
      return GOOGLE_CLIENT_ID_FR;
    }
    return GOOGLE_CLIENT_ID;
  }

  static ifSourceEasyHome() {
    try {
      const readLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEY);
      let returnValue = false;
      if (!isEmpty(readLocalStorage)) {
        const readLocalStorageObj = JSON.parse(readLocalStorage);
        if (!isEmpty(readLocalStorageObj.queryString)) {
          const localValues = queryString.parse(
            readLocalStorageObj.queryString
          );
          if (
            typeof localValues.utm_source !== 'undefined' &&
            localValues.utm_source === EH_SOURCE_VALUE
          ) {
            returnValue = true;
          }
        }
      }
      return returnValue;
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  static checkFlow(payload) {
    const { landingPageUrl } = payload;
    const newPayload = payload;
    try {
      this.setLandingPageUrl(landingPageUrl);
      this.checkLocalStorage();
      newPayload.landingPageUrl = this.getLandingPageUrl();
      return newPayload;
    } catch (err) {
      return payload;
    }
  }

  static checkLocalStorage() {
    try {
      const localStorageStr = localStorage.getItem(LOCAL_STORAGE_KEY);
      const localStorageObj = (isEmpty(localStorageStr)) ? {} : JSON.parse(localStorageStr);
      const localStorageQueryStringObj = (isEmpty(localStorageObj)) ? [] : queryString.parse(localStorageObj.queryString);

      if (isEmpty(localStorageObj.queryString) || (NOW_TIMESTAMP - localStorageObj.timestamp > DIFF_24HOURS)) {
        this.initLocalStorage();
      } else {
        const URL_QUERY = this.getHostQueryString(true);
        delete localStorageQueryStringObj.region;
        let utlQueryStr = queryString.stringify(localStorageQueryStringObj);
        if (!isEmpty(URL_QUERY)) {
          utlQueryStr = (isEmpty(localStorageQueryStringObj.flowname)) ?
            URL_QUERY :
            `${queryString.stringify({ flowname: localStorageQueryStringObj.flowname })}&${this.getHostQueryString(false)}`;
        }
        this.initLocalStorage(utlQueryStr);
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  static initLocalStorage(initData = null) {
    try {
      const URL_QUERY = (initData === null) ? this.getHostQueryString(true) : initData;
      const HOSTNAME = this.getHostName();

      let newURLQuery = `${BUILD_URL_QUERY_EXT[HOSTNAME]}${BUILD_URL_QUERY[HOSTNAME]}`;
      if (!isEmpty(URL_QUERY)) {
        const isFlowNameExist = (URL_QUERY.indexOf('flowname=') !== -1) || (URL_QUERY.indexOf('&flowname=') !== -1);
        newURLQuery = (isFlowNameExist) ? `${BUILD_URL_QUERY_EXT[HOSTNAME]}${URL_QUERY}` : `${newURLQuery}&${URL_QUERY}`;
      }

      this.setQueryString(`?${newURLQuery}`);
      const localStorageObj = {
        timestamp: NOW_TIMESTAMP,
        queryString: `?${newURLQuery}`,
      };
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(localStorageObj));
    } catch (err) {
      Sentry.captureException(err);
    }
  }
}
