import styled from 'styled-components';
import styles from '../../styles/constants';
import { getFontSizes, media } from '../../styles/mixins';

export default styled.span`
  ${getFontSizes('helptext')};
  color: ${styles.color.green};
  display: block;
  ${media.medium`
    width: 500px;
  `}};
`;
