import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ErrorMessage from '../QuestionWrapper/ErrorMessage';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as actionCreators from '../../store/LppProjectActions';
import styles from './Preview.module.css';

const oneMb = 1000000;

const Preview = (props) => {
    const [fileTooLarge, setFileTooLarge] = useState(false);
    const [imgSrc, setImgSrc] = useState('');

    const removeImgHandler = () => {
        props.fileWithMeta.remove();
    };

    useEffect(() => {
        const re = new RegExp(props.appState.specialCharacters);
        if (!re.test(props.fileWithMeta.file.name)) {
            props.showTooltip('tooltip_empty', ['tooltip_special_characters']);
            props.fileWithMeta.remove();
        }
        if (props.meta.size > oneMb * props.appState.fileSize) {
            setFileTooLarge(true);
        }
        // create the preview
        const objectUrl = URL.createObjectURL(props.fileWithMeta.file);
        setImgSrc(objectUrl);

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
    }, [props.fileWithMeta.file]);

    return (
        <div className={styles.document_section}>
            <div className={styles.document_section_content}>
                <div className={styles.document_section_img_wrapper}>
                    {props.meta.type === 'application/pdf' ? (
                        <iframe src={imgSrc} />
                    ) : (
                        <img src={imgSrc} />
                    )}
                </div>
                <h3>{props.meta.name}</h3>
                <div>
                    <button
                        className={styles.document_section_remove_btn}
                        onClick={removeImgHandler}
                    >
                        x
                    </button>
                </div>
            </div>
            {fileTooLarge ? (
                <ErrorMessage>
                    <FormattedMessage id="file_size_error" />
                    <span>{props.appState.fileSize}</span>
                    <FormattedMessage id="file_size_error_mb" />
                </ErrorMessage>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        appState: state.crossSellApp,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSpinner: () => dispatch(actionCreators.toggleSpinner()),
        showTooltip: (title, text) =>
            dispatch(
                actionCreators.showTooltip({
                    title: title,
                    text: text,
                    isUpload: true,
                })
            ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Preview);