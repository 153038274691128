import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import * as actionCreators from "../../../store/LppProjectActions";

import LoanAppHeader from "../../../components/LoanHeader";
import MainWrapper from "../../../components/MainWrapper";
import ColorBox from "../../../components/ColorBox";
import ColorBoxGroup from "../../../components/ColorBoxGroup";
import Button from "../../../components/Button";
import Center from "../../../components/MainWrapper/Center";
import Group from "../../../components/Group";
import QuestionGroups from "../../../components/QuestionGroups";
import Paragraph from "../../../components/P";

const LeftAlign = styled.div`
  text-align: left;
`;

const FinePrint = styled.div`
  font-size: 1rem;
  margin-top: 2em;
`;
const NextPrevButtons = styled.div`
  display: flex;
`;
const PhoneNumber = styled.a`
  white-space: nowrap;
`;

const LoanNumberPage = (props) => {
  const history = useHistory();

  const hextPageHandler = () => {
    history.push("/customerInfo");
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <LoanAppHeader progress={0} />
      <MainWrapper>
        <Center>
          <ColorBoxGroup>
            <ColorBox green>
              <LeftAlign>
                <FormattedMessage id="lpp_splash_header" />
              </LeftAlign>
            </ColorBox>
            <ColorBox big left blue>
              <FormattedMessage id="lpp_splash_subheader" />
            </ColorBox>
            <ColorBox big left black>
              <FormattedMessage id="lpp_splash_caption" />
            </ColorBox>
          </ColorBoxGroup>
          <QuestionGroups>
            <Group>
              <Paragraph>
                <FormattedMessage id="lpp_splash_content1" />
              </Paragraph>
            </Group>
          </QuestionGroups>
          <NextPrevButtons>
            <Button oversized onClick={hextPageHandler} noWrap>
              <FormattedMessage id="lpp_splash_cta" />
            </Button>
          </NextPrevButtons>
          <FinePrint>
            <FormattedMessage id="lpp_splash_footer_text1" />
            &nbsp;
            <PhoneNumber href="tel:1-800-663-9822">
              <FormattedMessage id="lpp_splash_footer_text2" />
            </PhoneNumber>
            &nbsp;
            <br />
            <FormattedMessage id="lpp_splash_footer_text4" />
          </FinePrint>
        </Center>
      </MainWrapper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appState: state.crossSellApp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSpinner: () => dispatch(actionCreators.toggleSpinner()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(LoanNumberPage));
