/**
 *
 * App.react.js
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { size, media, mediaUpTo } from '../../styles/mixins';
import styles from '../../styles/constants';
import LogoutWarning from '../../components/Modal/LogoutWarning';
import { get } from 'immutable';

import {
  changeLocale,
  changeLocaleFlag,
} from '../../containers/LanguageProvider/actions';

import { LANGUAGE_FULL_NAMES } from '../../containers/LanguageProvider/constants';

import IconMoon from '../../utils/Icon';
import LocalStorageClass from '../../utils/util';
import { CONFIG, getConfig } from '../../config';
import TagManager from 'react-gtm-module';

const MENU_PHONE = '1-855-996-3279';

const MenuWrapper = styled.li`
  position: relative;
  display: inline-block;
`;

const HideText = styled.span`
  display: inline-block;
    ${mediaUpTo.small`
      display: none;
    `};
`;

const LanguagePickerWrapper = styled.div`
display: inline-block;
  font-weight: 400;
  color: #555559;
  padding: 4px;
  border: 1px solid #555559;
  font-family: 'din-next-lt-pro','Helvetica Neue',Helvetica,Aria,sans-serif;
    ${mediaUpTo.small`
    padding: 2px;
  `};
`;

const MenuItem = styled.li`
  width: 100%;
  button {
    padding: 4px 4px;
    color: ${styles.color.blue};
    cursor: pointer;
    width: 100%;
    text-align: left;
  }

  ${media.medium`
    button {
      margin: 6px;
      padding: 0px 8px;
      white-space: nowrap;
    }
    .global-icon {
      padding-left: 12px;
    }
    .global-icon-text{
      padding-left: 3px;
    }
    .logout-text{
      padding-left:4px;
    }
  `};
  ${mediaUpTo.medium`
    .global-icon {
      padding-left: 8px;
    }
    .global-icon-text{
      padding-left: 3px;
    }
    .logout-text{
      padding-left:3px;
    }
  `};
`;

const Dropdown = styled.ul`
  display: ${(props) => (props.isOpen ? 'inline-flex' : 'none')};
  flex-direction: column;
  box-shadow: ${styles.shadow.default};
  background: ${styles.color.white};
  min-width: ${size(22)};
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 3;
  list-style-type: none;
  padding: 0;
  margin: 0;

  ${media.medium`
    display: ${(props) => (props.isOpen ? 'inline-flex' : 'none')};
    flex-direction: column;
    box-shadow: ${styles.shadow.default};
    background: ${styles.color.white};
    position: absolute;
    z-index: 3;
  `};
`;
const MenuText = styled.span`
  font-size: 0.69em;
  color: #005ea5;
  ${media.medium`
    display: inline-block;
    color: #005ea5;
    font-size: 0.8em;
    font-weight: bold;
    display: inline-flex;
  `};
`;
const MenuA = styled.a`
  text-decoration: none;
`;
const PopupButton = styled.button`
  cursor: pointer;
  float: right;
`;

const Dot = styled.span`
  display: block;
  height: ${size(0.5)};
  width: ${size(0.5)};
  background-color: ${styles.color.gray};
  border-radius: 50%;
  margin: 3px;
`;
export class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.toggleOpen = this.toggleOpen.bind(this);
    this.toggleClose = this.toggleOpen.bind(this);
    this.state = { logOutWarning: false };
    this.openLogoutWanring = this.openLogoutWanring.bind(this);
    this.closeLogoutWanring = this.closeLogoutWanring.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  toggleOpen() {
    if (!this.state.isOpen) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }

  handleOutsideClick(e) {
    this.toggleClose();
  }

  toggleClose() {
    this.state = {
      isOpen: false,
    };
  }
  closeLogoutWanring() {
    this.setState({
      logOutWarning: false,
    });
  }

  openLogoutWanring() {
    this.setState({
      logOutWarning: true,
    });
  }

  componentWillUnmount() {
    this.closeLogoutWanring();
  }

  changeLocale(newLanguage) {
    this.props.dispatchChangeLocale(newLanguage);
  }

  renderLanguageButton() {
    return null;

    const { currentLanguage } = this.props;
    const newLanguage = currentLanguage === 'en' ? 'fr' : 'en';

    const renderMenu = (
      <button onClick={() => this.changeLocale(newLanguage)} id="languagePickerButton">
        <LanguagePickerWrapper><FormattedMessage id="menu_change_language_short" /></LanguagePickerWrapper>
      </button>
    );

    return renderMenu;
  }

  renderLogoutButton() {
    return (
      <MenuItem>
        <button onClick={this.openLogoutWanring}>
          <IconMoon size={25} icon="ges-logout" />
          <MenuText className="logout-text">
            <FormattedMessage id="menu_logout" />
          </MenuText>
        </button>
      </MenuItem>
    );
  }
  renderChat() {
    return (
      <MenuItem>
        <button>
          <IconMoon size={30} icon="ges-chat" />
          <MenuText>
            <FormattedMessage id="live_chat_label" />
          </MenuText>
        </button>
      </MenuItem>
    );
  }
  renderPhone() {
    return (
      <MenuItem>
        <MenuA href={`tel:${MENU_PHONE}`}>
          <IconMoon size={30} icon="ges-phone" />
          <MenuText>
            {MENU_PHONE}
          </MenuText>
        </MenuA>
      </MenuItem>
    );
  }
  renderEFS() {
    return (
      <MenuItem>
        <button className="global-icon">
          <MenuA target="_blank" href="#">
            <IconMoon size={22} icon="ges-www-icon" />
            <MenuText >
              <span className="global-icon-text">Easy Financial Site</span>
            </MenuText>
          </MenuA>
        </button>
      </MenuItem>
    );
  }

  renderLogoutWarning() {
    const { logOutWarning } = this.state;

    return (
      <LogoutWarning
        isOpen={logOutWarning}
        onRequestClose={this.closeLogoutWanring}
        dismissible
      />
    );
  }

  render() {
    const { isOpen } = this.state;
    return (
      <MenuWrapper>
        {this.renderLanguageButton()}
        <HideText>need help?</HideText>
        <PopupButton
          aria-haspopup="true"
          aria-expanded={isOpen}
          onClick={this.toggleOpen}
          aria-label="menu"
        >
          <Dot />
          <Dot />
          <Dot />
        </PopupButton>
        <Dropdown isOpen={isOpen}>
          {this.renderPhone()}
        </Dropdown>
      </MenuWrapper>
    );
  }
}

Menu.propTypes = {
  dispatchChangeLocale: PropTypes.func,
  dispatchLocaleFlag: PropTypes.func,
  currentLanguage: PropTypes.string,
};

const mapStateToProps = (state) => ({
  currentLanguage: get(state.language, "locale"),
});

const actions = {
  dispatchChangeLocale: changeLocale,
  dispatchLocaleFlag: changeLocaleFlag,
};

export default connect(mapStateToProps, actions)(Menu);
