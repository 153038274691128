/**
 *
 * ToolTip
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { size, getFontSizes } from '../../styles/mixins';
import {
    FormattedMessage,
    injectIntl,
    intlShape,
    FormattedHTMLMessage,
} from 'react-intl';
import TagManager from 'react-gtm-module';
import H2 from '../../components/H2';
import P from '../../components/P';
import Button from '../../components/Button';
import ButtonGroup from '../../components/ButtonGroup';
import * as Sentry from '@sentry/browser';
import styles from '../../styles/constants';

import Modal from './index';

const TextWrapper = styled.div`
    &:empty {
        display: none;
    }
    padding-bottom: ${size(1)};
`;

const Span = styled.span`
  ${getFontSizes('p')} color: ${styles.color.text};
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'none')};
  strong {
    font-weight: bold;
    color: ${styles.color.green};
  }
  :last-of-type {
    margin-bottom: ${size(2)};
  }
`;

const Line = (props) => <FormattedMessage id={props.lineId} />;

const Lines = (props) => {
    const lines = [];

    for (let i = 0; i < props.tooltipTexts.length; i += 1) {
        let lineId = '';
        let basic = false;
        let bold = false;
        let bullet = false;
        let italic = false;
        let link = false;
        let inline = false;

        if (props.tooltipTexts[i] && props.tooltipTexts[i].match(/_inline/i)) {
            inline = true;
        }

        if (props.tooltipTexts[i] && props.tooltipTexts[i].match(/_bold/i)) {
            bold = true;
        } else if (
            props.tooltipTexts[i] &&
            props.tooltipTexts[i].match(/_bullet/i)
        ) {
            bullet = true;
        } else if (
            props.tooltipTexts[i] &&
            props.tooltipTexts[i].match(/_italic/i)
        ) {
            italic = true;
        } else if (
            props.tooltipTexts[i] &&
            props.tooltipTexts[i].match(/_link/i)
        ) {
            link = true;
        } else {
            basic = true;
        }

        lineId = props.tooltipTexts[i];

        // note: we add a key prop here to allow react to uniquely identify each
        // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
        if (basic || bold || bullet || italic || link) {
            let elmt = null;

            if (inline) {
                elmt = (
                    <Span key={i}>
                        {!bold && !bullet && !italic && !link && (
                            <Line lineId={lineId} />
                        )}
                        {bold && (
                            <b>
                                <Line lineId={lineId} />
                            </b>
                        )}
                        {bullet && (
                            <li>
                                <Line lineId={lineId} />
                            </li>
                        )}
                        {italic && (
                            <i>
                                <Line lineId={lineId} />
                            </i>
                        )}
                        {link && <FormattedHTMLMessage id={lineId} />}
                    </Span>
                );
            } else {
                elmt = (
                    <P key={i}>
                        {!bold && !bullet && !italic && !link && (
                            <Line lineId={lineId} />
                        )}
                        {bold && (
                            <b>
                                <Line lineId={lineId} />
                            </b>
                        )}
                        {bullet && (
                            <li>
                                <Line lineId={lineId} />
                            </li>
                        )}
                        {italic && (
                            <i>
                                <Line lineId={lineId} />
                            </i>
                        )}
                        {link && <FormattedHTMLMessage id={lineId} />}
                    </P>
                );
            }

            lines.push(elmt);
        }
    }
    return lines;
};

class ToolTip extends React.PureComponent {
    render() {
        const { props } = this;
        const { onRequestClose } = this.props;

        let title = '';
        if (!isEmpty(props.details.title) && !!props.isOpen) {
            try {
                title = props.intl.formatMessage({ id: props.details.title });
            } catch (err) {
                Sentry.captureException(err);
            }
            const dataLayerToolTip = {
                dataLayer: {
                    event: 'e_help',
                    questionAsked: title,
                    helpAction: 'inline',
                },
                dataLayerName: 'CardinalDataLayer',
            };
            TagManager.dataLayer(dataLayerToolTip);
        }
        return (
            <Modal {...props} contentLabel="tooltip_alt">
                <H2>
                    <FormattedMessage id={props.details.title || ''} />
                </H2>
                <TextWrapper>
                    <Lines tooltipTexts={props.details.text} />
                </TextWrapper>
                <ButtonGroup>
                    <Button onClick={onRequestClose}>
                        {props.details.isUpload ? (
                            <FormattedMessage id="ok_it_cta" />
                        ) : (
                            <FormattedMessage id="got_it_cta" />
                        )}
                    </Button>
                </ButtonGroup>
            </Modal>
        );
    }
}

ToolTip.propTypes = {
    onRequestClose: PropTypes.func,
    intl: intlShape.isRequired,
};

Line.propTypes = {
    lineId: PropTypes.string,
};

export default injectIntl(ToolTip);