/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import queryString from 'query-string';
import { bindActionCreators } from 'redux';
import * as Sentry from '@sentry/browser';
import { changeLocale } from './actions';
import { CONFIG, getConfig } from '../../config';
import { LANGUAGE_EN, LANGUAGE_FR } from './constants';
import { get } from 'immutable';

export class LanguageProvider extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  componentDidMount() {
    try {
      const LOCATION = 
        window.location ||
        document.location;
      const HOSTNAME =
        window.location.hostname ||
        document.location.hostname;
      const LOCALHOST_FR = getConfig(CONFIG.HOST_FR);
      if (HOSTNAME === LOCALHOST_FR) {
      // } else {
        const parsedLang = queryString.parse(LOCATION.search);

        let browserLanguage = LANGUAGE_FR; // window.navigator.language;
        if (this.isIE()) {
          browserLanguage = window.navigator.userLanguage;
        }
        // Check if any query params has passed
        if (parsedLang.lang) {
          browserLanguage = parsedLang.lang;
         // this.props.updachangeLocale(parsedLang.lang);
        }
        if (!parsedLang.lang && browserLanguage) {
          if (browserLanguage.includes('en')) {
            browserLanguage = LANGUAGE_EN;
          }
          if (browserLanguage.includes('fr')) {
            browserLanguage = LANGUAGE_FR;
          }
         // this.props.updachangeLocale(browserLanguage);
        }
        this.props.updachangeLocale(browserLanguage);
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  // check if it is an IE browser
  isIE() {
    const ua = window.navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones */
    const isIe = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
    return isIe;
  }

  render() {
    return (
      <IntlProvider
        locale={this.props.locale}
        key={this.props.locale}
        messages={this.props.messages[this.props.locale]}
      >
        {React.Children.only(this.props.children)}
      </IntlProvider>
    );
  }
}

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
  updachangeLocale: PropTypes.func,
};

const mapStateToProps = (state) => ({
  locale: get(state.language, "locale"),
});

function mapDispatchToProps(dispatch) {
  return { updachangeLocale: bindActionCreators(changeLocale, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageProvider);
