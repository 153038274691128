import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import assignDeep from 'assign-deep';

import SIZE from '../../constants/breakpoints';

import PropTypes from 'prop-types';

import { media, size } from '../../styles/mixins';
import { getCurrentBreakpoint } from '../../utils/window';

ReactModal.setAppElement('#root');

/**
 * We can't use styled-components to style the ReactModal.  We've got to pass in
 * styles through a traditional react styles object.
 */

const CloseButton = styled.button`
  position: absolute;
  top: ${size()};
  right: ${size()};
  box-shadow: none;
  border: 0;
  background: none;
  color: styles.color.gray;
  font-size: ${size(3)};
  font-weight: 300;

  ${media.medium`
  top: ${size(2)};
  right: ${size(2)};
  `};
`;

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.onRequestClose = this.onRequestClose.bind(this);

    this.styles = [];

    this.styles.push({
      size: SIZE.XSMALL,
      styles: {
        overlay: {
          backgroundColor: 'rgba(19,24,35,0.8)',
          zIndex: 3,
        },
        content: {
          maxWidth: size(49),
          maxHeight: '90%',
          width: `calc(90% - ${size(4)})`,
          top: '50%',
          transform: 'translateY(-50%) translateX(-50%)',
          bottom: 'initial',
          left: '50%',
          paddingLeft: size(3),
          paddingRight: size(3),
          paddingTop: size(4),
          paddingBottom: size(4),
        },
      },
    });

    this.styles.push({
      size: SIZE.LARGE,
      styles: {
        content: {
          width: `calc(90% - ${size(8)})`,
          maxHeight: `calc(90% - ${size(8)})`,
          maxWidth: size(57),
          paddingTop: size(6),
          paddingBottom: size(4),
          paddingLeft: size(4),
          paddingRight: size(4),
        },
      },
    });

    this.state = {
      windowSize: getCurrentBreakpoint(),
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  onRequestClose() {
    if (this.props.contentLabel === 'modal_account_alt') {
      const date = new Date();
      const timestamp = date.getTime();
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'login',
        eventAction: 'close',
        eventLabel: 'close_modal',
        eventValue: gavalue, // eslint-disable-line no-undef
        dimension1: timestamp,
        dimension2: gaFlowName, // eslint-disable-line no-undef
        dimension3: gaFlowVersion, // eslint-disable-line no-undef
      });
    }
    this.props.onRequestClose();
  }

  getStyles() {
    const { windowSize } = this.state;

    return this.styles.reduce((acc, thisStyle) => {
      if (thisStyle.size <= windowSize) {
        return assignDeep(acc, thisStyle.styles);
      }
      return acc;
    }, {});
  }

  updateWindowDimensions() {
    const windowSize = getCurrentBreakpoint();

    if (windowSize !== this.state.windowSize) {
      this.setState({ windowSize });
    }
  }

  renderCloseButton() {
    const { dismissible, intl } = this.props;
    const closeLabel = intl.formatMessage({ id: 'popup_close_button' });
    if (dismissible) {
      return (
        <CloseButton
          type="button"
          onClick={this.onRequestClose}
          aria-label={closeLabel}
        >
          <span aria-hidden="true">&times;</span>
        </CloseButton>
      );
    }

    return false;
  }
  render() {
    const { children, dismissible, isOpen, contentLabel, intl } = this.props;

    const styles = this.getStyles();
    const contentLabelText = intl.formatMessage({ id: contentLabel });

    return (
      <ReactModal
        onRequestClose={dismissible ? this.onRequestClose : () => false}
        isOpen={isOpen}
        contentLabel={contentLabelText}
        style={styles}
      >
        {this.renderCloseButton()}
        {React.Children.toArray(children)}
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node,

  /** If dismissible, shows the 'x' and bubbles up onRequestClose */
  dismissible: PropTypes.bool,

  /** Should be hooked into logic for isOpen */
  onRequestClose: PropTypes.func,

  isOpen: PropTypes.bool,

  /** Used by screenreaders to identify the modal */
  contentLabel: PropTypes.string.isRequired,

  intl: intlShape,
};

export default injectIntl(Modal);
