import React from "react";
import styles from "./LoadingIndicator.module.css";
import LoadingImage from "./loader_big.gif";

const LoadingIndicator = (props) => {
  const loadingActiveClass = props.isActive
    ? " " + styles.LoadingIndicator__active
    : "";
  return (
    <div className={styles.LoadingIndicator + loadingActiveClass}>
      <img src={LoadingImage} />
    </div>
  );
};

export default LoadingIndicator;
