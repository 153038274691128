/*
 *
 * LanguageProvider actions
 *
 */

import { CHANGE_LOCALE, LOCALE_VISIBLE } from './constants';

export function changeLocale(languageLocale) {
  return {
    type: CHANGE_LOCALE,
    locale: languageLocale,
  };
}

export function changeLocaleFlag(flag) {
  return {
    type: LOCALE_VISIBLE,
    locale_visible: flag,
  };
}

export default {
  changeLocale,
  changeLocaleFlag,
};
