import * as actions from './actions';

const initialState = {
    appIdAndToken: null,
    esignData: null,
    token: null,
    isServerError: false,
    showThankYouScreen: false,
    claimantData: null,
    completeType: null,
    customerInfoState: {
        id: null,
        s1Phone: '',
        s1City: '',
        s1FirstName: '',
        s1LastName: '',
        s1PostalCode: '',
        s1Province: '',
        s1Street: '',
        resubmitS1IsAddressChanged: '',
    },
    employmentStatusState: {
        s1DateReturnedToWork: '',
        s1IsReturnedToWork: '',
        resubmitS1IsFullTimeJob: '',
        resubmitS1NotReturnedReason: '',
        s3WorkingHoursPerWeek: '',
        resubmitS1InsuranceBenefits: '',
        s1ClaimNumber: '',
        s1LoanNumber: '',
    },
    employerInfoState: {
        s3CiName: '',
        s3CiPhone: '',
        s3Extension: '',
        s3isExtension: '',
        s3InterruptionReason: '',
    },
    employmentHistoryState: {
        screen: '',
        s1IsReturnedToWork: '',
        s3DateReturnedToWork: '',
        s3DateSeveranceEnds: '',
        s3DescriptionOfDuties: '',
        s3DutiesAbleToPerform: '',
        s3EmployerWorkTitle: '',
        s3EmploymentType: '',
        s3FullDutiesWorkingHoursPerWeek: '',
        s3HiredDate: '',
        s3InterruptionReason: '',
        s3IsEmployeeReceiveSeverance: '',
        s3IsEmployeeResumedFullDuties: '',
        s3JobLossNoticeDate: '',
        s3LastWorkingDate: '',
        s3SeasonalEmploymentFrom: '',
        s3SeasonalEmploymentTo: '',
        s3WorkingHoursPerWeek: '',
    },
    additionalInformationState: {
        s3Comments: '',
    },
    tooltip: {
        isVisible: false,
        title: '',
        text: [],
		isUpload: false,
    },
    isApplicationCompleted: '',
    loadingStarted: '',
    fileFormats: null,
    fileSize: null,
    specialCharacters: null,
};

const LppReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_APP_ID:
            return {
                ...state,
                appIdAndToken: action.value,
            };
        case actions.STORE_ESIGN_DATA:
            return {
                ...state,
                esignData: action.value,
            };
        case actions.STORE_TOKEN:
            return {
                ...state,
                token: action.value,
            };
        case actions.SHOW_THANKYOU_SCREEN:
            return {
                ...state,
                showThankYouScreen: true,
            };
        case actions.IS_SERVER_ERROR: {
            return {
                ...state,
                isServerError: action.value,
            };
        }
        case actions.STORE_CUSTOMER_INFO_STATE: {
            return {
                ...state,
                customerInfoState: action.value,
            };
        }
        case actions.STORE_EMPLOYMENT_STATUS_STATE: {
            return {
                ...state,
                employmentStatusState: action.value,
            };
        }
        case actions.STORE_EMPLOYER_INFO_STATE: {
            return {
                ...state,
                employerInfoState: action.value,
            };
        }
        case actions.STORE_EMPLOYMENT_HISTORY_STATE: {
            return {
                ...state,
                employmentHistoryState: action.value,
            };
        }
        case actions.STORE_ADDITIONAL_INFO_STATE: {
            return {
                ...state,
                additionalInformationState: action.value,
            };
        }
        case actions.IS_APPLICATION_COMPLETED: {
            return {
                ...state,
                isApplicationCompleted: action.value,
            };
        }
        case actions.SET_COMPLETE_TYPE: {
            return {
                ...state,
                completeType: action.value,
            };
        }
        case actions.TOGGLE_SPINNER:
            return {
                ...state,
                loadingStarted: !state.loadingStarted,
            };
        case actions.TOOLTIP_SHOW:
            return {
                ...state,
                tooltip: {
                    ...action.value,
                    isVisible: true,
                },
            };
        case actions.TOOLTIP_HIDE:
            return {
                ...state,
                tooltip: {
                    ...state.tooltip,
                    isVisible: false,
                },
            };
        case actions.SET_FILE_FORMATS:
            return {
                ...state,
                fileFormats: action.value,
            };
        case actions.SET_FILE_SIZE:
            return {
                ...state,
                fileSize: action.value,
            };
        case actions.SET_SPECIAL_CHARACTERS:
            return {
                ...state,
                specialCharacters: action.value,
            };
        default:
            return state;
    }
};

export default LppReducer;
