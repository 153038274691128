import React, { useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import styles from './ErrorMessage.module.css';

const ErrorMessage = props => {
    const isErrorBlock = props.isActive ? styles.error_block : styles.d_none;
    const [errBlock, showErrBlock] = useState(true);


    const closeErrBlock = () => {
        showErrBlock(false);
    }
    useEffect(() => {
        showErrBlock(true);
    }, [props.isActive]);
    return (
        <Collapse className={isErrorBlock} isOpen={errBlock}>
            <div className="d-flex flex-column">
                <div className="d-flex justify-content-end">
                    <button onClick={closeErrBlock} className={styles.error_btn}>
                        <i className="fas fa-times-circle"></i>
                    </button>
                </div>
                <p className={`d-flex ${styles.error_msg}`}>
                    Lorem consectetur adipisicing elit.Maiores iure consectetur animi a corporis enim blanditiis aliquam. Necessitatibus eos nisi molestias similique qui. Maiores iure consectetur animi a corporis enim blanditiis aliquam, reprehenderit totam labore cum nulla ea adipisci?
            </p>
            </div>
        </Collapse>
    )
}
export default ErrorMessage;