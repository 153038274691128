import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import * as actionCreators from "../../../store/LppProjectActions";
import { callPaths } from "../../../configuration";
import { setVal } from "../../../helpers/setValueHelper";
import * as moment from "moment";
import { decimalNumberValidation } from "../../../helpers/validationHelper";

import styled from "styled-components";
import LoanAppHeader from "../../../components/LoanHeader";
import MainWrapper from "../../../components/MainWrapper";
import Button from "../../../components/Button";
import Center from "../../../components/MainWrapper/Center";
import Group from "../../../components/Group";
import QuestionGroups from "../../../components/QuestionGroups";
import Label from "../../../components/QuestionWrapper/Label";
import FormChecklist from "../../../components/FormCheckList";
import FormCheck from "../../../components/FormCheck";
import InputRadio from "../../../components/InputRadioGroup/InputRadio";
import ColorBox from "../../../components/ColorBox";
import ColorBoxGroup from "../../../components/ColorBoxGroup";
import TooltipSVG from "../../../components/QuestionWrapper/tooltip.svg";
import InputWrapper from "../../../components/InputText/InputWrapper";
import ErrorMessage from "../../../components/QuestionWrapper/ErrorMessage";
import InputDateField from "../../../components/InputText/InputDateField";

const LeftAlign = styled.div`
  text-align: left;
`;

const ToolTip = styled.img`
  float: right;
`;

const QuestionWrap = styled.div`
  margin-top: 8px;
`;

const NextPrevButtons = styled.div`
  display: flex;
`;

const AdditionalQuestions = styled.div`
  margin-top: 1em;
  margin-left: 2em;
`;

const EmploymentHistoryPage2 = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();

  const [collapse2, setCollapse2] = useState(false);
  const [collapse3, setCollapse3] = useState(false);
  const [collapse4, setCollapse4] = useState(false);
  const [collapse5, setCollapse5] = useState(false);

  let [formValues, setFormValues] = useState({
    ...props.appState.employmentHistoryState,
  });

  const emplymentStatusList = [
    { permanent: null },
    { seasonal: null },
    { temporary: null },
    { contract: null },
    { self_employed: null },
  ];
  let severanceYes = null;
  let severanceNo = null;
  let returnedToWorkYes = null;
  let returnedToWorkNo = null;
  let fdYes = null;
  let fdNo = null;
  let currentState = props.appState.employmentHistoryState;

  if (currentState) {
    if (
      currentState.s3IsEmployeeReceiveSeverance &&
      currentState.s3IsEmployeeReceiveSeverance !== ""
    ) {
      severanceYes = true;
    } else if (
      !currentState.s3IsEmployeeReceiveSeverance &&
      currentState.s3IsEmployeeReceiveSeverance !== ""
    ) {
      severanceNo = true;
    }

    if (
      currentState.s1IsReturnedToWork &&
      currentState.s1IsReturnedToWork !== ""
    ) {
      returnedToWorkYes = true;
    } else if (
      !currentState.s1IsReturnedToWork &&
      currentState.s1IsReturnedToWork !== ""
    ) {
      returnedToWorkNo = true;
    }
    if (
      currentState.s3IsEmployeeResumedFullDuties &&
      currentState.s3IsEmployeeResumedFullDuties !== ""
    ) {
      fdYes = true;
    } else if (
      !currentState.s3IsEmployeeResumedFullDuties &&
      currentState.s3IsEmployeeResumedFullDuties !== ""
    ) {
      fdNo = true;
    }

    for (let i = 0; i < emplymentStatusList.length; i++) {
      if (
        emplymentStatusList[i].hasOwnProperty(
          currentState.s3EmploymentType.toLowerCase()
        )
      ) {
        emplymentStatusList[i][
          currentState.s3EmploymentType.toLowerCase()
        ] = true;
      }
    }
  }

  let handleChange = (e) => {
    let name = e.target.name;
    let id = e.target.id;
    collapseHandler(id, name);
    let value;
    if (name === "s3EmploymentType") {
      value = id;
    } else if (
      name === "s3IsEmployeeReceiveSeverance" ||
      name === "s1IsReturnedToWork" ||
      name === "s3IsEmployeeResumedFullDuties"
    ) {
      value =
        id === "severanceYes" || id === "returnedToWorkYes" || id === "fdYes";
    } else {
      value = e.target.value;
    }
    formValues[name] = value;
    setFormValues(formValues);
  };
  const backButtonHandler = () => {
    props.storeEmploymentHistoryState(formValues);
    history.push("/employmentHistory");
  };

  const [isValidNoticeDate, setIsValidNoticeDate] = useState(true);
  const [dob, setDate] = useState("");

  const isFeautureDate = (date) => {
    var today = new Date();
    var dateForChecking = new Date(date);
    let result = date !== "" ? today <= dateForChecking : true;
    return result;
  };

  const changeDate = (date, field) => {
    if (date) {
      // Date format YYYY-MM-DD
      formValues[field] = date;
      setFormValues(formValues);
      setDate(date);
      if (field === "s3JobLossNoticeDate") {
        if (isFeautureDate(date)) {
          setIsValidNoticeDate(false);
        } else {
          setIsValidNoticeDate(true);
        }
      }
    }
  };

  const onSubmit = () => {
    window.scrollTo(0, 0);
    if (!isValidNoticeDate) {
      return;
    }
    props.toggleSpinner();
    formValues.id = props.appState.appIdAndToken.token;
    formValues.screen = "EMPLOYMENT_HISTORY_2";
    props.storeEmploymentHistoryState(formValues);
    axios
      .post(callPaths.screen5, formValues)
      .then((response) => {
        props.toggleSpinner();
        history.push("/additionalInformation");
        window.scrollTo(0, 0);
      })
      .catch(function(error) {
        props.toggleSpinner();
        console.log(error);
      });
  };

  const collapseHandler = (id) => {
    if (id === "severanceYes") {
      setCollapse2(true);
    } else if (id === "severanceNo") {
      setCollapse2(false);
      formValues["s3DateSeveranceEnds"] = "";
    } else if (id === "returnedToWorkYes") {
      setCollapse3(true);
    } else if (id === "returnedToWorkNo") {
      setCollapse3(false);
      setCollapse4(false);
      setCollapse5(false);
      formValues["s3DateReturnedToWork"] = "";
      formValues["s3IsEmployeeResumedFullDuties"] = "";
      formValues["s3FullDutiesWorkingHoursPerWeek"] = "";
      formValues["s3DutiesAbleToPerform"] = "";
    } else if (id === "fdYes") {
      setCollapse4(true);
      setCollapse5(false);
      formValues["s3DutiesAbleToPerform"] = "";
    } else if (id === "fdNo") {
      setCollapse4(false);
      setCollapse5(true);
      formValues["s3FullDutiesWorkingHoursPerWeek"] = "";
    }
  };

  useEffect(() => {
    if (currentState && currentState.s3IsEmployeeReceiveSeverance) {
      setCollapse2(true);
    }
    if (currentState && currentState.s1IsReturnedToWork) {
      setCollapse3(true);
    }
    if (
      (currentState && currentState.s3IsEmployeeResumedFullDuties === false) ||
      currentState.s3IsEmployeeResumedFullDuties === true
    ) {
      currentState.s3IsEmployeeResumedFullDuties
        ? setCollapse4(true)
        : setCollapse5(true);
    }
    if (!props.appState.appIdAndToken) {
      history.push("/");
    }
  }, []);

  return (
    <>
      <LoanAppHeader progress={75} />
      <MainWrapper>
        <Center>
          <ColorBoxGroup>
            <ColorBox green>
              <LeftAlign>
                <FormattedMessage id="lpp_page5_header" />
              </LeftAlign>
            </ColorBox>
            <ColorBox big blue>
              <FormattedMessage id="lpp_page5_subheader" />
            </ColorBox>
          </ColorBoxGroup>
          <form noValidate>
            <QuestionGroups>
              <Group>
                <Label htmlFor="s3EmployerWorkTitle">
                  <FormattedMessage id="lpp_page5_question1_label" />
                </Label>
                <QuestionWrap>
                  <InputWrapper
                    isValid={errors.s3EmployerWorkTitle === undefined}
                  >
                    <input
                      type="text"
                      name="s3EmployerWorkTitle"
                      id="s3EmployerWorkTitle"
                      placeholder=""
                      onChange={handleChange}
                      ref={register({
                        minLength: 2,
                        maxLength: 50,
                      })}
                      defaultValue={setVal(
                        props.appState.employmentHistoryState,
                        "s3EmployerWorkTitle"
                      )}
                    />
                    {errors.s3CiName && errors.s3CiName.type === "required" && (
                      <ErrorMessage>
                        <FormattedMessage id="lpp_validation_required" />
                      </ErrorMessage>
                    )}
                    {errors.s3EmployerWorkTitle &&
                      (errors.s3EmployerWorkTitle.type === "minLength" ||
                        errors.s3EmployerWorkTitle.type === "maxLength") && (
                        <ErrorMessage>
                          <FormattedMessage id="lpp_validation_job_title" />
                        </ErrorMessage>
                      )}
                  </InputWrapper>
                </QuestionWrap>
                <Label htmlFor="s3DescriptionOfDuties">
                  <FormattedMessage id="lpp_page5_question2_label" />
                  <ToolTip
                    role="link"
                    tabIndex="0"
                    className="tooltip"
                    src={TooltipSVG}
                    alt="tooltip"
                    onClick={() =>
                      props.showTooltip("tooltip_title", ["tooltip_text7"])
                    }
                  />
                </Label>
                <QuestionWrap>
                  <InputWrapper
                    isValid={errors.s3DescriptionOfDuties === undefined}
                  >
                    <input
                      type="textarea"
                      name="s3DescriptionOfDuties"
                      id="s3DescriptionOfDuties"
                      placeholder=""
                      onChange={handleChange}
                      ref={register({ maxLength: 500 })}
                      defaultValue={setVal(
                        props.appState.employmentHistoryState,
                        "s3DescriptionOfDuties"
                      )}
                    />
                    {errors.s3DescriptionOfDuties && (
                      <ErrorMessage>
                        <FormattedMessage id="lpp_validation_max500" />
                      </ErrorMessage>
                    )}
                  </InputWrapper>
                </QuestionWrap>
                <Label htmlFor="s3JobLossNoticeDate">
                  <FormattedMessage id="lpp_page5_question3_label" />
                  <ToolTip
                    role="link"
                    tabIndex="0"
                    className="tooltip"
                    src={TooltipSVG}
                    alt="tooltip"
                    onClick={() =>
                      props.showTooltip("tooltip_title", ["tooltip_text8"])
                    }
                  />
                </Label>
                <QuestionWrap>
                  <InputWrapper
                    isValid={errors.s3JobLossNoticeDate === undefined}
                  >
                    <InputDateField
                      name="s3JobLossNoticeDate"
                      id="s3JobLossNoticeDate"
                      placeholder="q_birth_day"
                      onChange={(evt) =>
                        changeDate(evt.target.value, evt.target.name)
                      }
                      onBlur={handleChange}
                      onFocus={() => null}
                      value={formValues.s3JobLossNoticeDate}
                      increaseYear={false}
                      isValid={isValidNoticeDate}
                    />
                    {!isValidNoticeDate && (
                      <ErrorMessage>
                        <FormattedMessage id="lpp_validation_future_date" />
                      </ErrorMessage>
                    )}
                  </InputWrapper>
                </QuestionWrap>
                <Label htmlFor="s3InterruptionReason">
                  <FormattedMessage id="lpp_page5_question4_label" />
                  <ToolTip
                    role="link"
                    tabIndex="0"
                    className="tooltip"
                    src={TooltipSVG}
                    alt="tooltip"
                    onClick={() =>
                      props.showTooltip("tooltip_title", ["tooltip_text11"])
                    }
                  />
                </Label>
                <QuestionWrap>
                  <InputWrapper
                    isValid={errors.s3InterruptionReason === undefined}
                  >
                    <input
                      type="textarea"
                      name="s3InterruptionReason"
                      id="s3InterruptionReason"
                      placeholder=""
                      onChange={handleChange}
                      ref={register({ maxLength: 500 })}
                      defaultValue={setVal(
                        props.appState.employmentHistoryState,
                        "s3InterruptionReason"
                      )}
                    />
                    {errors.s3InterruptionReason && (
                      <ErrorMessage>
                        <FormattedMessage id="lpp_validation_max500" />
                      </ErrorMessage>
                    )}
                  </InputWrapper>
                </QuestionWrap>
                <Label htmlFor="s3LastWorkingDate">
                  <FormattedMessage id="lpp_page5_question5_label" />
                </Label>
                <QuestionWrap>
                  <InputWrapper isValid={true}>
                    <InputDateField
                      name="s3LastWorkingDate"
                      id="s3LastWorkingDate"
                      placeholder="q_birth_day"
                      onChange={(evt) =>
                        changeDate(evt.target.value, evt.target.name)
                      }
                      onBlur={handleChange}
                      onFocus={() => null}
                      value={formValues.s3LastWorkingDate}
                      increaseYear={true}
                      isValid={true}
                    />
                  </InputWrapper>
                </QuestionWrap>
              </Group>
              <Group>
                <Label htmlFor="s3IsEmployeeReceiveSeverance">
                  <FormattedMessage id="lpp_page5_question6_label" />
                </Label>
                <FormChecklist>
                  <FormCheck>
                    <InputRadio
                      id="severanceYes"
                      name="s3IsEmployeeReceiveSeverance"
                      value="yes"
                      label="Yes"
                      onChange={handleChange}
                      defaultChecked={severanceYes}
                    />
                  </FormCheck>
                  <FormCheck>
                    <InputRadio
                      id="severanceNo"
                      name="s3IsEmployeeReceiveSeverance"
                      value="no"
                      label="No"
                      onChange={handleChange}
                      defaultChecked={severanceNo}
                    />
                  </FormCheck>
                </FormChecklist>
                {collapse2 === true ? (
                  <>
                    <br />
                    <Label htmlFor="s3DateSeveranceEnds">
                      <FormattedMessage id="lpp_page5_collapse1_label" />
                    </Label>
                    <InputWrapper isValid={true}>
                      <InputDateField
                        name="s3DateSeveranceEnds"
                        id="s3DateSeveranceEnds"
                        placeholder="q_birth_day"
                        onChange={(evt) =>
                          changeDate(evt.target.value, evt.target.name)
                        }
                        onBlur={handleChange}
                        onFocus={() => null}
                        value={formValues.s3DateSeveranceEnds}
                        increaseYear={true}
                        isValid={true}
                      />
                    </InputWrapper>
                  </>
                ) : null}
              </Group>
              <Group>
                <Label htmlFor="returnedToWork">
                  <FormattedMessage id="lpp_page5_question7_label" />
                  &nbsp;
                  {props.appState.employerInfoState.s3CiName}?
                </Label>
                <FormChecklist>
                  <FormCheck>
                    <InputRadio
                      id="returnedToWorkYes"
                      name="s1IsReturnedToWork"
                      value="yes"
                      label="Yes"
                      onChange={handleChange}
                      defaultChecked={returnedToWorkYes}
                    />
                  </FormCheck>
                  <FormCheck>
                    <InputRadio
                      id="returnedToWorkNo"
                      name="s1IsReturnedToWork"
                      value="no"
                      label="No"
                      onChange={handleChange}
                      defaultChecked={returnedToWorkNo}
                    />
                  </FormCheck>
                </FormChecklist>
                {collapse3 === true ? (
                  <>
                    <br />
                    <Label htmlFor="s3DateReturnedToWork">
                      <FormattedMessage id="lpp_page5_collapse2_label1" />
                      &nbsp;
                      {props.appState.employerInfoState.s3CiName}?
                    </Label>
                    <InputWrapper
                      isValid={errors.s3DateReturnedToWork === undefined}
                    >
                      <InputDateField
                        name="s3DateReturnedToWork"
                        id="s3DateReturnedToWork"
                        placeholder="q_birth_day"
                        onChange={(evt) =>
                          changeDate(evt.target.value, evt.target.name)
                        }
                        onBlur={handleChange}
                        onFocus={() => null}
                        value={formValues.s3DateReturnedToWork}
                        increaseYear={true}
                        isValid={true}
                      />
                    </InputWrapper>
                    <br />
                    <Label htmlFor="s3IsEmployeeResumedFullDuties">
                      <FormattedMessage id="lpp_page5_collapse2_label2" />
                    </Label>
                    <FormChecklist>
                      <FormCheck>
                        <InputRadio
                          id="fdYes"
                          name="s3IsEmployeeResumedFullDuties"
                          value="yes"
                          label="Yes"
                          onChange={handleChange}
                          defaultChecked={fdYes}
                        />
                      </FormCheck>
                      <FormCheck>
                        <InputRadio
                          id="fdNo"
                          name="s3IsEmployeeResumedFullDuties"
                          value="no"
                          label="No"
                          onChange={handleChange}
                          defaultChecked={fdNo}
                        />
                      </FormCheck>
                    </FormChecklist>
                    <br />
                  </>
                ) : null}
                {collapse4 === true ? (
                  <>
                    <Label htmlFor="s3FullDutiesWorkingHoursPerWeek">
                      <FormattedMessage id="lpp_page5_collapse3_label1" />
                    </Label>
                    <InputWrapper
                      isValid={
                        errors.s3FullDutiesWorkingHoursPerWeek === undefined
                      }
                    >
                      <input
                        type="text"
                        name="s3FullDutiesWorkingHoursPerWeek"
                        id="s3FullDutiesWorkingHoursPerWeek"
                        step=".01"
                        placeholder=""
                        onChange={(e) => {
                          e.target.value = e.target.value.trim();
                          handleChange(e);
                        }}
                        ref={register({
                          validate: (val) => decimalNumberValidation(val),
                        })}
                        defaultValue={setVal(
                          props.appState.employmentHistoryState,
                          "s3FullDutiesWorkingHoursPerWeek"
                        )}
                      />
                      {errors.s3FullDutiesWorkingHoursPerWeek &&
                        errors.s3FullDutiesWorkingHoursPerWeek.type ===
                          "validate" && (
                          <ErrorMessage>
                            <FormattedMessage id="lpp_validation_decimal_number" />
                          </ErrorMessage>
                        )}
                    </InputWrapper>
                  </>
                ) : null}
                {collapse5 === true ? (
                  <>
                    <Label htmlFor="s3DutiesAbleToPerform">
                      <FormattedMessage id="lpp_page5_collapse4_label1" />
                      <ToolTip
                        role="link"
                        tabIndex="0"
                        className="tooltip"
                        src={TooltipSVG}
                        alt="tooltip"
                        onClick={() =>
                          props.showTooltip("tooltip_title", ["tooltip_text9"])
                        }
                      />
                    </Label>
                    <InputWrapper isValid={true}>
                      <input
                        type="textarea"
                        name="s3DutiesAbleToPerform"
                        id="s3DutiesAbleToPerform"
                        placeholder=""
                        onChange={handleChange}
                        ref={register({ maxLength: 500 })}
                        defaultValue={setVal(
                          props.appState.employmentHistoryState,
                          "s3DutiesAbleToPerform"
                        )}
                      />
                      {errors.s3DutiesAbleToPerform && (
                        <ErrorMessage>
                          <FormattedMessage id="lpp_validation_max500" />
                        </ErrorMessage>
                      )}
                    </InputWrapper>
                  </>
                ) : null}
              </Group>
            </QuestionGroups>
            <NextPrevButtons>
              <Button outlined onClick={backButtonHandler}>
                <FormattedMessage id="lpp_cta_back" />
              </Button>
              <Button onClick={handleSubmit(onSubmit)} type="button">
                <FormattedMessage id="lpp_cta_next" />
              </Button>
            </NextPrevButtons>
          </form>
        </Center>
      </MainWrapper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    appState: state.crossSellApp,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    storeEmploymentHistoryState: (data) =>
      dispatch(actionCreators.storeEmploymentHistoryState(data)),
    toggleSpinner: () => dispatch(actionCreators.toggleSpinner()),
    showTooltip: (title, texts) =>
      dispatch(
        actionCreators.showTooltip({
          title: title,
          text: texts,
        })
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EmploymentHistoryPage2));
