import styled from 'styled-components';
import { media } from '../../styles/mixins';

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10px;
  ${media.medium`
    padding-top: 0px;
  `};
`;
