import { loadCSS, loadJS } from './helpers';

const key = "hb98-ub47-fz31-pb29";

export default function loadCanadaPost() {
  const CSSHref = `https://ws1.postescanada-canadapost.ca/css/addresscomplete-2.00.min.css?key=${key}`;
  const JSSrc = `https://ws1.postescanada-canadapost.ca/js/addresscomplete-2.00.min.js?key=${key}`;

  loadCSS(CSSHref);
  loadJS(JSSrc);
}

export const ADDRESS_PARTS = {
  POSTAL_CODE: 'PostalCode',
  LINE1: 'Line1',
  LINE2: 'Line2',
  CITY: 'City',
  PROVINCE: 'Province',
};

export const applyCanadaPost = (addressElmtID, postalCodeElmtID, cityElmtId, provElmtID, onChange, onBlur, triggerValidations) => {
  // If canada post is not loaded, do not apply
  if (!window.pca) {
    return;
  }

  const control = new window.pca.Address([{
        field: 'Line1',
        element: addressElmtID,
      },{
        field: 'PostalCode',
        element: postalCodeElmtID,
      },{
        field: 'City',
        element: cityElmtId,
        mode: window.pca ? window.pca.fieldMode.POPULATE : false,
      },{
        field: 'ProvinceCode',
        element: provElmtID,
        mode: window.pca ? window.pca.fieldMode.POPULATE : false,
      }],
      {
        key: key,
        bar: {
          showCountry: false,
          showLogo: false,
          manualEntry: true
        }
      }
  );
  control.setCountry("Canada");

  const applyFields = {
    [ADDRESS_PARTS.POSTAL_CODE]: postalCodeElmtID,
    [ADDRESS_PARTS.LINE1]: addressElmtID,
    [ADDRESS_PARTS.CITY]: cityElmtId,
    [ADDRESS_PARTS.PROVINCE]: provElmtID,
  };

  control.listen('populate', (address) => {
    triggerValidations();
    // For each address object we recognize, fire off a simulated change event
    // to change each field
    Object.entries(address).forEach(([key, value]) => {
      // If the address field is not mapped to a config field, bail
      if (!applyFields) {
        return;
      }

      // Create the simulated event
      const simulatedEvent = {
        target: {
          name: applyFields,
          value,
        },
      };

      // Act like a normal change
      onChange(simulatedEvent);
      onBlur(simulatedEvent);
    });
  });
};