import { css } from 'styled-components';
import styles from '../../styles/constants';
import { size, getFontSizes, media } from '../../styles/mixins';

import oversizedBackground from './oversized-background.svg';

// Set what colour StyledButton will use based on props

function getLinkColour() {
  return (props) => {
    const { oversized, secondary, critical, link, bluelink, outlined } = props;
    if (oversized) {
      return styles.color.white;
    }
    if (outlined) {
      return styles.color.green;
    }
    if (secondary) {
      return styles.color.white;
    }
    if (critical) {
      return styles.color.red;
    }
    if (link) {
      return styles.color.green;
    }
    if (bluelink) {
      return styles.color.blue;
    }
    // if none of the above, return white for now. Final styling will determine this.
    return styles.color.white;
  };
}

// getBGColour is a temporary function to set the background to pink if no StyledButton prop is set.
// Once final stylings are determined, this will either be modified or go away.

function getBGColour() {
  return (props) => {
    const {
      oversized,
      secondary,
      critical,
      link,
      graybg,
      bluelink,
      outlined,
      forward,
    } = props;
    if (oversized) {
      return styles.color.green;
    }

    if (outlined && secondary) {
      return styles.color.white;
    }

    if (secondary) {
      return styles.color.green;
    }

    if (critical || bluelink) {
      return 'transparent';
    }

    if (link) {
      if (graybg) {
        return styles.color.gray3;
      }
      return styles.color.white;
    }

    if (outlined) {
      return styles.color.gray3;
    }

    if (forward) {
      return styles.color.green;
    }

    // if none of the above, make the background pink. Final styling will determine this.
    return styles.color.green;
  };
}

function getHeight() {
  return (props) => {
    const { outlined, forward, secondary, small } = props;
    if (forward || (outlined && !secondary)) {
      return '64px';
    }
    if (small) {
      return '32px';
    }
    return '';
  };
}

function getBorder() {
  return (props) => {
    const { outlined } = props;
    if (outlined) {
      return 'solid 2px #158709;';
    }
    return 'none';
  };
}

function getFontSizeName(props) {
  const { oversized, secondary, critical } = props;
  if (oversized) {
    return 'button_oversized';
  }

  if (secondary || critical) {
    return 'button_secondary';
  }

  return 'button';
}

// Stylings for StyledButton and StyledSpan
const sharedStyles = css`
  ${(props) => getFontSizes(getFontSizeName(props))};
  border-radius: ${styles.size.border_radius.main};
  box-shadow: ${(props) =>
    props.secondary || props.critical || props.link || props.bluelink
      ? ''
      : styles.shadow.small};
  color: ${getLinkColour};
  background-color: ${getBGColour};
  line-height: 1.3;
  border: ${getBorder};
  position: relative;
  display: block;
  width: 100%;
  cursor: pointer;
  padding: ${(props) =>
    props.oversized ? `${size(2)} ${size(1)}` : `${size(1)} ${size(1)}`};
  text-align: center;
  max-width: ${size(50)};
  margin: auto;
  height: ${getHeight};

  &:after {
    content: ${(props) => (props.oversized || props.forward ? '" "' : 'none')};
    background: url(${(props) => (props.disabled ? '' : oversizedBackground)})
      no-repeat;
    width: ${size(3)};
    height: ${size(3)};
    float: right;
    display: inline-block;
    margin-left: ${size()};
    margin-right: ${size(1)};
  }

  &[disabled] {
    color: ${styles.color.white};
    cursor: not-allowed;
    background-color: ${styles.color.button_color_disabled};
  }

  ${media.medium`
    max-width: none;
    padding: ${(props) =>
      props.oversized || props.forward
        ? `${size(2)} ${size(3)}`
        : `${size(1.5)} ${size(3)}`};

    &:after {
      content: ${(props) =>
        props.oversized || props.forward ? '" "' : 'none'};
      width: ${size(3)};
      height: ${size(3)};
      float: right;
      display: inline-block;
      margin-left: ${size()};
    }
  `};
`; // eslint-disable-line no-nested-ternary

export default sharedStyles;
