import styled from 'styled-components';

import { getFontSizes } from '../../styles/mixins';
import styles from '../../styles/constants';

const H2 = styled.h2`
  ${getFontSizes('h2')};
  font-weight: ${styles.typography.weight.h2};
  color: ${styles.color.text};
  margin-bottom: 0;
  margin-top: 0;
  line-height: 1.5;
  text-transform: uppercase;
`;

export default H2;
