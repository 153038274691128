/**
 *
 * LogoutWarning
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import H2 from '../../components/H2';
import Button from '../../components/Button';
import ButtonGroup from '../../components/ButtonGroup';

import Modal from './index';

class LogoutWarning extends React.PureComponent {
  render() {
    const { props } = this;
    const { logout, onRequestClose } = this.props;
    return (
      <Modal {...props} contentLabel="retry_login_alt">
        <H2>
          <FormattedMessage id="logout_warning" />
        </H2>

        <ButtonGroup>
          <Button button onClick={logout}>
            <FormattedMessage id="yes_label" />
          </Button>
        </ButtonGroup>

        <ButtonGroup>
          <Button secondary outlined onClick={onRequestClose}>
            <FormattedMessage id="no_label" />
          </Button>
        </ButtonGroup>
      </Modal>
    );
  }
}

LogoutWarning.propTypes = {
  logout: PropTypes.func,
  onRequestClose: PropTypes.func,
};

export default LogoutWarning;
