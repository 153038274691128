import styled from 'styled-components';
import { getFontSizes } from '../../styles/mixins';

export default styled.label`
  ${(props) =>
    props.small
      ? getFontSizes('question_label_small')
      : getFontSizes('question_label')};
  display: block;
  font-weight: 500;
`;
