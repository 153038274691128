import QUESTION_TYPES from './types';

export default {
  [QUESTION_TYPES.AMOUNT_SLIDER]: null,
  [QUESTION_TYPES.DOB_DROPDOWN]: '',
  [QUESTION_TYPES.CURRENCY]: null,
  [QUESTION_TYPES.DATE]: '',
  [QUESTION_TYPES.EMAIL]: '',
  [QUESTION_TYPES.MULTIPLE]: [],
  [QUESTION_TYPES.NUMBER]: '',
  [QUESTION_TYPES.SIN]: '',
  [QUESTION_TYPES.CHECKBOX]: false,
  [QUESTION_TYPES.PHONE]: '',
  [QUESTION_TYPES.RADIO]: null,
  [QUESTION_TYPES.RADIOBOX]: null,
  [QUESTION_TYPES.TEXT]: '',
  [QUESTION_TYPES.DROPDOWN]: '',
};
