import React from 'react';
import { intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { size, media } from '../../styles/mixins';
import Submenu from '../../containers/Menu';
import logoEN from '../../images/logo.svg';
import logoFR from '../../images/efs-logo.png';
import logoEH from '../../images/eh-logo.png';

import LocalStorageClass from '../../utils/util';
import { CONFIG, getConfig } from '../../config';

const StyledNav = styled.nav`
  padding: ${size(1)};

  & > ul {
    margin: 0;
    padding-left: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ${media.medium`
    padding: ${size(3)};
  `};
`;

class Nav extends React.PureComponent {
  constructor(props) {
    super(props);
    this.altText = this.props.intl.formatMessage({
      id: 'forgot_password_home_alt',
    });
  }

  /* Get site details - EH, EFS  */

  getSiteDetails() {
    let returnValue = {
      logoSrc: logoEN,
      logoURl: getConfig(CONFIG.EFS_WEBSITE_EN),
    };
    const LOCALHOST_FR = getConfig(CONFIG.HOST_FR);
    if (LocalStorageClass.getHostName() === LOCALHOST_FR) {
      returnValue = {
        logoSrc: logoFR,
        logoURl: getConfig(CONFIG.EFS_WEBSITE_FR),
      };
    } else if (LocalStorageClass.ifSourceEasyHome() === true && this.props.disablelogologic === false) {
      returnValue = {
        logoSrc: logoEH,
        logoURl: getConfig(CONFIG.EH_WEBSITE_URL),
      };
    }
    return returnValue;
  }

  render() {
    const siteDetails = this.getSiteDetails();
    return (
      <StyledNav>
        <ul>
          <li key="logo">
            <a href={siteDetails.logoURl}>
              <img
                src={siteDetails.logoSrc}
                alt={this.altText}
                style={{ height: '40px' }}
              />
            </a>
          </li>
          <Submenu />
        </ul>
      </StyledNav>
    );
  }
}

Nav.defaultProps = {
  disablelogologic: false,
};

Nav.propTypes = {
  intl: intlShape.isRequired,
  disablelogologic: PropTypes.bool,
};

export default injectIntl(Nav);
