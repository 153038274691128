import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import * as actionCreators from "../../../store/LppProjectActions";
import { callPaths } from "../../../configuration";
import { setVal, transforrmPhoneNumber } from "../../../helpers/setValueHelper";
import {
  emailValidation,
  onlyLettersValidation,
  checkPhoneNumber,
} from "../../../helpers/validationHelper";
import InputMask from "react-input-mask";

import styled from "styled-components";
import LoanAppHeader from "../../../components/LoanHeader";
import MainWrapper from "../../../components/MainWrapper";
import Button from "../../../components/Button";
import Center from "../../../components/MainWrapper/Center";
import Group from "../../../components/Group";
import QuestionGroups from "../../../components/QuestionGroups";
import Label from "../../../components/QuestionWrapper/Label";
import ColorBox from "../../../components/ColorBox";
import ColorBoxGroup from "../../../components/ColorBoxGroup";
import TooltipSVG from "../../../components/QuestionWrapper/tooltip.svg";
import InputWrapper from "../../../components/InputText/InputWrapper";
import ErrorMessage from "../../../components/QuestionWrapper/ErrorMessage";
import InputPhone from "../../../components/InputText/InputPhone";
import HelpText from "../../../components/QuestionWrapper/Helptext";
import H3 from "../../../components/H3";
import FormChecklist from "../../../components/FormCheckList";
import FormCheck from "../../../components/FormCheck";
import InputRadio from "../../../components/InputRadioGroup/InputRadio";

import { applyCanadaPost } from "../../../vendor/canadaPost";

const LeftAlign = styled.div`
  text-align: left;
`;

const ToolTip = styled.img`
  float: right;
`;

const QuestionWrap = styled.div`
  margin-top: 8px;
`;

const NextPrevButtons = styled.div`
  display: flex;
`;

const CanadaPostAddressInput = (props) => {
  useEffect(() => {
    // Allow canada post to load
    window.setTimeout(() => {
      applyCanadaPost(
        "s3CiStreet",
        "s3CiPostalCode",
        "s3CiCity",
        "s3CiProvince",
        props.onChange,
        props.onBlur,
        function triggerCanadaPostFields() {}
      );
    }, 500);
  });

  return (
    <input
      type="text"
      name="s3CiStreet"
      id="s3CiStreet"
      placeholder=""
      onChange={props.onChange}
      ref={props.register()}
      defaultValue={setVal(props.appState.employerInfoState, "s3CiStreet")}
    />
  );
};

const EmployerInfoPage = (props) => {
  const { register, handleSubmit, errors, control } = useForm();
  const history = useHistory();
  const currentState = props.appState.employerInfoState;

  let isExtensionYes = null;
  let isExtensionNo = null;

  if (currentState) {
    if (
      currentState.s3isExtension &&
      currentState.s3isExtension !== ""
    ) {
      isExtensionYes = "checked";
    } else if (
      !currentState.s3isExtension &&
      currentState.s3isExtension !== ""
    ) {
      isExtensionNo = "checked";
    }
  }

  const [collapse, setCollapse] = useState(false);

  const collapseHandler = (id, name) => {
    if (id === "isExtensionYes") {
      setCollapse(true);
    } else if (id === "isExtensionNo") {
      setCollapse(false);
    }
  };

  let [formValues, setFormValues] = useState({
    ...props.appState.employerInfoState,
  });

  let handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let id = e.target.id;
    if (name === "s3isExtension") {
      collapseHandler(id, name);
        value = id === "isExtensionYes";
    }
    formValues[name] = value;
    setFormValues(formValues);
  };

  const backButtonHandler = () => {
    // formValues.s3CiCity = document.getElementById("s3CiCity").value;
    // formValues.s3CiPostalCode = document.getElementById("s3CiPostalCode").value;
    // formValues.s3CiProvince = document.getElementById("s3CiProvince").value;
    // formValues.s3CiStreet = document.getElementById("s3CiStreet").value;
    props.storeEmployerInfoState(formValues);
    history.push("/customerInfo");
  };

  const onSubmit = (data) => {
    props.toggleSpinner();
    formValues.id = props.appState.appIdAndToken.token;
    formValues.s3CiPhone = transforrmPhoneNumber(formValues.s3CiPhone);
    // formValues.s3CiCity = data.s3CiCity;
    // formValues.s3CiPostalCode = data.s3CiPostalCode;
    // formValues.s3CiProvince = data.s3CiProvince;
    // formValues.s3CiStreet = data.s3CiStreet;
    props.storeEmployerInfoState(formValues);
    axios
      .post(callPaths.screen3, formValues)
      .then((response) => {
        props.toggleSpinner();
        history.push("/employmentStatus");
        window.scrollTo(0, 0);
      })
      .catch(function(error) {
        props.toggleSpinner();
        console.log(error);
      });
  };

  useEffect(() => {
    if (!props.appState.appIdAndToken) {
      history.push("/");
    }
  }, []);

  return (
    <>
      <LoanAppHeader progress={45} />
      <MainWrapper>
        <Center>
          <ColorBoxGroup>
            <ColorBox green>
              <LeftAlign>
                <FormattedMessage id="lpp_page3_header" />
              </LeftAlign>
            </ColorBox>
            <ColorBox big blue>
              <FormattedMessage id="lpp_page3_subheader" />
            </ColorBox>
          </ColorBoxGroup>
          <form noValidate="novalidate">
            <QuestionGroups>
              <Group>
                <Label htmlFor="s3CiName">
                  <FormattedMessage id="lpp_page3_question1_label" />
                </Label>
                <QuestionWrap>
                  <InputWrapper isValid={errors.s3CiName === undefined}>
                    <input
                      type="text"
                      name="s3CiName"
                      id="s3CiName"
                      placeholder=""
                      onChange={handleChange}
                      ref={register({
                        required: true,
                        minLength: 2,
                        maxLength: 50,
                      })}
                      defaultValue={setVal(
                        props.appState.employerInfoState,
                        "s3CiName"
                      )}
                    />
                    {errors.s3CiName && errors.s3CiName.type === "required" && (
                      <ErrorMessage>
                        <FormattedMessage id="lpp_validation_required" />
                      </ErrorMessage>
                    )}
                    {errors.s3CiName &&
                      (errors.s3CiName.type === "minLength" ||
                        errors.s3CiName.type === "maxLength") && (
                        <ErrorMessage>
                          <FormattedMessage id="lpp_validation_company_name" />
                        </ErrorMessage>
                      )}
                  </InputWrapper>
                </QuestionWrap>
              </Group>
            </QuestionGroups>
            <br />
            <QuestionGroups>
              <Group>
                {/* <H3>
                  <FormattedMessage id="lpp_page3_question2_label" />
                  &nbsp;
                  <ToolTip
                    role="link"
                    tabIndex="0"
                    className="tooltip"
                    src={TooltipSVG}
                    alt="tooltip"
                    onClick={() =>
                      props.showTooltip("tooltip_title", ["tooltip_text4"])
                    }
                  />
                </H3>
                <br /> */}
                {/* <Label htmlFor="s3CiCbFirstName" small="true">
                  <FormattedMessage id="lpp_page3_question3_label" />
                </Label>
                <InputWrapper isValid={errors.s3CiCbFirstName === undefined}>
                  <input
                    type="text"
                    name="s3CiCbFirstName"
                    id="s3CiCbFirstName"
                    placeholder=""
                    onChange={handleChange}
                    ref={register({
                      validate: (value) => onlyLettersValidation(value),
                      minLength: 2,
                      maxLength: 38,
                    })}
                    defaultValue={setVal(
                      props.appState.employerInfoState,
                      "s3CiCbFirstName"
                    )}
                  />
                  {errors.s3CiCbFirstName &&
                    (errors.s3CiCbFirstName.type === "validate" ||
                      errors.s3CiCbFirstName.type === "minLength" ||
                      errors.s3CiCbFirstName.type === "maxLength") && (
                      <ErrorMessage>
                        <FormattedMessage id="lpp_validation_name" />
                      </ErrorMessage>
                    )}
                </InputWrapper> */}
                {/* <Label htmlFor="s3CiCbLastName" small="true">
                  <FormattedMessage id="lpp_page3_question4_label" />
                </Label>
                <InputWrapper isValid={errors.s3CiCbLastName === undefined}>
                  <input
                    type="text"
                    name="s3CiCbLastName"
                    id="s3CiCbLastName"
                    placeholder=""
                    onChange={handleChange}
                    ref={register({
                      validate: (value) => onlyLettersValidation(value),
                      minLength: 2,
                      maxLength: 38,
                    })}
                    defaultValue={setVal(
                      props.appState.employerInfoState,
                      "s3CiCbLastName"
                    )}
                  />
                  {errors.s3CiCbLastName &&
                    (errors.s3CiCbLastName.type === "validate" ||
                      errors.s3CiCbLastName.type === "minLength" ||
                      errors.s3CiCbLastName.type === "maxLength") && (
                      <ErrorMessage>
                        <FormattedMessage id="lpp_validation_name" />
                      </ErrorMessage>
                    )}
                </InputWrapper> */}
                <Label htmlFor="s3CiPhone" small="true">
                  <FormattedMessage id="lpp_page3_question5_label" />
                  &nbsp;
                  <ToolTip
                    role="link"
                    tabIndex="0"
                    className="tooltip"
                    src={TooltipSVG}
                    alt="tooltip"
                    onClick={() =>
                      props.showTooltip("tooltip_title", [
                        "tooltip_text_company_phone",
                      ])
                    }
                  />
                </Label>
                <QuestionWrap>
                  <InputWrapper isValid={errors.s3CiPhone === undefined}>
                    <Controller
                      as={InputMask}
                      control={control}
                      mask="(999) 999 - 9999"
                      name="s3CiPhone"
                      placeholder="( ___ ) ___ - ____"
                      onChange={(ev) => {
                        formValues.s3CiPhone = ev[0].target.value;
                      }}
                      rules={{
                        validate: () => checkPhoneNumber(formValues.s3CiPhone),
                      }}
                      defaultValue={setVal(
                        props.appState.employerInfoState,
                        "s3CiPhone"
                      )}
                    />
                  </InputWrapper>
                  {errors.s3CiPhone && errors.s3CiPhone.type === "validate" && (
                    <ErrorMessage>
                      <FormattedMessage id="lpp_validation_phone" />
                    </ErrorMessage>
                  )}
                </QuestionWrap>
                <Label small="true">
                  <FormattedMessage id="lpp_page3_is_extension_label" />
                </Label>
                <FormChecklist>
                  <FormCheck>
                    <InputRadio
                      id="isExtensionYes"
                      name="s3isExtension"
                      label="Yes"
                      onChange={handleChange}
                      defaultChecked={isExtensionYes}
                    />
                  </FormCheck>
                  <FormCheck>
                    <InputRadio
                      id="isExtensionNo"
                      name="s3isExtension"
                      label="No"
                      onChange={handleChange}
                      defaultChecked={isExtensionNo}
                    />
                  </FormCheck>
                </FormChecklist>
                {collapse === true ? (
                  <>
                    <br />
                    <Label htmlFor="extension" small="true">
                      <FormattedMessage id="lpp_extension_label" />
                    </Label>
                    <QuestionWrap>
                  <InputWrapper
                    isValid={errors.s3Extension === undefined}
                  >
                    <input
                      type="number"
                      name="s3Extension"
                      id="extension"
                      placeholder=""
                      onChange={handleChange}
                      ref={register({ 
                        minLength: 2,
                        maxLength: 6
                       })}
                      defaultValue={setVal(
                        props.appState.employerInfoState,
                        "s3Extension"
                      )}
                    />
                    {errors.s3Extension && (
                      <ErrorMessage>
                        <FormattedMessage id="lpp_validation_extension" />
                      </ErrorMessage>
                    )}
                  </InputWrapper>
                </QuestionWrap>
                    {/* <InputWrapper
                      isValid={errors.s3Extension === undefined}
                    >
                      <Controller
                        as={InputMask}
                        control={control}
                        mask="(999) 999 - 9999"
                        name="s3Extension"
                        placeholder="( ___ ) ___ - ____"
                        onChange={(ev) => {
                          formValues.s3Extension = ev[0].target.value;
                        }}
                        rules={{
                          validate: () =>
                            checkPhoneNumber(formValues.s3Extension),
                        }}
                        defaultValue={setVal(
                          props.appState.employerInfoState,
                          "s3Extension"
                        )}
                      />
                    </InputWrapper> */}
                  </>
                ) : null}
                {/* <Label htmlFor="s3CiCbEmail" small="true">
                  <FormattedMessage id="lpp_page3_question6_label" />
                </Label>
                <InputWrapper isValid={errors.s3CiCbEmail === undefined}>
                  <input
                    type="email"
                    name="s3CiCbEmail"
                    id="s3CiCbEmail"
                    placeholder=""
                    onChange={handleChange}
                    ref={register({
                      validate: (value) => emailValidation(value),
                    })}
                    defaultValue={setVal(
                      props.appState.employerInfoState,
                      "s3CiCbEmail"
                    )}
                  />
                  {errors.s3CiCbEmail &&
                    errors.s3CiCbEmail.type === "validate" && (
                      <ErrorMessage>
                        <FormattedMessage id="lpp_validation_email" />
                      </ErrorMessage>
                    )}
                </InputWrapper> */}
              </Group>
              <Group>
              <Label htmlFor="s3InterruptionReason">
                  <FormattedMessage id="lpp_page5_question4_label" />
                  {/* <ToolTip
                    role="link"
                    tabIndex="0"
                    className="tooltip"
                    src={TooltipSVG}
                    alt="tooltip"
                    onClick={() =>
                      props.showTooltip("tooltip_title", ["tooltip_text11"])
                    }
                  /> */}
                </Label>
                <QuestionWrap>
                  <InputWrapper
                    isValid={errors.s3InterruptionReason === undefined}
                  >
                    <input
                      type="textarea"
                      name="s3InterruptionReason"
                      id="s3InterruptionReason"
                      placeholder=""
                      onChange={handleChange}
                      ref={register({ maxLength: 500 })}
                      defaultValue={setVal(
                        props.appState.employerInfoState,
                        "s3InterruptionReason"
                      )}
                    />
                    {errors.s3InterruptionReason && (
                      <ErrorMessage>
                        <FormattedMessage id="lpp_validation_max500" />
                      </ErrorMessage>
                    )}
                  </InputWrapper>
                </QuestionWrap>
              </Group>
            </QuestionGroups>
            {/* <br />
            <QuestionGroups>
              <Group>
                <H3>
                  <FormattedMessage id="lpp_page3_question7_caption" />
                  &nbsp;
                  <ToolTip
                    role="link"
                    tabIndex="0"
                    className="tooltip"
                    src={TooltipSVG}
                    alt="tooltip"
                    onClick={() =>
                      props.showTooltip("tooltip_title", ["tooltip_text12"])
                    }
                  />
                </H3>
                <br />
                <Label htmlFor="s3CiStreet" small="true">
                  <FormattedMessage id="lpp_page3_question7_label" />
                </Label>
                <HelpText>
                  <FormattedMessage id="lpp_page3_question7_help" />
                </HelpText>
                <InputWrapper isValid={errors.s3CiStreet === undefined}>
                  <CanadaPostAddressInput
                    onChange={handleChange}
                    register={register}
                    onBlur={() => null}
                    appState={props.appState}
                  />
                </InputWrapper>
                <Label htmlFor="s3CiCity" small="true">
                  <FormattedMessage id="lpp_page3_question8_label" />
                </Label>
                <InputWrapper isValid={errors.s3CiCity === undefined}>
                  <input
                    type="text"
                    name="s3CiCity"
                    id="s3CiCity"
                    placeholder=""
                    onChange={handleChange}
                    ref={register()}
                    defaultValue={setVal(
                      props.appState.employerInfoState,
                      "s3CiCity"
                    )}
                  />
                </InputWrapper>
                <Label htmlFor="s3CiProvince" small="true">
                  <FormattedMessage id="lpp_page3_question9_label" />
                </Label>
                <InputWrapper isValid={errors.s3CiProvince === undefined}>
                  <input
                    type="text"
                    name="s3CiProvince"
                    id="s3CiProvince"
                    placeholder=""
                    onChange={handleChange}
                    ref={register()}
                    defaultValue={setVal(
                      props.appState.employerInfoState,
                      "s3CiProvince"
                    )}
                  />
                </InputWrapper>
                <Label htmlFor="s3CiPostalCode" small="true">
                  <FormattedMessage id="lpp_page3_question10_label" />
                </Label>
                <InputWrapper isValid={errors.s3CiPostalCode === undefined}>
                  <input
                    type="text"
                    name="s3CiPostalCode"
                    id="s3CiPostalCode"
                    placeholder=""
                    onChange={handleChange}
                    ref={register()}
                    defaultValue={setVal(
                      props.appState.employerInfoState,
                      "s3CiPostalCode"
                    )}
                  />
                </InputWrapper>
              </Group>
            </QuestionGroups> */}
            <NextPrevButtons>
              <Button outlined onClick={backButtonHandler}>
                <FormattedMessage id="lpp_cta_back" />
              </Button>
              <Button onClick={handleSubmit(onSubmit)} type="button">
                <FormattedMessage id="lpp_cta_next" />
              </Button>
            </NextPrevButtons>
          </form>
        </Center>
      </MainWrapper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    appState: state.crossSellApp,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    storeEmployerInfoState: (data) =>
      dispatch(actionCreators.storeEmployerInfoState(data)),
    toggleSpinner: () => dispatch(actionCreators.toggleSpinner()),
    showTooltip: (title, texts) =>
      dispatch(
        actionCreators.showTooltip({
          title: title,
          text: texts,
        })
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EmployerInfoPage));
