/**
 *
 * Button.react.js
 *
 * A common button, if you pass it a prop "route" it'll render a link to a react-router route
 * otherwise it'll render a link with an onclick
 */

import PropTypes from 'prop-types';

import React, { Children } from 'react';
import { Link } from 'react-router-dom';

import StyledButton from './StyledButton';
import StyledSpan from './StyledSpan';
import Wrapper from './Wrapper';
import { PrevWrap } from './NextPrevWrap';

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.disableClick = this.disableClick.bind(this);
  }

  disableClick(e) {
    e.preventDefault();
  }

  render() {
    const {
      onClick,
      disabled,
      href,
      oversized,
      secondary,
      critical,
      link,
      graybg,
      bluelink,
      children,
      type,
      htmlFor,
      noWrap,
      outlined,
      small,
      forward,
    } = this.props;

    let button;

    // If the button has an href, render a link
    if (href) {
      button = (
        <Link
          to={!disabled ? href : '#'}
          onClick={!disabled ? onClick : this.disableClick}
          disabled={disabled}
          style={{ textDecoration: 'none' }}
        >
          <StyledSpan
            oversized={oversized}
            secondary={secondary}
            critical={critical}
            link={link}
            graybg={graybg}
            bluelink={bluelink}
            disabled={disabled}
          >
            {Children.toArray(children)}
          </StyledSpan>
        </Link>
      );

      // If the button has htmlFor, render a label
    } else if (htmlFor) {
      button = (
        <label htmlFor={htmlFor}>
          <StyledSpan
            oversized={oversized}
            secondary={secondary}
            critical={critical}
            link={link}
            graybg={graybg}
            bluelink={bluelink}
            disabled={disabled}
          >
            {Children.toArray(children)}
          </StyledSpan>
        </label>
      );

      // Otherwise, render a button
    } else {
      button = (
        <StyledButton
          type={type}
          oversized={oversized}
          secondary={secondary}
          critical={critical}
          link={link}
          graybg={graybg}
          bluelink={bluelink}
          disabled={disabled}
          onClick={onClick}
          outlined={outlined}
          small={small}
          forward={forward}
        >
          {Children.toArray(children)}
        </StyledButton>
      );
    }

    if (noWrap) {
      return button;
    }
    if (outlined && !secondary) {
      return <PrevWrap>{button}</PrevWrap>;
    }
    return <Wrapper>{button}</Wrapper>;
  }
}

Button.defaultProps = {
  type: 'button',
};

Button.propTypes = {
  /** Link to be rendered in an anchor.  Not used if handleRoute is supplied */
  href: PropTypes.string,

  /** Function to be fired if an anchor is rendered.  Not used if handleRoute is supplied */
  onClick: PropTypes.func,

  /** Text node for the face of the button */
  children: PropTypes.node.isRequired,

  /** Is the button disabled */
  disabled: PropTypes.bool,

  /** Define if the link should be styled as a oversized button */
  oversized: PropTypes.bool,

  /** Define if the link should be styled as a secondary button */
  secondary: PropTypes.bool,

  /** Define if the link should be styled as a critical button */
  critical: PropTypes.bool,

  /** Define if the link should be styled as a link button */
  link: PropTypes.bool,

  /** Define if background should be of type gray3 */
  graybg: PropTypes.bool,

  /** Define if the link should be styled as a blue link */
  bluelink: PropTypes.bool,

  /** Define height */
  small: PropTypes.bool,

  /** Optional type if a button */
  type: PropTypes.string,

  /** If present, render a label that looks like a button */
  htmlFor: PropTypes.string,

  /** Specify if the button should not have the standard wrapper */
  noWrap: PropTypes.bool,
  /** For new nav outlined button */
  outlined: PropTypes.bool,
  /** For new forward button */
  forward: PropTypes.bool,
};

export default Button;
