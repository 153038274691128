import React, { useState, useEffect } from "react";
import VoidCheque from '../../../components/VoidCheque/VoidCheque';
import LoanAppHeader from "../../../components/LoanHeader";
import MainWrapper from "../../../components/MainWrapper";
import Center from "../../../components/MainWrapper/Center";

const VoidChequePage = props => {

    return (
        <>
            <LoanAppHeader progress={89} />
            <MainWrapper>
                <Center>
                    <VoidCheque />
                </Center>
            </MainWrapper>
        </>
    );
}

export default VoidChequePage;