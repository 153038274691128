import PropTypes from 'prop-types';

import React, { Children } from 'react';
import H2 from '../../components/H2';

import StyledBox from './StyledBox';

function ColorBox(props) {
  const { children, ...rest } = props;
  const text = props.big ? (
    <H2>{Children.toArray(children)}</H2>
  ) : (
    Children.toArray(children)
  );
  return <StyledBox {...rest}>{text}</StyledBox>;
}

ColorBox.propTypes = {
  children: PropTypes.node.isRequired,

  big: PropTypes.bool,
};

export default ColorBox;
