import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import InputMask from "react-input-mask";

import * as actionCreators from "../../../store/LppProjectActions";
import { setVal, transforrmPhoneNumber } from "../../../helpers/setValueHelper";
import { callPaths } from "../../../configuration";
import {
  emailValidation,
  onlyLettersValidation,
  checkPhoneNumber,
} from "../../../helpers/validationHelper";

import styled from "styled-components";
import LoanAppHeader from "../../../components/LoanHeader";
import MainWrapper from "../../../components/MainWrapper";
import Button from "../../../components/Button";
import Center from "../../../components/MainWrapper/Center";
import Group from "../../../components/Group";
import QuestionGroups from "../../../components/QuestionGroups";
import Label from "../../../components/QuestionWrapper/Label";
import FormChecklist from "../../../components/FormCheckList";
import FormCheck from "../../../components/FormCheck";
import InputRadio from "../../../components/InputRadioGroup/InputRadio";
import ColorBox from "../../../components/ColorBox";
import ColorBoxGroup from "../../../components/ColorBoxGroup";
import InputWrapper from "../../../components/InputText/InputWrapper";
import ErrorMessage from "../../../components/QuestionWrapper/ErrorMessage";
import HelpText from "../../../components/QuestionWrapper/Helptext";
import H3 from "../../../components/H3";

import { applyCanadaPost } from "../../../vendor/canadaPost";

const LeftAlign = styled.div`
  text-align: left;
`;

const ToolTip = styled.img`
  float: right;
`;

const QuestionWrap = styled.div`
  margin-top: 8px;
`;

const NextPrevButtons = styled.div`
  display: flex;
`;

const CanadaPostAddressInput = (props) => {
  useEffect(() => {
    // Allow canada post to load
    window.setTimeout(() => {
      applyCanadaPost(
        "s1Street",
        "s1PostalCode",
        "s1City",
        "s1Province",
        props.onChange,
        props.onBlur,
        function triggerCanadaPostFields() {
          const s1Street = document.getElementById("s1Street");
          const s1City = document.getElementById("s1City");
          const s1Province = document.getElementById("s1Province");
          const s1PostalCode = document.getElementById("s1PostalCode");

          const s1StreetEvent = new Event("input", { bubbles: true });
          const s1ProvinceEvent = new Event("input", { bubbles: true });
          const s1CityEvent = new Event("input", { bubbles: true });
          const s1PostalCodeEvent = new Event("input", { bubbles: true });

          s1Street.dispatchEvent(s1StreetEvent);
          s1Province.dispatchEvent(s1ProvinceEvent);
          s1City.dispatchEvent(s1CityEvent);
          s1PostalCode.dispatchEvent(s1PostalCodeEvent);
        }
      );
    }, 500);
  });

  return (
    <input
      type="text"
      name="s1Street"
      id="s1Street"
      placeholder=""
      onChange={props.onChange}
      ref={props.register({
        required: true,
      })}
      defaultValue={setVal(props.appState.customerInfoState, "s1Street")}
    />
  );
};

const CustomerInfoPage = (props) => {
  const { register, handleSubmit, getValues, errors, control } = useForm();
  const history = useHistory();

  let [formValues, setFormValues] = useState({
    ...props.appState.customerInfoState,
  });

  const setRadioValue = (name, id, value) => {
    if (name === "resubmitS1IsAddressChanged") {
      value = id === "isAddressChangedYes";
    }
    return value;
  };

  let handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let id = e.target.id;
    value = setRadioValue(name, id, value);
    formValues[name] = value;
    setFormValues(formValues);
  };

  const setAddressValues = () => {
    const data = getValues();
    formValues.s1City = data.s1City;
    formValues.s1PostalCode = data.s1PostalCode;
    formValues.s1Province = data.s1Province;
    formValues.s1Street = data.s1Street;
  };

  const backButtonHandler = () => {
    formValues.s1Phone = transforrmPhoneNumber(getValues().s1Phone);
    setAddressValues();
    props.storeCustomerInfoState(formValues);
    history.push("/");
  };

  const onSubmit = (data) => {
    window.scrollTo(0, 0);
    formValues.id = props.appState.appIdAndToken
      ? props.appState.appIdAndToken.token
      : null;
    formValues.s1Phone = transforrmPhoneNumber(getValues().s1Phone);
    setAddressValues();
    props.storeCustomerInfoState(formValues);
    props.toggleSpinner();
    axios
      .post(callPaths.screen2, formValues)
      .then((response) => {
        props.getAppId(response.data);
        props.toggleSpinner();
        if (response.data.isAlreadyPresented) {
          history.push("/alreadyExists");
        } else {
          history.push("/employmentStatus");
        }
      })
      .catch(function(error) {
        console.log(error);
        props.toggleSpinner();
      });
  };

  useEffect(() => {
    if (
      props.appState.appIdAndToken &&
      props.appState.appIdAndToken.isAlreadyPresented
    ) {
      history.push("/alreadyExists");
    }
  }, []);

  return (
    <>
      <LoanAppHeader progress={15} />
      <MainWrapper>
        <Center>
          <ColorBoxGroup>
            <ColorBox green>
              <LeftAlign>
                <FormattedMessage id="lpp_page1_header" />
              </LeftAlign>
            </ColorBox>
            <ColorBox big blue>
              <FormattedMessage id="lpp_page1_subheader" />
            </ColorBox>
          </ColorBoxGroup>
          <form noValidate="novalidate">
            <QuestionGroups>
              <Group>
                <Label htmlFor="s1FirstName">
                  <FormattedMessage id="lpp_page1_question2_label" />
                </Label>
                <QuestionWrap>
                  <InputWrapper isValid={errors.s1FirstName === undefined}>
                    <input
                      type="text"
                      name="s1FirstName"
                      id="s1FirstName"
                      placeholder=""
                      onChange={handleChange}
                      ref={register({
                        required: true,
                        validate: (value) => onlyLettersValidation(value),
                        minLength: 2,
                        maxLength: 38,
                      })}
                      defaultValue={setVal(
                        formValues,
                        "s1FirstName"
                      )}
                    />
                    {errors.s1FirstName &&
                      errors.s1FirstName.type === "required" && (
                        <ErrorMessage>
                          <FormattedMessage id="lpp_validation_required" />
                        </ErrorMessage>
                      )}
                    {errors.s1FirstName &&
                      (errors.s1FirstName.type === "validate" ||
                        errors.s1FirstName.type === "minLength" ||
                        errors.s1FirstName.type === "maxLength") && (
                        <ErrorMessage>
                          <FormattedMessage id="lpp_validation_name" />
                        </ErrorMessage>
                      )}
                  </InputWrapper>
                </QuestionWrap>
                <Label htmlFor="s1LastName">
                  <FormattedMessage id="lpp_page1_question3_label" />
                </Label>
                <QuestionWrap>
                  <InputWrapper isValid={errors.s1LastName === undefined}>
                    <input
                      type="text"
                      name="s1LastName"
                      id="s1LastName"
                      placeholder=""
                      onChange={handleChange}
                      ref={register({
                        required: true,
                        validate: (value) => onlyLettersValidation(value),
                        minLength: 2,
                        maxLength: 38,
                      })}
                      defaultValue={setVal(
                        formValues,
                        "s1LastName"
                      )}
                    />
                    {errors.s1LastName &&
                      errors.s1LastName.type === "required" && (
                        <ErrorMessage>
                          <FormattedMessage id="lpp_validation_required" />
                        </ErrorMessage>
                      )}
                    {errors.s1LastName &&
                      (errors.s1LastName.type === "validate" ||
                        errors.s1LastName.type === "minLength" ||
                        errors.s1LastName.type === "maxLength") && (
                        <ErrorMessage>
                          <FormattedMessage id="lpp_validation_name" />
                        </ErrorMessage>
                      )}
                  </InputWrapper>
                </QuestionWrap>
                <Label htmlFor="s1Phone">
                  <FormattedMessage id="lpp_page1_question4_label" />
                </Label>
                <QuestionWrap>
                  <InputWrapper isValid={errors.s1Phone === undefined}>
                    <Controller
                      as={InputMask}
                      control={control}
                      mask="(999) 999 - 9999"
                      name="s1Phone"
                      id="s1Phone"
                      placeholder="( ___ ) ___ - ____"
                      rules={{
                        validate: (val) =>
                          checkPhoneNumber(val, true),
                        required: true,
                      }}
                      defaultValue={setVal(
                        formValues,
                        "s1Phone"
                      )}
                    />
                  </InputWrapper>
                  {errors.s1Phone && errors.s1Phone.type === "validate" && (
                    <ErrorMessage>
                      <FormattedMessage id="lpp_validation_phone" />
                    </ErrorMessage>
                  )}
                  {errors.s1Phone && errors.s1Phone.type === "required" && (
                    <ErrorMessage>
                      <FormattedMessage id="lpp_validation_required" />
                    </ErrorMessage>
                  )}
                </QuestionWrap>
                <Label htmlFor="email">
                  <FormattedMessage id="lpp_page1_question5_label" />
                </Label>
                <QuestionWrap>
                  <InputWrapper isValid={errors.email === undefined}>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder=""
                      onChange={handleChange}
                      ref={register({
                        required: true,
                        validate: (value) => emailValidation(value),
                      })}
                      defaultValue={setVal(
                        formValues,
                        "email"
                      )}
                    />
                    {errors.email && errors.email.type === "required" && (
                      <ErrorMessage>
                        <FormattedMessage id="lpp_validation_required" />
                      </ErrorMessage>
                    )}
                    {errors.email && errors.email.type === "validate" && (
                      <ErrorMessage>
                        <FormattedMessage id="lpp_validation_email" />
                      </ErrorMessage>
                    )}
                  </InputWrapper>
                </QuestionWrap>
              </Group>
              <Group>
                <H3>
                  <FormattedMessage id="lpp_page1_question7_caption" />
                </H3>
                <br />
                <Label htmlFor="s1Street" small="true">
                  <FormattedMessage id="lpp_page1_question7_label" />
                </Label>
                <HelpText>
                  <FormattedMessage id="lpp_page1_question7_help" />
                </HelpText>
                <QuestionWrap>
                  <InputWrapper isValid={errors.s1Street === undefined}>
                    <CanadaPostAddressInput
                      onChange={handleChange}
                      register={register}
                      onBlur={() => null}
                      appState={props.appState}
                    />
                    {errors.s1Street && errors.s1Street.type === "required" && (
                      <ErrorMessage>
                        <FormattedMessage id="lpp_validation_required" />
                      </ErrorMessage>
                    )}
                  </InputWrapper>
                </QuestionWrap>
                <Label htmlFor="s1City" small="true">
                  <FormattedMessage id="lpp_page1_question8_label" />
                </Label>
                <QuestionWrap>
                  <InputWrapper isValid={errors.s1City === undefined}>
                    <input
                      type="text"
                      name="s1City"
                      id="s1City"
                      placeholder=""
                      onChange={handleChange}
                      ref={register({
                        required: true,
                      })}
                      defaultValue={setVal(
                        formValues,
                        "s1City"
                      )}
                    />
                    {errors.s1City && errors.s1City.type === "required" && (
                      <ErrorMessage>
                        <FormattedMessage id="lpp_validation_required" />
                      </ErrorMessage>
                    )}
                  </InputWrapper>
                </QuestionWrap>
                <Label htmlFor="s1Province" small="true">
                  <FormattedMessage id="lpp_page1_question9_label" />
                </Label>
                <QuestionWrap>
                  <InputWrapper isValid={errors.s1Province === undefined}>
                    <input
                      type="text"
                      name="s1Province"
                      id="s1Province"
                      placeholder=""
                      onChange={handleChange}
                      ref={register({
                        required: true,
                      })}
                      defaultValue={setVal(
                        formValues,
                        "s1Province"
                      )}
                    />
                    {errors.s1Province &&
                      errors.s1Province.type === "required" && (
                        <ErrorMessage>
                          <FormattedMessage id="lpp_validation_required" />
                        </ErrorMessage>
                      )}
                  </InputWrapper>
                </QuestionWrap>
                <Label htmlFor="s1PostalCode" small="true">
                  <FormattedMessage id="lpp_page1_question10_label" />
                </Label>
                <QuestionWrap>
                  <InputWrapper isValid={errors.s1PostalCode === undefined}>
                    <input
                      type="text"
                      name="s1PostalCode"
                      id="s1PostalCode"
                      placeholder=""
                      onChange={handleChange}
                      ref={register({
                        required: true,
                      })}
                      defaultValue={setVal(
                        formValues,
                        "s1PostalCode"
                      )}
                    />
                    {errors.s1PostalCode &&
                      errors.s1PostalCode.type === "required" && (
                        <ErrorMessage>
                          <FormattedMessage id="lpp_validation_required" />
                        </ErrorMessage>
                      )}
                  </InputWrapper>
                </QuestionWrap>
              </Group>
              <Group>
                <Label small="true">
                  <FormattedMessage id="lpp_page1_is_address_changed_label" />
                </Label>
                <FormChecklist>
                  <FormCheck>
                    <InputRadio
                      id="isAddressChangedYes"
                      name="resubmitS1IsAddressChanged"
                      label="Yes"
                      onChange={handleChange}
                      defaultChecked={setVal(formValues,"resubmitS1IsAddressChanged") ? "chcked":""}
                    />
                  </FormCheck>
                  <FormCheck>
                    <InputRadio
                      id="isAddressChangedNo"
                      name="resubmitS1IsAddressChanged"
                      label="No"
                      onChange={handleChange}
                      defaultChecked={setVal(formValues,"resubmitS1IsAddressChanged") === false ? "chcked":""}
                    />
                  </FormCheck>
                </FormChecklist>
              </Group>
            </QuestionGroups>
            <NextPrevButtons>
              <Button outlined onClick={backButtonHandler}>
                <FormattedMessage id="lpp_cta_back" />
              </Button>
              <Button onClick={handleSubmit(onSubmit)} type="button">
                <FormattedMessage id="lpp_cta_next" />
              </Button>
            </NextPrevButtons>
          </form>
        </Center>
      </MainWrapper>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    appState: state.crossSellApp,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    storeCustomerInfoState: (data) =>
      dispatch(actionCreators.storeCustomerInfoState(data)),
    getAppId: (id) => dispatch(actionCreators.storeAppId(id)),
    toggleSpinner: () => dispatch(actionCreators.toggleSpinner()),
    showTooltip: (title, text) =>
      dispatch(
        actionCreators.showTooltip({
          title: title,
          text: text,
        })
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CustomerInfoPage));
