import React, { useEffect, useState } from "react";
import axios from "axios";
import { injectIntl, FormattedMessage } from "react-intl";
import { getToken } from "../../../helpers/callHelperFunctions";
import { useLocation } from "react-router-dom";
import * as actionCreators from "../../../store/LppProjectActions";
import { connect } from "react-redux";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import LoanAppHeader from "../../../components/LoanHeader";
import MainWrapper from "../../../components/MainWrapper";
import Center from "../../../components/MainWrapper/Center";
import Group from "../../../components/Group";
import QuestionGroups from "../../../components/QuestionGroups";
import Paragraph from "../../../components/P";
import { callPaths } from "../../../configuration";

const EsignEmailPage = (props) => {
  let location = useLocation();
  const history = useHistory();
  let [isSigned, setIsSigned] = useState(false);

  const getIframeUrl = (id) => {
    props.toggleSpinner();
    axios
      // .get(
      //   `${callPaths.getToken}${localStorage.getItem('token')}&t=${new Date().getTime()}`
      // )
      .get(
        `${callPaths.getToken}${getToken(
          location.search
        )}&t=${new Date().getTime()}`
      )
      .then((response) => {
        if (response.data.status === "FAIL") {
          console.log(response);
          setIsSigned(true);
          props.toggleSpinner();
        } else {
          props.sendData(response.data);
          props.storeToken(getToken(location.search));
          props.toggleSpinner();
          history.push("/esign");
        }
      })
      .catch(function(error) {
        props.toggleSpinner();
        console.log(error);
      });
  };

  useEffect(() => {
    getIframeUrl();
  }, []);

  return (
    <>
      <LoanAppHeader progress={100} />
      <MainWrapper>
        <Center>
          {isSigned ? (
            <QuestionGroups>
              <Group>
                <Paragraph>
                  <FormattedMessage id="lpp_already_signed" />
                </Paragraph>
              </Group>
            </QuestionGroups>
          ) : (
            <div />
          )}
        </Center>
      </MainWrapper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    appState: state.crossSellApp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSpinner: () => dispatch(actionCreators.toggleSpinner()),
    sendData: (data) => dispatch(actionCreators.storeEsignData(data)),
    storeToken: (id) => dispatch(actionCreators.storeToken(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EsignEmailPage);
