import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './DropzoneMessage.module.css';

const DropzoneMessage = (props) => {
    return (
        <div className={styles.text_center}>
            <FormattedMessage id="dropzone_title" />
            <p className={styles.file_rules}>
                <FormattedMessage id="dropzone_file_format" />
                <b>{props.fileFormat}</b>{' '}
                <FormattedMessage id="dropzone_file_less" />
                <b>{props.fileSize}</b>
                <b>
                    <FormattedMessage id="dropzone_file_MB" />
                </b>
                <FormattedMessage id="dropzone_characters" />
            </p>
        </div>
    );
};

export default DropzoneMessage;
