/**
 *
 * LoanHeader
 *
 */

import PropTypes from 'prop-types';

import React from 'react';
import styled from 'styled-components';
import styles from '../../styles/constants';
import Sticky from 'react-stickynode';
import { size } from '../../styles/mixins';
import { FormattedMessage } from 'react-intl';

import ProgressBar from '../../components/ProgressBar';
import Nav from '../../components/Nav';

const StyledHeader = styled.header`
  padding: 0;
`;

const ProgressWrapper = styled.div`
  width: 100%;
  background: ${styles.color.white};
  padding: ${size(2)};
  -webkit-box-shadow: 0 2px 1px lightgray;
  -moz-box-shadow: 0 2px 1px lightgray;
  box-shadow: 0 2px 1px lightgray;
  & > div {
    max-width: 864px;
    margin: auto;
  }
`;

const OuterWrapper = styled.div`
  padding: 0;
`;

const P = styled.p`
  color: ${styles.color.green};
  display: block;
  max-width: 864px;
  margin: auto;
`;

class LoanHeader extends React.PureComponent {
  renderProgressBar() {
    const { progress } = this.props;

    const roundedProgress = Math.floor(progress);
    return (
      <OuterWrapper>
        <Sticky innerZ={2}>
          <ProgressWrapper>
            <P>
              {roundedProgress}% <FormattedMessage id="progress_label" />
            </P>
            <ProgressBar
              progress={roundedProgress}
              title={'Get started'}
              timeLeft={'approx 5 mins'}
            />
          </ProgressWrapper>
        </Sticky>
      </OuterWrapper>
    );
  }

  render() {
    const { showProgressBar, disablelogologic } = this.props;
    return (
      <StyledHeader role="heading" aria-level="1">
        <Nav  disablelogologic={disablelogologic}/>
        {showProgressBar ? this.renderProgressBar() : false}
      </StyledHeader>
    );
  }
}
LoanHeader.defaultProps = {
  progress: 0,
  showProgressBar: true,
  disablelogologic: false,
};

LoanHeader.propTypes = {
  progress: PropTypes.number,
  showProgressBar: PropTypes.bool,
  disablelogologic: PropTypes.bool,
};

export default LoanHeader;
