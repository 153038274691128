export const GET_APP_ID = 'getAppId';
export const STORE_TOKEN = 'storeToken';
export const SHOW_THANKYOU_SCREEN = "showThankYouScreen";
export const IS_SERVER_ERROR = "isServerError";
export const TOGGLE_SPINNER = 'toggleSpinner';
export const STORE_CUSTOMER_INFO_STATE = 'storeCustomerInfoState';
export const STORE_EMPLOYMENT_STATUS_STATE = 'storeEmploymentStatusState';
export const STORE_EMPLOYER_INFO_STATE = 'storeEmployerInfoState';
export const STORE_EMPLOYMENT_HISTORY_STATE = 'storeEmploymentHistoryState';
export const STORE_ADDITIONAL_INFO_STATE = 'storeAdditionalInformationState';
export const STORE_ESIGN_DATA = "storeEsignData";
export const TOOLTIP_SHOW = "tooltipShow";
export const TOOLTIP_HIDE = "tooltipHide";
export const IS_APPLICATION_COMPLETED = "isApplicationCompleted";
export const SET_COMPLETE_TYPE = "setCompleteType";
export const SET_FILE_FORMATS = "setFileFormats";
export const SET_FILE_SIZE = "setFileSize";
export const SET_SPECIAL_CHARACTERS = "setSpecialCharacters";
