import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as actionCreators from '../../../store/LppProjectActions';
import { callPaths } from '../../../configuration';
import { setVal } from '../../../helpers/setValueHelper';
import ErrorMessage from '../../../components/QuestionWrapper/ErrorMessage';
import { decimalNumberValidation } from '../../../helpers/validationHelper';

import styled from 'styled-components';
import LoanAppHeader from '../../../components/LoanHeader';
import MainWrapper from '../../../components/MainWrapper';
import Button from '../../../components/Button';
import Center from '../../../components/MainWrapper/Center';
import Group from '../../../components/Group';
import QuestionGroups from '../../../components/QuestionGroups';
import Label from '../../../components/QuestionWrapper/Label';
import FormChecklist from '../../../components/FormCheckList';
import FormCheck from '../../../components/FormCheck';
import InputRadio from '../../../components/InputRadioGroup/InputRadio';
import ColorBox from '../../../components/ColorBox';
import ColorBoxGroup from '../../../components/ColorBoxGroup';
import TooltipSVG from '../../../components/QuestionWrapper/tooltip.svg';
import InputDateField from '../../../components/InputText/InputDateField';
import InputWrapper from '../../../components/InputText/InputWrapper';

const LeftAlign = styled.div`
    text-align: left;
`;

const ToolTip = styled.img`
    float: right;
`;

const QuestionWrap = styled.div`
    margin-top: 8px;
`;

const NextPrevButtons = styled.div`
    display: flex;
`;

const AdditionalQuestions = styled.div`
    margin-top: 1em;
    margin-left: 2em;
`;

const EmploymentStatusPage = (props) => {
    let [formValues, setFormValues] = useState({
        ...props.appState.employmentStatusState,
    });

    const { register, handleSubmit, errors } = useForm();

    const history = useHistory();
    const [isInitialSubmit, setInitialSubmit] = useState(false);
    const [customErrObj, setCustomErrObj] = useState({
        resubmitS1IsFullTimeJob: false,
        s1DateReturnedToWork: false,
        s3WorkingHoursPerWeek: false,
    });
    const [dob, setDob] = useState('');

    const [collapseWorkType, setCollapseWorkType] = useState(false);
    const [collapseNotReturned, setCollapseNotReturned] = useState(false);

    const collapseHandler = (id, name) => {
        if (id === 'returnedToWorkYes') {
            setCollapseWorkType(true);
            setCollapseNotReturned(false);
        } else if (id === 'returnedToWorkNo') {
            setCollapseWorkType(false);
            setCollapseNotReturned(true);
        }
    };

    const setRadioValue = (name, id, value) => {
        if (
            name === 's1IsReturnedToWork' ||
            name === 'resubmitS1IsFullTimeJob' ||
            name === 'resubmitS1InsuranceBenefits'
        ) {
            collapseHandler(id, name);
            value =
                id === 'unemploymentBenefitsYes' ||
                id === 'returnedToWorkYes' ||
                id === 'isRecordOfEmploymentYes' ||
                id === 's1IsFullTimeYes' ||
                id === 'insuranceBenefitskYes' ||
                id === 'receivingIncomeYes';
        }
        if (name === 's1IsReturnedToWork' && value === false) {
            formValues['resubmitS1IsFullTimeJob'] = '';
            formValues['s1DateReturnedToWork'] = '';
            formValues['s3WorkingHoursPerWeek'] = '';
            props.storeEmpoymentStatusData(formValues);
        }

        if (name === 's1IsReturnedToWork' && value === true) {
            formValues['resubmitS1NotReturnedReason'] = '';
            props.storeEmpoymentStatusData(formValues);
        }
        return value;
    };

    let handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let id = e.target.id;
        value = setRadioValue(name, id, value);

        formValues[name] = value;
        setFormValues(formValues);
        validationHandler(name);
    };

    const validationHandler = (name) => {
        if (
            (name === 's1DateReturnedToWork' ||
                name === 'resubmitS1IsFullTimeJob' ||
                name === 's3WorkingHoursPerWeek' ||
                name === 'resubmitS1InsuranceBenefits') &&
            isInitialSubmit
        ) {
            setCustomErrObj({
                s1DateReturnedToWork: formValues['s1DateReturnedToWork'] === '',
                resubmitS1IsFullTimeJob:
                    formValues['resubmitS1IsFullTimeJob'] === '',
                s3WorkingHoursPerWeek: decimalNumberValidation(
                    formValues['s3WorkingHoursPerWeek']
                ),
                resubmitS1InsuranceBenefits:
                    formValues['resubmitS1InsuranceBenefits'] === '',
            });
        }
    };
    const changeDate = (date) => {
        if (date) {
            // Date format YYYY-MM-DD
            formValues['s1DateReturnedToWork'] = date;
            setFormValues(formValues);
            setDob(date);
            validationHandler('s1DateReturnedToWork');
        }
    };

    const backButtonHandler = () => {
        props.storeEmpoymentStatusData(formValues);
        history.push('/customerInfo');
    };

    const onSubmit = (data) => {
        window.scrollTo(0, 0);
        setInitialSubmit(true);
        if (formValues['s1IsReturnedToWork']) {
            if (
                formValues['s1DateReturnedToWork'] === '' ||
                formValues['resubmitS1IsFullTimeJob'] === '' ||
                formValues['s3WorkingHoursPerWeek'] === '' ||
                formValues['resubmitS1InsuranceBenefits'] === ''
            ) {
                setCustomErrObj({
                    s1DateReturnedToWork:
                        formValues['s1DateReturnedToWork'] === '',
                    resubmitS1IsFullTimeJob:
                        formValues['resubmitS1IsFullTimeJob'] === '',
                    s3WorkingHoursPerWeek: decimalNumberValidation(
                        formValues['s3WorkingHoursPerWeek']
                    ),
                    resubmitS1InsuranceBenefits:
                        formValues['resubmitS1InsuranceBenefits'] === '',
                });
                return;
            }
        }
        if (formValues['resubmitS1InsuranceBenefits'] === '') {
            setCustomErrObj({
                resubmitS1InsuranceBenefits:
                    formValues['resubmitS1InsuranceBenefits'] === '',
            });
            return;
        }
        props.toggleSpinner();
        formValues.id = props.appState.appIdAndToken.token;
        props.storeEmpoymentStatusData(formValues);
        axios
            .post(callPaths.screen3, formValues)
            .then((response) => {
                props.toggleSpinner();
                props.isAppCompleted();
                if (formValues.s1LoanNumber && formValues.s1ClaimNumber) {
                    history.push('/completeTheProcess');
                } else {
                    history.push('/thankYouScreen');
                }
            })
            .catch(function(error) {
                props.toggleSpinner();
                console.log(error);
            });
    };

    useEffect(() => {
        if (formValues.s1IsReturnedToWork) {
            setCollapseWorkType(true);
        }
        if (formValues.s1IsReturnedToWork === false) {
            setCollapseNotReturned(true);
        }
        if (!props.appState.appIdAndToken) {
            history.push('/');
        }
        if (
            props.appState.isApplicationCompleted &&
            props.appState.employmentStatusState.s1ClaimNumber &&
            props.appState.employmentStatusState.s1LoanNumber
        ) {
            history.push('/completeTheProcess');
        } else if (props.appState.isApplicationCompleted) {
            history.push('/thankYouScreen');
        }
    }, []);

    return (
        <>
            <LoanAppHeader progress={30} />
            <MainWrapper>
                <Center>
                    <ColorBoxGroup>
                        <ColorBox green>
                            <LeftAlign>
                                <FormattedMessage id="lpp_page2_header" />
                            </LeftAlign>
                        </ColorBox>
                        <ColorBox big blue>
                            <FormattedMessage id="lpp_page2_subheader" />
                        </ColorBox>
                    </ColorBoxGroup>
                    <form>
                        <QuestionGroups>
                            <Group>
                                <Label>
                                    <FormattedMessage id="lpp_page2_question1_label" />
                                    <ToolTip
                                        role="link"
                                        tabIndex="0"
                                        className="tooltip"
                                        src={TooltipSVG}
                                        alt="tooltip"
                                        onClick={() =>
                                            props.showTooltip('tooltip_title', [
                                                'tooltip_text2',
                                            ])
                                        }
                                    />
                                </Label>
                                <FormChecklist>
                                    <FormCheck>
                                        <InputRadio
                                            id="returnedToWorkYes"
                                            name="s1IsReturnedToWork"
                                            label="Yes"
                                            onChange={handleChange}
                                            defaultChecked={
                                                setVal(
                                                    formValues,
                                                    's1IsReturnedToWork'
                                                )
                                                    ? 'chcked'
                                                    : ''
                                            }
                                        />
                                    </FormCheck>
                                    <FormCheck>
                                        <InputRadio
                                            id="returnedToWorkNo"
                                            name="s1IsReturnedToWork"
                                            label="No"
                                            onChange={handleChange}
                                            defaultChecked={
                                                setVal(
                                                    formValues,
                                                    's1IsReturnedToWork'
                                                ) === false
                                                    ? 'chcked'
                                                    : ''
                                            }
                                        />
                                    </FormCheck>
                                </FormChecklist>
                                {collapseWorkType === true ? (
                                    <>
                                        <br />
                                        <Label htmlFor="s3IsEmployeeResumedFullDuties"></Label>
                                        <FormChecklist>
                                            <FormCheck>
                                                <InputRadio
                                                    id="s1IsFullTimeYes"
                                                    name="resubmitS1IsFullTimeJob"
                                                    value="full-time"
                                                    label="Full-time"
                                                    skipTranslation={true}
                                                    onChange={handleChange}
                                                    defaultChecked={
                                                        setVal(
                                                            formValues,
                                                            'resubmitS1IsFullTimeJob'
                                                        )
                                                            ? 'chcked'
                                                            : ''
                                                    }
                                                />
                                            </FormCheck>
                                            <FormCheck>
                                                <InputRadio
                                                    id="s1IsFullTimeNo"
                                                    name="resubmitS1IsFullTimeJob"
                                                    value="part-time"
                                                    label="Part-time"
                                                    skipTranslation={true}
                                                    onChange={handleChange}
                                                    defaultChecked={
                                                        setVal(
                                                            formValues,
                                                            'resubmitS1IsFullTimeJob'
                                                        ) === false
                                                            ? 'chcked'
                                                            : ''
                                                    }
                                                />
                                            </FormCheck>
                                            <br />
                                            {customErrObj.resubmitS1IsFullTimeJob && (
                                                <ErrorMessage>
                                                    <FormattedMessage id="lpp_validation_required" />
                                                </ErrorMessage>
                                            )}
                                        </FormChecklist>
                                        <br />
                                        <Label htmlFor="s1DateReturnedToWork">
                                            <FormattedMessage id="lpp_page2_question2_label" />
                                        </Label>
                                        <QuestionWrap>
                                            <InputDateField
                                                name="s1DateReturnedToWork"
                                                id="s1DateReturnedToWork"
                                                placeholder="q_birth_day"
                                                onChange={(evt) =>
                                                    changeDate(evt.target.value)
                                                }
                                                onBlur={handleChange}
                                                onFocus={() => null}
                                                value={
                                                    formValues.s1DateReturnedToWork
                                                }
                                                increaseYear={true}
                                                isValid={
                                                    !customErrObj.s1DateReturnedToWork
                                                }
                                            />
                                            <br />
                                            {customErrObj.s1DateReturnedToWork && (
                                                <ErrorMessage>
                                                    <FormattedMessage id="lpp_validation_required" />
                                                </ErrorMessage>
                                            )}
                                        </QuestionWrap>
                                        <br />
                                        <Label htmlFor="s3WorkingHoursPerWeek">
                                            <FormattedMessage id="lpp_page4_question2_label" />
                                            <ToolTip
                                                role="link"
                                                tabIndex="0"
                                                className="tooltip"
                                                src={TooltipSVG}
                                                alt="tooltip"
                                                onClick={() =>
                                                    props.showTooltip(
                                                        'tooltip_title',
                                                        ['tooltip_text5']
                                                    )
                                                }
                                            />
                                        </Label>
                                        <QuestionWrap>
                                            <InputWrapper
                                                isValid={
                                                    !(
                                                        customErrObj.s3WorkingHoursPerWeek ===
                                                            'empty' ||
                                                        customErrObj.s3WorkingHoursPerWeek ===
                                                            'err'
                                                    )
                                                }
                                            >
                                                <input
                                                    type="text"
                                                    name="s3WorkingHoursPerWeek"
                                                    id="s3WorkingHoursPerWeek"
                                                    placeholder=""
                                                    step=".01"
                                                    onChange={(e) => {
                                                        e.target.value = e.target.value.trim();
                                                        handleChange(e);
                                                    }}
                                                    ref={register({
                                                        // required: true,
                                                        // validate: (val) => decimalNumberValidation(val),
                                                    })}
                                                    defaultValue={setVal(
                                                        formValues,
                                                        's3WorkingHoursPerWeek'
                                                    )}
                                                />
                                                {customErrObj.s3WorkingHoursPerWeek ===
                                                    'empty' && (
                                                    <ErrorMessage>
                                                        <FormattedMessage id="lpp_validation_required" />
                                                    </ErrorMessage>
                                                )}
                                                {customErrObj.s3WorkingHoursPerWeek ===
                                                    'err' && (
                                                    <ErrorMessage>
                                                        <FormattedMessage id="lpp_validation_decimal_number" />
                                                    </ErrorMessage>
                                                )}
                                            </InputWrapper>
                                        </QuestionWrap>
                                    </>
                                ) : null}
                                {collapseNotReturned === true ? (
                                    <>
                                        <br />
                                        <Label htmlFor="s3Comments">
                                            <FormattedMessage id="lpp_page2_not_returned_reason_label" />
                                        </Label>
                                        <QuestionWrap>
                                            <InputWrapper
                                                isValid={
                                                    errors.s3Comments ===
                                                    undefined
                                                }
                                            >
                                                <input
                                                    type="textarea"
                                                    name="resubmitS1NotReturnedReason"
                                                    id="notReturnedReason"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    ref={register({
                                                        maxLength: 500,
                                                    })}
                                                    rows="5"
                                                    defaultValue={setVal(
                                                        formValues,
                                                        'resubmitS1NotReturnedReason'
                                                    )}
                                                />
                                            </InputWrapper>
                                        </QuestionWrap>
                                    </>
                                ) : null}
                            </Group>
                            <Group>
                                <Label>
                                    <FormattedMessage id="lpp_page2_insurance_benefits_label" />
                                </Label>
                                <FormChecklist>
                                    <FormCheck>
                                        <InputRadio
                                            id="insuranceBenefitskYes"
                                            name="resubmitS1InsuranceBenefits"
                                            label="Yes"
                                            onChange={handleChange}
                                            defaultChecked={
                                                setVal(
                                                    formValues,
                                                    'resubmitS1InsuranceBenefits'
                                                )
                                                    ? 'chcked'
                                                    : ''
                                            }
                                        />
                                    </FormCheck>
                                    <FormCheck>
                                        <InputRadio
                                            id="insuranceBenefitsNo"
                                            name="resubmitS1InsuranceBenefits"
                                            label="No"
                                            onChange={handleChange}
                                            defaultChecked={
                                                setVal(
                                                    formValues,
                                                    'resubmitS1InsuranceBenefits'
                                                ) === false
                                                    ? 'chcked'
                                                    : ''
                                            }
                                        />
                                    </FormCheck>
                                </FormChecklist>
                                <br />
                                {customErrObj.resubmitS1InsuranceBenefits && (
                                    <ErrorMessage>
                                        <FormattedMessage id="lpp_validation_required" />
                                    </ErrorMessage>
                                )}
                                <br />
                            </Group>
                            <Group>
                                <Label htmlFor="s1ClaimNumber">
                                    <FormattedMessage id="lpp_page1_claim_number_label" />
                                    <ToolTip
                                        role="link"
                                        tabIndex="0"
                                        className="tooltip"
                                        src={TooltipSVG}
                                        alt="tooltip"
                                        onClick={() =>
                                            props.showTooltip('tooltip_title', [
                                                'tooltip_claim_number',
                                            ])
                                        }
                                    />
                                </Label>
                                <QuestionWrap>
                                    <InputWrapper
                                        isValid={
                                            errors.s1ClaimNumber === undefined
                                        }
                                    >
                                        <input
                                            type="text"
                                            name="s1ClaimNumber"
                                            id="s1ClaimNumber"
                                            placeholder=""
                                            onChange={handleChange}
                                            ref={register({
                                                pattern: /^\d+$/,
                                            })}
                                            defaultValue={setVal(
                                                formValues,
                                                's1ClaimNumber'
                                            )}
                                        />
                                        {errors.s1ClaimNumber && (
                                            <ErrorMessage>
                                                <FormattedMessage id="lpp_validation_decimal_number" />
                                            </ErrorMessage>
                                        )}
                                    </InputWrapper>
                                </QuestionWrap>
                                <Label htmlFor="s1LoanNumber">
                                    <FormattedMessage id="lpp_page1_loan_number_label" />
                                    <ToolTip
                                        role="link"
                                        tabIndex="0"
                                        className="tooltip"
                                        src={TooltipSVG}
                                        alt="tooltip"
                                        onClick={() =>
                                            props.showTooltip('tooltip_title', [
                                                'tooltip_loan_number',
                                            ])
                                        }
                                    />
                                </Label>
                                <QuestionWrap>
                                    <InputWrapper
                                        isValid={
                                            errors.s1LoanNumber === undefined
                                        }
                                    >
                                        <input
                                            type="text"
                                            name="s1LoanNumber"
                                            id="s1LoanNumber"
                                            placeholder=""
                                            onChange={handleChange}
                                            ref={register({
                                                pattern: /^\d+$/,
                                                minLength: 7,
                                                maxLength: 8,
                                            })}
                                            defaultValue={setVal(
                                                formValues,
                                                's1LoanNumber'
                                            )}
                                        />
                                        {errors.s1LoanNumber &&
                                            (errors.s1LoanNumber.type ===
                                                'maxLength' ||
                                                errors.s1LoanNumber.type ===
                                                    'minLength') && (
                                                <ErrorMessage>
                                                    <FormattedMessage id="lpp_validation_decimal_number" />
                                                </ErrorMessage>
                                            )}
                                        {errors.s1LoanNumber &&
                                            errors.s1LoanNumber.type ===
                                                'pattern' && (
                                                <ErrorMessage>
                                                    <FormattedMessage id="lpp_validation_decimal_number" />
                                                </ErrorMessage>
                                            )}
                                    </InputWrapper>
                                </QuestionWrap>
                            </Group>
                        </QuestionGroups>
                        <NextPrevButtons>
                            <Button outlined onClick={backButtonHandler}>
                                <FormattedMessage id="lpp_cta_back" />
                            </Button>
                            <Button
                                onClick={handleSubmit(onSubmit)}
                                type="button"
                            >
                                <FormattedMessage id="lpp_cta_next" />
                            </Button>
                        </NextPrevButtons>
                    </form>
                </Center>
            </MainWrapper>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        appState: state.crossSellApp,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        storeEmpoymentStatusData: (data) =>
            dispatch(actionCreators.storeEmploymentStatusState(data)),
        toggleSpinner: () => dispatch(actionCreators.toggleSpinner()),
        showTooltip: (title, texts) =>
            dispatch(
                actionCreators.showTooltip({
                    title: title,
                    text: texts,
                })
            ),
        isAppCompleted: () =>
            dispatch(actionCreators.isApplicationCompleted(true)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(EmploymentStatusPage));
