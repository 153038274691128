import styled from 'styled-components';
import { size } from '../../styles/mixins';

export default styled.div`
  & > div {
    margin-top: ${size(2)};
    margin-bottom: ${size(2)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
