import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './styles/global';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import HttpsRedirect from 'react-https-redirect';

import Main from './containers/Main/Main';
import ToolTipModal from './components/Modal/ToolTip';
import { hideTooltip } from './store/LppProjectActions';

import 'sanitize.css/sanitize.css';

const App = () => {
    const tooltipProps = useSelector((state) => state.crossSellApp.tooltip);
    const dispatch = useDispatch();

    return (
        <HttpsRedirect>
            <div className="App">
                <ToolTipModal
                    dismissible
                    isOpen={tooltipProps.isVisible}
                    details={{
                        title: tooltipProps.title,
                        text: tooltipProps.text,
                        isUpload: tooltipProps.isUpload,
                    }}
                    onRequestClose={() => dispatch(hideTooltip())}
                />
                <Main />
            </div>
        </HttpsRedirect>
    );
};

export default injectIntl(App);
